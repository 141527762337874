import './Invoices.module.scss';

import React, { useContext, useEffect, useState } from 'react';

import { post } from '../../api';
import { UserContext } from '../../context';

import DesktopLayout  from '../../components/utils/DesktopLayout';
import MobileLayout  from '../../components/utils/MobileLayout';
import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortNumericDown,
  faSortNumericDownAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Invoices.module.scss';

const Invoice = ({ match, history }) => {
  const { user } = useContext(UserContext);

  const [partner, setPartner] = useState([{}]);
  const [total, setTotal] = useState([{}]);
  const [auths, setAuth] = useState('');

  const GoToHome = () => history.push({ pathname: `/` });

  useEffect(() => {
    const fetchData = async () => {
      const auth = await post(`/check_auth`, user);

      if (auth.ok) {
        setAuth(await auth.json())
      }

      const resp = await post(`/get_invoices`, user);

      if (resp.ok) {
        setPartner(await resp.json());
      }

      const resp1 = await post(`/get_total`, user);

      if(resp1.ok) {
        setTotal(await resp1.json());
      }
    };

    fetchData();
  }, [setPartner, user, setTotal, setAuth]);

  const InvoiceTableHeaders = ({
    name,
    displayName,
    sortIcons,
    sort,
    onClickSort,
  }) => (
    <div onClick={() => onClickSort(name)} className={styles.IssuesTableHeader}>
      {displayName}&nbsp;
      {sort.col === name ? (
        sort.dir === 'dec' ? (
          <FontAwesomeIcon icon={sortIcons.dec} />
        ) : sort.dir === 'asc' ? (
          <FontAwesomeIcon icon={sortIcons.asc} />
        ) : null
      ) : null}
    </div>
  );

  const sortPrepare = (value) => {
    if (typeof value === 'string' || value instanceof String) {
      return value.toLowerCase();
    }
  
    return value || '';
  };

  const sortAsc = (col) => (a, b) => {
    a = sortPrepare(a[col]);
    b = sortPrepare(b[col]);
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  };
  
  const sortDec = (col) => (a, b) => {
    a = sortPrepare(a[col]);
    b = sortPrepare(b[col]);
    if (a < b) return 1;
    if (b < a) return -1;
    return 0;
  };

  const [sort, setSort] = useState({ col: 'id', dir: 'dec' });
  const sortFn = sort.dir === 'dec' ? sortDec(sort.col) : sortAsc(sort.col);

  const setSortToggleDir = () =>
    setSort((s) => ({ col: s.col, dir: s.dir === 'dec' ? 'asc' : 'dec' }));

  const setSortColumn = (col) => setSort({ col, dir: 'dec' });

  const onClickSort = (sortColumn) => {
    if (sort.col === sortColumn) {
      setSortToggleDir();
    } else {
      setSortColumn(sortColumn);
    }
  };

  const InvoiceLine = ({ line, onClick }) => (
    <div className={styles.RowWrapper}>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.reknr}</div>
      </div>

      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.invoicedate}</div>
      </div>

      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.invnr}</div>
      </div>
  
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.outstanding}</div>
      </div>
    </div>
  );

  const TotalLine = ({ line, onClick }) => (
    <div className={styles.RowWrapper}>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}></div>
      </div>

      <div className={styles.tableRow}>
        <div className={styles.TableCell}></div>
      </div>

      <div className={styles.tableRow}>
        <div className={styles.TableCell}>Total</div>
      </div>
  
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.total_outstanding}</div>
      </div>
    </div>
  );

  return (
  <>
    <DesktopLayout>
    {auths ?
    <>
    {partner.length>0  ? 
    <>
    <div className="BackRow">
      <button className="TertiaryBtn" onClick={GoToHome}>
        <i className="material-icons">arrow_back</i> Back to open tickets
      </button>
    </div>
    <div className={styles.TableWrapper}>
        <div className={styles.IssuesTable}>
        <InvoiceTableHeaders
            name="accNo"
            displayName="Account Number"
            sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
            sort={sort}
            onClickSort={onClickSort} />
  
          <InvoiceTableHeaders
            name="invoiceDate"
            displayName="Invoice Date"
            sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
            sort={sort}
            onClickSort={onClickSort} />
  
          <InvoiceTableHeaders
            name="invNo"
            displayName="Invoice Number"
            sortIcons={{ asc: faSortAlphaDown, dec: faSortAlphaDownAlt }}
            sort={sort}
            onClickSort={onClickSort} />
          <InvoiceTableHeaders
            name="amount"
            displayName="Amount"
            sortIcons={{ asc: faSortAlphaDown, dec: faSortAlphaDownAlt }}
            sort={sort}
            onClickSort={onClickSort} />

              {!partner ?
                <div className={styles.NoneSelected}>No outstanding balances.</div>
              : null
              }
              {partner ?
                <>
                {partner.map((line) => (
                    <InvoiceLine line={line} key={line.invnr}/>
                ))}</>
              : null
              }
              {partner ?
                <>
                {total.map((li) => (
                    <TotalLine line={li} key={li.total_outstanding}/>
                ))}</>
              : null
              }
        </div>
      </div>
      </>:
      <div className={styles.centerh1}><h1>No outstanding balance.</h1></div>
      }
      </>:
      <div className={styles.centerh1}><h1> No Authorization</h1></div>
      
      }
    </DesktopLayout>

    <MobileLayout>
      {auths ?
      <>
      {partner.length>0  ? 
      <>
      <div className="BackRow">
        <button className="TertiaryBtn" onClick={GoToHome}>
          <i className="material-icons">arrow_back</i> Back to open tickets
        </button>
      </div>
      <div className={styles.TableWrapper}>
          <div className={styles.IssuesTable}>
          <InvoiceTableHeaders
              name="accNo"
              displayName="Account Number"
              sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
              sort={sort}
              onClickSort={onClickSort} />
    
            <InvoiceTableHeaders
              name="invoiceDate"
              displayName="Invoice Date"
              sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
              sort={sort}
              onClickSort={onClickSort} />
    
            <InvoiceTableHeaders
              name="invNo"
              displayName="Invoice Number"
              sortIcons={{ asc: faSortAlphaDown, dec: faSortAlphaDownAlt }}
              sort={sort}
              onClickSort={onClickSort} />
            <InvoiceTableHeaders
              name="amount"
              displayName="Amount"
              sortIcons={{ asc: faSortAlphaDown, dec: faSortAlphaDownAlt }}
              sort={sort}
              onClickSort={onClickSort} />

                {!partner ?
                  <div className={styles.NoneSelected}>No outstanding balances.</div>
                : null
                }
                {partner ?
                  <>
                  {partner.map((line) => (
                      <InvoiceLine line={line} key={line.invnr}/>
                  ))}</>
                : null
                }
                {partner ?
                  <>
                  {total.map((li) => (
                      <TotalLine line={li} key={li.total_outstanding}/>
                  ))}</>
                : null
                }
          </div>
        </div>
        </>:
        <div className={styles.centerh1}><h1>No outstanding balance.</h1></div>
        }
        </>:
        <div className={styles.centerh1}><h1> No Authorization</h1></div>
        }
    </MobileLayout>
    </>
  );
};

export default Invoice;
