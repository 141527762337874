import React, { useContext,useEffect, useState } from 'react';
import { CustomTabs, CustomTab } from '../../utils/CustomTabs';
import MobileLayout  from '../../components/utils/MobileLayout';
import DesktopLayout  from '../../components/utils/DesktopLayout';
import ChangedInformation from './ChangedInformation';
import { UserContext } from '../../context';

import { get, post } from '../../api';
import styles from './UpdateInformation.module.scss';
import { ListItem } from '@material-ui/core';

const UpdateInformation = ({ history }) => {
  const { user } = useContext(UserContext);
  const [listReleases, setListReleases] = useState({});
  const [street, setStreet] = useState('');
  const [street2, setStreet2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [fax, setFax] = useState('');
  const [information_officer_name, setInformation_officer_name] = useState('');
  const [information_officer, setInformation_officer] = useState('');
  const [information_officer_cell_no, setInformation_officer_cell_no] = useState('');
  const [marketing_email, setMarketing_email] = useState('');
  const [debtor_vatnr, setDebtor_vatnr] = useState('');
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [operationalEmail, setOperationalEmail] = useState('');
  const [financialEmail, setFinancialEmail] = useState('');
  const [newName,setNewName] = useState('')
  const [newStreet, setNewStreet] = useState('');
  const [newStreet2, setNewStreet2] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newZip, setNewZip] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newMobile, setNewMobile] = useState('');
  const [newFax, setnewFax] = useState('');
  const [new_information_officer_name, setNewInformation_officer_name] = useState('');
  const [new_information_officer, setNewInformation_officer] = useState('');
  const [new_information_officer_cell_no, setNewInformation_officer_cell_no] = useState('');
  const [new_marketing_email, setNewMarketing_email] = useState('');
  const [new_debtor_vatnr, setNewDebtor_vatnr] = useState('');
  const [isInformationOfficer, setIsInformationOfficer] = useState(false);
  const [newWebsite, setNewWebsite] = useState('');
  const [newOperationalEmail, setNewOperationalEmail] = useState('');
  const [newFinancialEmail, setNewFinancialEmail] = useState('');
  const [buttonState, changeButtonState] = useState(false);

  const GoToHome = () => history.push({ pathname: `/` });

  const setButtonState = async () => {
    changeButtonState(true)
  }

  const submitNewInformation = async () => {
    const createInfoRequest = async () => {
      const resp = await post(`/create_info_requests`, {'street': street,
      'street2': street2,
      'city': city,
      'zip': zip,
      'phone': phone,
      'email':email,
      'mobile': mobile,
      'fax':fax,
      'information_officer_name':information_officer_name,
      'information_officer':information_officer,
      'information_officer_cell_no':information_officer_cell_no,
      'marketing_email':marketing_email,
      'debtor_vatnr':debtor_vatnr,
      'name':name,
      'website':website,
      'operationalEmail':operationalEmail,
      'financialEmail':financialEmail,
      'newName':newName,
      'newStreet':newStreet,
      'newStreet2':newStreet2,
      'newCity':newCity,
      'newZip':newZip,
      'newPhone':newPhone,
      'newEmail':newEmail,
      'newMobile':newMobile,
      'newFax':newFax,
      'new_information_officer_name':new_information_officer_name,
      'new_information_officer':new_information_officer,
      'new_information_officer_cell_no':new_information_officer_cell_no,
      'new_marketing_email':new_marketing_email,
      'new_debtor_vatnr':new_debtor_vatnr,
      'newWebsite':newWebsite,
      'newOperationalEmail':newOperationalEmail,
      'newFinancialEmail':newFinancialEmail,
      'isInformationOfficer':isInformationOfficer,
      'user':user
    });

      if (resp.ok) {
        setListReleases(await resp.json())
        clickNewRequest()
      }
    };
    createInfoRequest();
  }

  const goBack = async () => {
    changeButtonState(false)
  }
  const clickNewRequest = () =>
  history.push({ pathname: `/Info-Update-Requests` });

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      const resp = await get(`/get_business_details`);

      if (resp.ok) {
        setListReleases(await resp.json())
      }
    };
    fetchBusinessDetails();

    const fetchInformationOfficer = async () => {
      const auth = await post(`/check_is_information_officer`, user);

      if (auth.ok) {
        setIsInformationOfficer(await auth.json())
      }
    };

    fetchInformationOfficer();
  }, []);

  useEffect(() => {
    if(typeof (listReleases[0]) !== "undefined") {
      setName(listReleases[0].name);
      setNewName(listReleases[0].name);
      setStreet(listReleases[0].street);
      setNewStreet(listReleases[0].street);
      setStreet2(listReleases[0].street2);
      setNewStreet2(listReleases[0].street2);
      setCity(listReleases[0].city);
      setNewCity(listReleases[0].city);
      setZip(listReleases[0].zip);
      setNewZip(listReleases[0].zip);
      setEmail(listReleases[0].email);
      setNewEmail(listReleases[0].email);
      setFax(listReleases[0].fax);
      setWebsite(listReleases[0].website);
      setFinancialEmail(listReleases[0].financial_email);
      setOperationalEmail(listReleases[0].operational_email);
      setnewFax(listReleases[0].fax);
      setPhone(listReleases[0].phone);
      setNewPhone(listReleases[0].phone);
      setMobile(listReleases[0].mobile);
      setNewMobile(listReleases[0].mobile);
      setInformation_officer_name(listReleases[0].information_officer_name);
      setNewInformation_officer_name(listReleases[0].information_officer_name);
      setInformation_officer(listReleases[0].information_officer);
      setNewInformation_officer(listReleases[0].information_officer);
      setInformation_officer_cell_no(listReleases[0].information_officer_cell_no);
      setNewInformation_officer_cell_no(listReleases[0].information_officer_cell_no);
      setMarketing_email(listReleases[0].marketing_email);
      setNewMarketing_email(listReleases[0].marketing_email);
      setDebtor_vatnr(listReleases[0].debtor_vatnr);
      setNewDebtor_vatnr(listReleases[0].debtor_vatnr);
      setNewWebsite(listReleases[0].website);
      setNewOperationalEmail(listReleases[0].operational_email);
      setNewFinancialEmail(listReleases[0].financial_email);
    }
  }, [listReleases]);
  
          
  return (
    <>
    { isInformationOfficer ? 
    <></>:
    <>
    { buttonState ?
      <>
      <ChangedInformation information={{
        'street': street,
        'street2': street2,
        'city': city,
        'zip': zip,
        'phone': phone,
        'email':email,
        'mobile': mobile,
        'fax':fax,
        'information_officer_name':information_officer_name,
        'information_officer':information_officer,
        'information_officer_cell_no':information_officer_cell_no,
        'marketing_email':marketing_email,
        'debtor_vatnr':debtor_vatnr,
        'name':name,
        'website':website,
        'operationalEmail':operationalEmail,
        'financialEmail':financialEmail,
        'newName':newName,
        'newStreet':newStreet,
        'newStreet2':newStreet2,
        'newCity':newCity,
        'newZip':newZip,
        'newPhone':newPhone,
        'newEmail':newEmail,
        'newMobile':newMobile,
        'newFax':newFax,
        'new_information_officer_name':new_information_officer_name,
        'new_information_officer':new_information_officer,
        'new_information_officer_cell_no':new_information_officer_cell_no,
        'new_marketing_email':new_marketing_email,
        'new_debtor_vatnr':new_debtor_vatnr,
        'newWebsite':newWebsite,
        'newOperationalEmail':newOperationalEmail,
        'newFinancialEmail':newFinancialEmail,
        'isInformationOfficer':isInformationOfficer,
      }}/>
      <div className={styles.jc_center}>
        <button onClick={submitNewInformation} className={styles.submitBtnValidate}>
          Information Is Correct
        </button>
        <button onClick={goBack} className={styles.submitBtnValidate}>
          Go Back
        </button>
      </div>
      </>:
    <div className={styles.FormWrapper}>
    <div className={styles.FormHeader}>
      <div className={styles.Heading}>
        <h1>Update Information</h1>
      </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldInfo}>
        Correct the information that is outdated. Then Click on the Send Request Button.
      </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Name
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Street Line 1
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newStreet} onChange={(e) => setNewStreet(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Street Line 2
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newStreet2} onChange={(e) => setNewStreet2(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          City
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newCity} onChange={(e) => setNewCity(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Zip Code
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newZip} onChange={(e) => setNewZip(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Phone Number
      </div>
      <div className={styles.FieldInput}>
        <input type="text" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
      </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
        Email Address
      </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Mobile Number
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newMobile} onChange={(e) => setNewMobile(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Fax
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newFax} onChange={(e) => setnewFax(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Information Officer Name
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={new_information_officer_name} onChange={(e) => setNewInformation_officer_name(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Information Officer Email Address
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={new_information_officer} onChange={(e) => setNewInformation_officer(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Information Officer Cell Number
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={new_information_officer_cell_no} onChange={(e) => setNewInformation_officer_cell_no(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Marketing Email Address
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={new_marketing_email} onChange={(e) => setNewMarketing_email(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Vat Number
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={new_debtor_vatnr} onChange={(e) => setNewDebtor_vatnr(e.target.value)} />
        </div>
    </div>
    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Website
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newWebsite} onChange={(e) => setNewWebsite(e.target.value)} />
        </div>
    </div>

    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Operational Email Address
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newOperationalEmail} onChange={(e) => setNewOperationalEmail(e.target.value)} />
        </div>
    </div>

    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
          Financial Email Address
        </div>
        <div className={styles.FieldInput}>
          <input type="text" value={newFinancialEmail} onChange={(e) => setNewFinancialEmail(e.target.value)} />
        </div>
    </div>
        <button onClick={setButtonState} className={styles.SubmitBtn}>
        Submit New Information
        </button>
    </div>
    }
    </>
    }
    </>
  );
}
export default UpdateInformation;