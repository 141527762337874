import React, { useEffect, useState } from 'react';

import { get } from '../../../api';
import InputSelect from './Inputs/InputSelect';

const fetchCountries = async (setCountries) => {
  const resp = await get('/install_setup/country_list');
  if (resp.ok) {
    setCountries(await resp.json());
  } else {
    console.error(await resp.text());
  }
};

const CountryList = ({ name, value, errors, onChange }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchCountries(setCountries);
  }, [setCountries]);

  return (
    <InputSelect
      {...{ name, value, errors, onChange }}
      options={countries}
      withEmpty
    />
  );
};

export default CountryList;
