import React, { useState } from 'react';


const NotValidOTPChar = (char) => (
    <span>{char} is not a valid OTP (one-time pin) value</span>
  );

const OTPLengthNot5 = <span>OTP (one-time pin) must be 5 characters</span>;

const OTPInput = ({ state: [otp, setOTPState], setMessage }) => {
    const [error, setError] = useState(false);

    const validChars = (value) => {
      [...value].forEach((c) => {
        if ('123456789ABCDEFGHJKLMNPQRSTUVWXYZ'.indexOf(c) === -1) {
          setMessage(NotValidOTPChar(c));
          setError(true);
        }
      });
    };

    const validateOTPLength = () => {
      if (otp.length !== 5) {
        setMessage(OTPLengthNot5);
        setError(true);
      }
    };

    const validateOTP = (value) => {
      if (value) {
        validChars(value);
      }
    };

    const setOTP = (value) => {
      setMessage(null);
      setError(false);
      validateOTP(value);
      setOTPState(value);
    };

    return (
      <React.Fragment>
        <input
          className={['Input', error ? 'Error' : null].join(' ')}
          type="text"
          value={otp}
          onChange={(e) => setOTP(e.target.value.toUpperCase())}
          onBlur={validateOTPLength}
          placeholder="OTP (one-time pin)"
          maxLength="5"
        />
      </React.Fragment>
    );
};

export default OTPInput;
