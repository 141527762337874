import './Issue.scss';

import React from 'react';

import dateDisplay from '../../components/utils/datetime';

const SupportOlderIssuesRow = ({ supportOlderIssues }) => (
  <tr>
    <td>
      <a
        href={`https://odoo.quantsolutions.co.za/issue/${supportOlderIssues.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {supportOlderIssues.id}
      </a>
    </td>
    <td>{supportOlderIssues.issue}</td>
    <td>{supportOlderIssues.fcl_and_stage}</td>
    <td>{dateDisplay(supportOlderIssues.create_date)}</td>
  </tr>
);

const SupportOlderIssuesTable = ({ supportOlderIssues }) => (
  <table id="SupportOlderIssuesTable">
    <thead>
      <tr>
        <th>ID</th>
        <th>Issue</th>
        <th>FCL and Stage</th>
        <th>Created date</th>
      </tr>
    </thead>
    <tbody>
      {supportOlderIssues?.map((support_older_issues) => (
        <SupportOlderIssuesRow
          key={support_older_issues.id}
          supportOlderIssues={support_older_issues}
        />
      ))}
    </tbody>
  </table>
);

export default SupportOlderIssuesTable;
