import React from 'react';
import { Link } from 'react-router-dom';

export const NoToken = () => (
  <div className="Setup">
    <h1>Setup your Install</h1>
    <div>
      <h3>Not Authorized</h3>
      <p>
        Part of the URL is an access token. Please click the link in your email
        again.
      </p>
      <p>
        If you continue to see this error, please contact Goodx Support on{' '}
        <a href="tel:+27128459888">012 845 9888</a>
      </p>
    </div>
  </div>
);

export const ExpiredToken = () => (
  <div className="Setup">
    <h1>Setup your Install</h1>
    <div>
      <h3>Authentication Expired</h3>
      <p>
        The link to this page is only valid for 14 days since you received your
        email.
      </p>
      <p>Please contact Goodx Support, and ask them to send you a new email.</p>
      <p>
        Goodx Support: <a href="tel:+27128459888">012 845 9888</a>
      </p>
    </div>
  </div>
);

const searchSetRedirect = (location) => {
  const search = new URLSearchParams(location.search);
  search.set('redirect', location.pathname);
  return search.toString();
};

export const NotAccepted = ({ location }) => {
  return (
    <div className="Setup">
      <h1>Setup your install</h1>
      <div>
        <h3>Terms and Conditions not accepted</h3>
        <p>
          Please{' '}
          <Link
            to={{
              ...location,
              pathname: '/terms-and-conditions',
              search: searchSetRedirect(location),
            }}
          >
            Click here
          </Link>{' '}
          to read our terms and conditions.
        </p>
        <p>
          You need to accept the terms and conditions to proceed with this form.
        </p>
      </div>
    </div>
  );
};
