import React from 'react';

import Validator from '../Validator';

const InputText = ({ name, value, errors, onChange, maxLength }) => (
  <>
    <label>{name}</label>
    <input
      className={errors ? 'Error' : null}
      type="text"
      {...{ onChange, value, maxLength }}
    />
    <Validator errors={errors} />
  </>
);

export default InputText;
