import './Issue.scss';

import React from 'react';

const CategoriesNotFilledInRow = ({ emptyCategories }) => (
  <tr>
    <td>
      <a
        href={`https://odoo.quantsolutions.co.za/issue/${emptyCategories.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {emptyCategories.id}
      </a>
    </td>
    <td>{emptyCategories.issue}</td>
    <td>{emptyCategories.assigned_to}</td>
  </tr>
);

const CategoriesNotFilledInTable = ({ emptyCategories }) => (
  <table id="CategoriesNotFilledInTable">
    <thead>
      <tr>
        <th>ID</th>
        <th>Issue</th>
        <th>Assigned To</th>
      </tr>
    </thead>
    <tbody>
      {emptyCategories?.map((empty_categories) => (
        <CategoriesNotFilledInRow
          key={empty_categories.id}
          emptyCategories={empty_categories}
        />
      ))}
    </tbody>
  </table>
);

export default CategoriesNotFilledInTable;
