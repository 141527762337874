import './qualityAssurance.scss';

import React from 'react';

const NewBugsPerUser = ({ per_category }) => (
  <tr>
    <td>{per_category.get_qa_new_bugs_per_user_per_sprint.tester}</td>
    <td>{per_category.get_qa_new_bugs_per_user_per_sprint.sprint_name}</td>
    <td>{per_category.get_qa_new_bugs_per_user_per_sprint.count}</td>
  </tr>
);

const NewBugsPerUserPerSprint = ({ per_category }) => (
  <table id="QaTestResultTable">
    <thead>
      <tr>
        <th>User</th>
        <th>Sprint Name</th>
        <th>Count</th>
      </tr>
    </thead>
    <tbody>
      {per_category?.map((per_category) => (
        <NewBugsPerUser
          per_category={per_category}
          key={per_category.get_qa_new_bugs_per_user_per_sprint.tester}
        />
      ))}
    </tbody>
  </table>
);

export default NewBugsPerUserPerSprint;
