import './Issue.scss';

import React from 'react';

const LastUpdateIssueRow = ({ supportIssuesPerFcl }) => (
  <tr>
    <td>{supportIssuesPerFcl.fcl}</td>
    <td>{supportIssuesPerFcl.count}</td>
  </tr>
);

const lastUpdateIssuesTable = ({ supportIssuesPerFcl }) => (
  <table id="LastUpdateIssuesTable">
    <thead>
      <tr>
        <th>FCL</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      {supportIssuesPerFcl?.map((support_issues_per_fcl) => (
        <LastUpdateIssueRow
          key={support_issues_per_fcl.fcl}
          supportIssuesPerFcl={support_issues_per_fcl}
        />
      ))}
    </tbody>
  </table>
);

export default lastUpdateIssuesTable;
