import React from 'react';
import { Bar } from 'react-chartjs-2';

const GraphData = (data) => ({
  labels: data[0].data_labels,
  datasets: [
    {
      type: 'bar',
      label: 'Number of issues',
      data: data[0].number_of_issues_agg,
      fill: false,
      backgroundColor: 'rgba(61, 190, 255)',
      borderColor: 'rgba(33, 88, 217)',
      yAxisID: 'y-axis-1',
    },
    {
      type: 'bar',
      label: 'Affected Clients',
      data: data[0].affected_clients_agg,
      fill: false,
      backgroundColor: 'rgb(30, 152, 179)',
      borderColor: 'rgba(30, 152, 179)',
      yAxisID: 'y-axis-1',
    },
    {
      type: 'bar',
      label: 'Number Of Tasks',
      data: data[0].no_of_tasks_agg,
      fill: false,
      backgroundColor: 'rgb(67, 11, 219)',
      borderColor: 'rgba(67, 11, 219)',
      yAxisID: 'y-axis-1',
    },
  ],
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        ticks: {
            beginAtZero: true,
        }
      },
    ],
  },
};

const BugsIdentifiedPerVersionWeb = (data) => (
  <>
    <div className='header'>
      <h1 className='title'>GoodxWeb</h1>
    </div>
    {data.data[0]?(<Bar data={GraphData(data.data.map((data) => (data.get_qa_bugs_identified_per_version_graph)))} options={options} />):(<div></div>)
    }
  </>
);

export default BugsIdentifiedPerVersionWeb;
