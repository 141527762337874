import React, { useContext } from 'react';

import { AuthTokenContext } from '../../context';
import TicketList from './components/IssueList';

const Home = ({ history }) => {
  const { isTokenValid } = useContext(AuthTokenContext);

  if (!isTokenValid()) {
    history.push({ pathname: '/login' });
    return null;
  } else {
    return <TicketList ticketsStatus={'open'} />;
  }
};

export default Home;
