import React, { useState } from 'react';
import './CustomTab.css';

const CustomTab = ({ label, children, onClick, isSelected }) => { 
  const tabClassName = isSelected ? 'custom-tab selected' : 'custom-tab';
  return (
    <div className={tabClassName} onClick={onClick}>
      <p>{label}</p>
      {children}
    </div>
  );
};

const CustomTabList = ({ children }) => {
  return <div className="custom-tab-list">{children}</div>;
};

const CustomTabPanel = ({ children, isSelected, display }) => {
  const panelClassName = isSelected ? 'custom-tab-panel selected' : 'custom-tab-panel';
  return <div className={panelClassName} style={{ "display": !isSelected ? display : "flex" }}>{children}</div>;
};

const CustomTabs = ({ children, display }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  const selectTab = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="custom-tabs">
      <CustomTabList>
        {children.map((child, index) => (
          <CustomTab 
            key={index}
            label={child.props.label}
            onClick={() => {
              selectTab(index);
              !collapsed ? setCollapsed(true) : setCollapsed(false);
            }}
            isSelected={selectedTab === index}
          />
        ))}
      </CustomTabList>
      <div className="custom-tab-content" style={{ display: !collapsed ? display : "flex" }}>
        {children.map((child, index) => (
          <CustomTabPanel key={index} isSelected={selectedTab === index}>
            {child.props.children}
          </CustomTabPanel>
        ))}
      </div>
    </div>
  );
};

export { CustomTabs, CustomTabList, CustomTab, CustomTabPanel };