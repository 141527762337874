import React from 'react';
import { Bar } from 'react-chartjs-2';

const GraphData = (data) => ({
   labels: data[0].data_labels,
  datasets: [
    {
      type: 'line',
      label: 'Total Time',
       data: data[0].total_minutes,
      fill: false,
      backgroundColor: 'rgb(129, 50, 168)',
      borderColor: 'rgba(67, 11, 219)',
      yAxisID: 'y-axis-1',
    },

  ],
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const TotalTimeSpentPerDepartmentDesktop = (data) => (
  <>
    <div className="header">
      <h1 className="title">Total Time</h1>
    </div>
    {data.data[0] ? (
      <Bar
        data={GraphData(
          data.data.map(
            (data) => data.get_qa_time_spent_per_department_per_release,
          ),
        )}
        options={options}
      />
    ) : (
      <div></div>
    )}
  </>
);

export default TotalTimeSpentPerDepartmentDesktop;
