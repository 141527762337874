import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import Stars from '../../../../../components/stars/Stars';
import styles from './TicketFeedback.module.scss';

import DesktopLayout  from '../../../../../components/utils/DesktopLayout';
import MobileLayout  from '../../../../../components/utils/MobileLayout';
import responsive from '../../../../../responsive.module.scss';

const BoolResponse = ({ value }) => (
  <FontAwesomeIcon
    icon={value ? faThumbsUp : faThumbsDown}
    color={value ? '#44AF69' : '#E0323B'}
    className={styles.thumbicon}
  />
);

const cutText = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit - 1) + '…';
  }
  return text;
};

const TicketFeedback = ({ response }) => {
  const [short, setShort] = useState(true);

  const toggleShort = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target;
    const parent = target.parentNode;
    if (short) {
      const width = target.offsetWidth;
      console.log(target, width);
      parent.style.maxWidth = width + 'px';
    } else {
      parent.style.maxWidth = '';
    }
    setShort((s) => !s);
  };

  return (
    <>
    <DesktopLayout>
      <div className={styles.TicketFeedbackResponse}>
        <div>Resolved: </div>
        <div><BoolResponse value={response.resolved} /></div>
        <div>Satisfied: </div>
        <div><BoolResponse value={response.satisfied} /></div>
        <div className={styles.colspan}><Stars value={response.rating} /></div>
        <div>Comment: </div>
        <div>
          <span onPointerEnter={toggleShort} onPointerLeave={toggleShort}>
            {short ? cutText(response.comment, 80) : response.comment}
          </span>
        </div>
      </div>
    </DesktopLayout>
    <MobileLayout>
      <hr/>
      <div className={responsive.TicketFeedbackResponse}>
        <div>Resolved: <BoolResponse value={response.resolved} /></div>
        <div>Satisfied: <BoolResponse value={response.satisfied} /></div>
        <div className={styles.colspan}><Stars value={response.rating} /></div>
        <div>
          <span onPointerEnter={toggleShort} onPointerLeave={toggleShort}>
            <i className="material-icons">chat</i>{short ? cutText(response.comment, 80) : response.comment}
          </span>
        </div>
      </div>
    </MobileLayout>
    </>
  );
};

export default TicketFeedback;
