import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NoSurvey from './no-survey/NoSurvey';
import Setup from './setup';
import SurveyList from './survey-list/SurveyList';
import TicketFeedback from './ticket-feedback/TicketFeedback';

const Survey = () => {
  return (
    <Switch>
      <Route path="/survey/ticket-feedback" component={TicketFeedback} />
      <Route path="/survey/setup" component={Setup} />
      <Route path="/survey" exact component={SurveyList} />
      <Route component={NoSurvey} />
    </Switch>
  );
};

export default Survey;
