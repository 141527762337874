import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { post } from '../../../api';
import EmailInput from '../../../components/inputs/EmailInput';
import styles from '../Login.module.scss';

const Mailto = () => (
  <a href="mailto:help@goodx.co.za" target="_blank" rel="noopener noreferrer">
    help@goodx.co.za
  </a>
);

const SomethingWentWrong = (
  <React.Fragment>
    <div className={styles.ErrorMsg}>
			<div className={styles.Icon}><i className="material-icons">warning</i></div>
			Something went wrong. Please try again or log a ticket with <Mailto />.
		</div>
  </React.Fragment>
);

const EmailNotExist = (
  <React.Fragment>
		<div className={styles.ErrorMsg}>
			<div className={styles.Icon}><i className="material-icons">warning</i></div>
			The email address has not yet been registered. Please contact <Mailto /> to request a Client Portal new account.
		</div>
  </React.Fragment>
);

const RegisterForm = ({ history }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const urlLocation = history.location.pathname;

  const submit = async (event) => {
    event.preventDefault();

      const resp = await post('/user/email_exists', { email });

      if (!resp.ok) {
        setMessage(SomethingWentWrong);
        return false;
      }

      const json = await resp.json();
      if (!json.exists) {
        setMessage(EmailNotExist);
      }
      return json.exists;
    };

  return (
    <form onSubmit={submit} className={styles.RegisterFrm}>
			<EmailInput state={[email, setEmail, urlLocation]} setMessage={setMessage} history={history} />

			<Link className={styles.BackLoginBtn} to="/">
        Back to Login
      </Link>

      <div className={styles.Messages}>
				{message}
			</div>
    </form>
  );
};

export default withRouter(RegisterForm);
