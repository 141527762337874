import React from 'react';

import TicketFeedback from './ticket-feedback/TicketFeedback';

const Response = ({ response, type }) => {
  switch (type) {
    case 'ticket-feedback':
      return <TicketFeedback response={response} />;
    default:
      return <span>Response Type ({type}) has no display method</span>;
  }
};

export default Response;
