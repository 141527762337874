import './Message.scss';

import React from 'react';

import { dateDisplay } from '../home/components/IssueList';
import Attachments from './Attachments';

const SingleMessage = ({ message }) => (
  <div className="MessageWrapper Me">
    <div className="MessageHeader">
      {message.author_name}&ensp;|&ensp;{dateDisplay(message.date + ' GMT')} (
       {new Date(message.date + ' GMT').toLocaleString('en-GB')})
    </div>
    <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
    <Attachments attachments={message.attachments} />
    <div className="MessageFooter">Message ID: {message.id}</div>
  </div>
);

const Messages = ({ messages }) => (
  <div className="Messages">
    {messages.map((message) => (
      <SingleMessage key={message.id} message={message} />
    ))}
  </div>
);

export default Messages;
