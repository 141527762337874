import './Issue.scss';

import {
  faArrowRight,
  faBolt,
  faCircle,
  faClinicMedical,
  faPeopleCarry,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { get } from '../../api';
import CategoriesNotFilledInTable from './CategoriesNotFilledIn.jsx';
import LastUpdateIssuesTable from './LastUpdatedIssues.jsx';
import LeaderBoardIssuesTable from './LeaderBoard.jsx';
import ProjectPerStageTable from './ProjectPerStage.jsx';
import SupportActiveIssuesTable from './SupportActiveIssues.jsx';
import SupportOlderIssuesTable from './SupportOlderIssues.jsx';

const Issue = () => {
  const [userStats, setUserStats] = useState([]);
  const [supportIssues, setSupportIssues] = useState([]);
  const [getIssuesPerFcl, setIssuesPerFcl] = useState([]);
  const [userClosedStats, setUserClosedStats] = useState([]);
  const [projectPerStage, setProjectPerStage] = useState([]);
  const [olderIssues, setOlderIssues] = useState([]);
  const [emptyCategory, setEmptyCategories] = useState([]);
  const [getDevopsStats, setDevopsStats] = useState([]);

  const fetchSupport = async (url, setFn) => {
    const resp = await get(url);
    if (resp.ok) {
      setFn(await resp.json());
    }
    return resp.ok;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchSupport('/support/issues', setUserStats);
      fetchSupport('/support/getCurrentActiveIssues', setSupportIssues);
      fetchSupport('/support/getIssuesPerFcl', setIssuesPerFcl);
      fetchSupport('/support/getUserClosedStats', setUserClosedStats);
      fetchSupport('/support/getProjectPerStage', setProjectPerStage);
      fetchSupport('/support/getOlderIssues', setOlderIssues);
      fetchSupport('/support/getEmptyCategories', setEmptyCategories);
      fetchSupport('/support/devopsStats', setDevopsStats);
    }, 60000);
    return () => clearInterval(interval);
  });

  return (
    <div className="row">
      <div className="col-md-1">
        <div className="card-counter open_tickets">
          <i>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </i>
          <span className="count-name detail">Support Open</span>
          <span className="count-numbers detail">
            {userStats.support_open_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter platinum">
          <i>
            <FontAwesomeIcon icon={faBolt} />
          </i>
          <span className="count-name detail">Out Platinum</span>
          <span className="count-numbers detail">
            {userStats.platinum_open_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter help">
          <i>
            <FontAwesomeIcon icon={faPeopleCarry} />
          </i>
          <span className="count-name detail">Out Help</span>
          <span className="count-numbers detail">
            {userStats.help_open_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter devops">
          <i>
            <FontAwesomeIcon icon={faClinicMedical} />
          </i>
          <span className="count-name detail">Devops Stats</span>
          <span className="count-numbers detail">
            {getDevopsStats.ops_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card-counter incoming_tickets">
          <i>
            <FontAwesomeIcon icon={faCircle} />
          </i>
          <span className="count-name detail">Today In</span>
          <span className="count-numbers detail">
            {userStats.platinum_today_incoming_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card-counter outgoing_tickets">
          <i>
            <FontAwesomeIcon icon={faArrowRight} />
          </i>
          <span className="count-name detail">Today Out</span>
          <span className="count-numbers detail">
            {userStats.platinum_today_outgoing_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card-counter incoming_tickets">
          <i>
            <FontAwesomeIcon icon={faCircle} />
          </i>
          <span className="count-name detail">Today In</span>
          <span className="count-numbers detail">
            {userStats.help_today_incoming_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card-counter outgoing_tickets">
          <i>
            <FontAwesomeIcon icon={faArrowRight} />
          </i>
          <span className="count-name detail">Today Out</span>
          <span className="count-numbers detail">
            {userStats.help_today_outgoing_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card-counter incoming_tickets">
          <i>
            <FontAwesomeIcon icon={faCircle} />
          </i>
          <span className="count-name detail">Today In</span>
          <span className="count-numbers detail">
            {getDevopsStats.today_backfrom_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card-counter outgoing_tickets">
          <i>
            <FontAwesomeIcon icon={faArrowRight} />
          </i>
          <span className="count-name detail">Today Out</span>
          <span className="count-numbers detail">
            {getDevopsStats.today_outgoing_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-3-table">
        <div className="detail2">Issues Per FCL</div>
        <span className="count-name category_view">
          <LastUpdateIssuesTable supportIssuesPerFcl={getIssuesPerFcl} />
        </span>
      </div>
      <div className="col-md-3-table">
        <div className="detail2">Project Per Stage</div>
        <span className="count-name category_view">
          <ProjectPerStageTable projectPerStage={projectPerStage} />
        </span>
      </div>
      <div className="col-md-3-table">
        <div className="detail2">Leader Board</div>
        <span className="count-numbers category_view">
          <LeaderBoardIssuesTable supportClosedStats={userClosedStats} />
        </span>
      </div>
      <div className="col-md-2">
        <div className="detail2">Older than 5 days</div>
        <span className="count-numbers category_view">
          <SupportOlderIssuesTable supportOlderIssues={olderIssues} />
        </span>
      </div>
      <div className="col-md-2">
        <div className="detail2">Active Tickets</div>
        <span className="count-name category_view">
          <SupportActiveIssuesTable supportActiveIssues={supportIssues} />
        </span>
      </div>
      <div className="col-md-2">
        <div className="detail2">Categories not filled in</div>
        <span className="count-numbers category_view">
          <CategoriesNotFilledInTable emptyCategories={emptyCategory} />
        </span>
      </div>
    </div>
  );
};

export default Issue;
