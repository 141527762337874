import { get } from '../../../api';

import {
  emailValidator,
  // fileLT2MBValidator,
  numberValidator,
  phoneNumberValidator,
  practiceNumberValidator,
  requiredValidator,
  trimValidator,
} from './validators';

const validate = (value, validators) =>
  validators
    .map((validator) => validator(value))
    .filter((e) => e !== null)
    .join(' · ') || null;

const defaultValue = (value, validators = []) => ({
  errors: validate(value, validators),
  validators,
  value,
});

const defaultDiaryDayOpen = {
  enabled: defaultValue(true),
  startTime: defaultValue('09:00', [requiredValidator, trimValidator]),
  endTime: defaultValue('17:00', [requiredValidator, trimValidator]),
  interval: defaultValue(15, [requiredValidator]),
};

const defaultDiaryDayClosed = {
  enabled: defaultValue(false),
  startTime: defaultValue('00:00', [requiredValidator], trimValidator),
  endTime: defaultValue('00:00', [requiredValidator, trimValidator]),
  interval: defaultValue(15, [requiredValidator]),
};

const defaultDiary = {
  sunday: defaultDiaryDayClosed,
  monday: defaultDiaryDayOpen,
  tuesday: defaultDiaryDayOpen,
  wednesday: defaultDiaryDayOpen,
  thursday: defaultDiaryDayOpen,
  friday: defaultDiaryDayOpen,
  saturday: defaultDiaryDayClosed,
};

export const fetchBusinessDetails = async (
  dispatch,
  token
) => {
  const resp = await get(`/quotation/business_details/${token}`);
  if (resp.ok) {
    let data = await resp.json(); 

    dispatch({
      fields: ["entities", 0, 'filterOut()'],
    })

    try {
      for (const [key, val] of Object.entries(data["businessDetails"])) {
        dispatch({
          fields: ["businessDetails", key],
          value: val["value"]         
        })
      }

      for (let i = 0; i < data["entities"].length; i++) {
        dispatch({
          fields: ["entities", 'append()'],
          value: data["entities"][i],
        })
      }
    }
    catch (err) {
      console.log("ERR", err);
    }
  }
};

export const defaultTreatingDoctor = {
  doctorDetails: {
    title: defaultValue('', [requiredValidator, trimValidator]),
    firstNames: defaultValue('', [requiredValidator, trimValidator]),
    lastName: defaultValue('', [requiredValidator, trimValidator]),
    speciality: defaultValue('', [requiredValidator]),
    practiceNumber7: defaultValue('', [
      requiredValidator,
      trimValidator,
      practiceNumberValidator,
    ]),
    mpNumber: defaultValue('', [trimValidator]),
  },
  diary: defaultDiary,
};

export const defaultSystemUser = {
  userFullName: defaultValue('', [requiredValidator, trimValidator]),
  username: defaultValue('', [requiredValidator, trimValidator]),
  email: defaultValue('', [requiredValidator, trimValidator, emailValidator]),
  cellphoneNumber: defaultValue('', [
    requiredValidator,
    trimValidator,
    phoneNumberValidator,
  ]),
  userType: defaultValue('doctor'),
  accountType: defaultValue('gxweb'),
};

export const defaultEntity = {
  basicDetails: {
    name: defaultValue('', [requiredValidator, trimValidator]),
    speciality: defaultValue('', [requiredValidator]),
    practiceNumber7: defaultValue('', [trimValidator, practiceNumberValidator]),
    practiceNumber13: defaultValue('', [
      trimValidator,
      practiceNumberValidator,
    ]),
    councilRegistrationNumber: defaultValue('', [trimValidator]),
    vatNumber: defaultValue('', [trimValidator]),
    telNumber: defaultValue('', [numberValidator, requiredValidator, trimValidator]),
    cellNumber: defaultValue('', [numberValidator, requiredValidator, trimValidator]),
    faxNumber: defaultValue('', [numberValidator, trimValidator]),
    email: defaultValue('', [requiredValidator, trimValidator]),
    website: defaultValue('', [requiredValidator, trimValidator]),
    postalAddress: defaultValue('', [requiredValidator, trimValidator]),
    postalAddressPostal: defaultValue('', [numberValidator, requiredValidator, trimValidator]),
    streetAddress: defaultValue('', [requiredValidator, trimValidator]),
    streetAddressPostal: defaultValue('', [numberValidator, requiredValidator, trimValidator]),
    country: defaultValue('', [requiredValidator]),
  },
  bankingDetails: {
    bankName: defaultValue('', [trimValidator]),
    branchCode: defaultValue('', [trimValidator]),
    accountName: defaultValue('', [trimValidator]),
    accountType: defaultValue('', [trimValidator]),
    accountNumber: defaultValue('', [numberValidator]),
  },
  financialSetup: {
    yearEndMonth: defaultValue('1'),
    debtorSetup: defaultValue('e'),
    debtorPrefix: defaultValue('', [trimValidator]),
  },
  treatingDoctors: [defaultTreatingDoctor],
  systemUsers: [defaultSystemUser],
  smtpDetails: {
    dkim_host: defaultValue('', [trimValidator]),
    dkim_tls: defaultValue('', [trimValidator]),
    dkim_port: defaultValue('', [numberValidator, trimValidator]),
    dkim_username: defaultValue('', [trimValidator]),
    dkim_password: defaultValue('', [trimValidator])
  }
};

export const defaultState = {
  businessDetails: {
    name: defaultValue('', [requiredValidator, trimValidator]),
    officePhone: defaultValue('', [phoneNumberValidator, trimValidator]),
    officeCell: defaultValue('', [phoneNumberValidator, trimValidator]),
    officeFax: defaultValue('', [phoneNumberValidator, trimValidator]),
    email: defaultValue('', [emailValidator, trimValidator]),
    website: defaultValue('', [trimValidator]),
    // idFile: defaultValue(null, [ fileLT2MBValidator]),
    // bankingDetailsFile: defaultValue(null, [
      
    //   fileLT2MBValidator,
    // ]),
  },
  entities: [defaultEntity],
};

const simpleReducer = (state, fields, value) => {
  if (fields.length > 1) {
    const [section, ...sectionField] = fields;

    if (!isNaN(section)) {
      if (sectionField[0] === 'filterOut()') {
        return state.filter((_, index) => section !== index);
      }

      return state.map((item, i) =>
        i === section ? simpleReducer(item, sectionField, value) : item,
      );
    }

    if (state.hasOwnProperty(section)) {
      return {
        ...state,
        [section]: simpleReducer(state[section], sectionField, value),
      };
    }
  } else {
    const [field] = fields;

    if (state.hasOwnProperty(field)) {
      const current = state[field];
      if (current.hasOwnProperty('validators')) {
        return {
          ...state,
          [field]: {
            ...current,
            errors: validate(value, current.validators),
            value,
          },
        };
      }

      return {
        ...state,
        [field]: {
          ...current,
          value,
        },
      };
    }

    if (field === 'append()') {
      return [...state, value];
    }
  }

  console.error('Field not found:', fields);
  return state;
};

export const containsErrors = (state) => {
  if (state === null) {
    return false;
  }

  if (Array.isArray(state)) {
    for (const e of state) {
      if (containsErrors(e)) {
        return true;
      }
    }
  } else {
    if (state.hasOwnProperty('errors')) {
      return state.errors;      
    }

    if (typeof state === 'object') {
      for (const v of Object.values(state)) {
        if (containsErrors(v)) {
          return true;
        }
      }
    }
  }

  return false;
};

const reducer = (state, action) => {
  const { fields, value } = action;
  return simpleReducer(state, fields, value);
};

export default reducer;
