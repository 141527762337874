import React from 'react';

import styles from './Stars.module.scss';

const priorityAltText = (priority) => {
  switch (priority) {
    case 0:
      return 'Low';
    case 1:
      return 'Medium';
    case 2:
      return 'High';
    case 3:
      return 'Maintenance';
    case 4:
      return 'Bug Fixes';
    case 5:
      return 'Critical';
    default:
      return null;
  }
};

const Stars = ({ value = null, onChange, length = 5, fillChar = '★' }) => {
  onChange = onChange === undefined ? () => {} : onChange;
  const empty = length - value;

  return (
    <div className={styles.Stars} title={priorityAltText(value)}>
      {new Array(value).fill().map((_, i) => (
        <span key={i} onClick={() => onChange(i + 1)} className={styles.Filled}>
          {fillChar}
        </span>
      ))}
      {new Array(empty).fill().map((_, i) => (
        <span
          key={value + i}
          onClick={() => onChange(value + i + 1)}
          className={styles.Empty}
        >
          {fillChar}
        </span>
      ))}
    </div>
  );
};

export default Stars;
