import './Issue.scss';

import React from 'react';


const PrevEscalations = ({escalateObj}) => {

    return (
        <>
        <h2>Previous Escalations (X2)</h2>
        {escalateObj ?
          escalateObj.map((item) =>
          <>
          <div className="OverviewRow">
            <i className="material-icons">event</i>
            <span className="field">Create date:</span>
            <span className="value">{item.create_date}</span>
          </div>

          <div className="OverviewRow">
            <i className="material-icons">description</i>
            <span className="field">Description:</span>
            <span className="value">{item.escalate_desc}</span>
          </div>

          {item.reason_desc ?
          <div className="OverviewRow">
            <i className="material-icons">keyboard</i>
            <span className="field">Reason:</span>
            <span className="value">{item.reason_desc}</span>
          </div>
          : null
          }
          </>
        ) : null
        }
    </>
    );
};

export default PrevEscalations;