import React from 'react';

import InputText from './Inputs/InputText';

const BusinessDetails = ({ fields, businessDetails, dispatch }) => (
  <>
    <h3>Business Details</h3>
    <div className="Inputs">
      <InputText
        name="Name"
        value={businessDetails.name.value}
        errors={businessDetails.name.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'name'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Phone Number"
        value={businessDetails.officePhone.value}
        errors={businessDetails.officePhone.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'officePhone'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Cellphone Number"
        value={businessDetails.officeCell.value}
        errors={businessDetails.officeCell.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'officeCell'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Fax Number"
        value={businessDetails.officeFax.value}
        errors={businessDetails.officeFax.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'officeFax'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Email Address"
        value={businessDetails.email.value}
        errors={businessDetails.email.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'email'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Website"
        value={businessDetails.website.value}
        errors={businessDetails.website.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'website'],
            value: e.target.value,
          })
        }
      />
      {/* <InputUpload
        name="Upload ID"
        accept="image/*, application/pdf"
        value={businessDetails.idFile.value}
        errors={businessDetails.idFile.errors}
        onChange={(value) => dispatch({ fields: [...fields, 'idFile'], value })}
      /> */}
      {/* <InputUpload
        name="Upload Proof of Banking Details"
        accept="image/*, application/pdf"
        value={businessDetails.bankingDetailsFile.value}
        errors={businessDetails.bankingDetailsFile.errors}
        onChange={(value) =>
          dispatch({
            fields: [...fields, 'bankingDetailsFile'],
            value,
          })
        }
      /> */}
    </div>
  </>
);

export default BusinessDetails;
