import React from 'react';

const TodayInIssuesRow = ({ issues_today }) => (
  <tr>
    <td>
      <a
        href={`https://odoo.quantsolutions.co.za/issue/${issues_today.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {issues_today.issue}
      </a>
    </td>
    <td>{issues_today.assigned_to}</td>
    <td>{issues_today.stage}</td>
    <td>{issues_today.product_type_description}</td>
    <td>{issues_today.site}</td>
  </tr>
);

const TodayInIssuesTable = ({ issues_today }) => (
  <table id="ActiveIssuesTable">
    <thead>
      <tr>
        <th>Issue</th>
        <th>Assigned To</th>
        <th>Stage</th>
        <th>Product Type Desc</th>
        <th>Site</th>
      </tr>
    </thead>
    <tbody>
      {issues_today.map((issues_today) => (
        <TodayInIssuesRow
          key={issues_today.issue_id}
          issues_today={issues_today}
        />
      ))}
    </tbody>
  </table>
);

export default TodayInIssuesTable;
