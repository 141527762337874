import './qualityAssurance.scss';

import React from 'react';

const UserTimeSpentPerSprintRow = ({ per_category }) => (
  <tr>
    <td>{per_category.get_qa_user_time_spent_per_sprint.tester}</td>
    <td>{per_category.get_qa_user_time_spent_per_sprint.sprint_name}</td>
    <td>{per_category.get_qa_user_time_spent_per_sprint.distinct_tasks}</td>
    <td>{per_category.get_qa_user_time_spent_per_sprint.total_tasks}</td>
    <td>
      {per_category.get_qa_user_time_spent_per_sprint.total_time_spent_minutes}
    </td>
    <td>
      {per_category.get_qa_user_time_spent_per_sprint.total_time_spent_hours}
    </td>
  </tr>
);

const UserTimeSpentPerSprint = ({ per_category }) => (
  <table id="TimeStatsTable">
    <thead>
      <tr>
        <th>Tester</th>
        <th>Sprint Name</th>
        <th>Distinct Tasks</th>
        <th>Total Tasks</th>
        <th>Total Time Spent Minutes</th>
        <th>Total Time Spent Hours</th>
      </tr>
    </thead>
    <tbody>
      {per_category?.map((per_category) => (
        <UserTimeSpentPerSprintRow
          key={per_category.get_qa_user_time_spent_per_sprint.tester}
          per_category={per_category}
        />
      ))}
    </tbody>
  </table>
);

export default UserTimeSpentPerSprint;
