import './Setup.scss';

import React, { useContext, useReducer, useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import { ToastsContext } from '../../../components/toast/Toast';
import parseLocationToken, { parseJwt } from '../../../utils/parseLocationToken';
import BusinessDetails from './BusinessDetails';
import Entities from './Entities';
import { ExpiredToken, NoToken, NotAccepted } from './ErrorScreens';
import save, { markAsCompleted } from './setupAPI';
import reducer, { containsErrors, defaultState, fetchBusinessDetails } from './setupReducer';
import Tab from './Tab/Tab';
import { get } from '../../../api';

const SubmitButton = ({ errors, onClick }) => (
  <button
    className="SubmitButton"
    onClick={onClick}
    disabled={errors}
    title={
      errors
        ? 'Form has errors. Please fix the errors before you can submit.'
        : 'Submit form'
    }
  >
    Complete
  </button>
);

const SaveButton = ({ errors, onClick }) => (
  <button
    className="SaveButton"
    onClick={onClick}
    disabled={errors}
    title={
      errors
        ? 'Form has errors. Please fix the errors before you can save.'
        : 'Save form'
    }
  >
    Save
  </button>
);

const Setup = ({ location }) => {
  const [{ businessDetails, entities }, dispatch] = useReducer(
    reducer,
    defaultState,
  );
  const { token, expired } = parseLocationToken(location);
  const [page, setPage] = useState('businessDetails');
  const { appendToast } = useContext(ToastsContext);
  const quote_id = parseJwt(token)["qid"];
  const [paCompleted, setPaCompleted] = useState({});
  
  useEffect(() => {
    if (token) {
      const fetchPaStatus = async () => {
        const resp = await get(`/quotation/get_pa_status/${quote_id}`);

        if (resp.ok) {
          setPaCompleted(await resp.text());
          fetchBusinessDetails(dispatch, quote_id);
        }
      };

      fetchPaStatus();
      }
    }, [token, quote_id]);

  if (!token) {
    return <NoToken />;
  }

  if (expired) {
    return <ExpiredToken />;
  }

  if (!(location.state && location.state.accepted)) {
    return <NotAccepted {...{ location }} />;
  }

  return (
    <div className="Setup">
      <div className="title">
        <label>Setup your Install</label>
      </div>
      <div className="TabList">
        <Tab
          name="Business Details"
          active={page === 'businessDetails'}
          error={containsErrors(businessDetails)}
          onChange={() => setPage('businessDetails')}
        />
        <Tab
          name={`Entities (${entities.length})`}
          active={page === 'entities'}
          error={containsErrors(entities)}
          onChange={() => setPage('entities')}
        />
      </div>
      <div className="Page">
        {page === 'businessDetails' ? (
          <BusinessDetails
            fields={['businessDetails']}
            dispatch={dispatch}
            businessDetails={businessDetails}
          />
        ) : null}
        {page === 'entities' ? (
          <Entities
            fields={['entities']}
            entities={entities}
            dispatch={dispatch}
          />
        ) : null}
      </div>
      
      <SaveButton
        errors={containsErrors({ businessDetails, entities })}
        onClick={() => save({ businessDetails, entities }, token, appendToast)}
      />
      {paCompleted === 'false' ?
        <SubmitButton
          errors={containsErrors({ businessDetails, entities })}
          onClick={() => markAsCompleted({ businessDetails, entities }, token, appendToast)}
        />
      : null
      }
    </div>
  );
};

export default withRouter(Setup);
