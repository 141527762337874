import React from 'react';

import InputText from './Inputs/InputText';
import Speciality from './Speciality';
import CountryList from './CountryList';

const EntityBasicDetails = ({ fields, basicDetails, dispatch }) => (
  <div>
    <h3>Basic Details</h3>
    <div className="Inputs">
      <InputText
        name="Entity Name"
        value={basicDetails.name.value}
        errors={basicDetails.name.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'name'],
            value: e.target.value,
          })
        }
      />

      <Speciality
        name="Speciality"
        value={basicDetails.speciality.value}
        errors={basicDetails.speciality.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'speciality'],
            value: parseInt(e.target.value),
          })
        }
      />

      <InputText
        name="Practice Number (7)"
        value={basicDetails.practiceNumber7.value}
        errors={basicDetails.practiceNumber7.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'practiceNumber7'],
            value: e.target.value,
          })
        }
        maxLength="7"
      />

      <InputText
        name="Practice Number (13)"
        value={basicDetails.practiceNumber13.value}
        errors={basicDetails.practiceNumber13.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'practiceNumber13'],
            value: e.target.value,
          })
        }
        maxLength="13"
      />

      <InputText
        name="Council Registration Number"
        value={basicDetails.councilRegistrationNumber.value}
        errors={basicDetails.councilRegistrationNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'councilRegistrationNumber'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="VAT Number"
        value={basicDetails.vatNumber.value}
        errors={basicDetails.vatNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'vatNumber'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Telephone Number"
        value={basicDetails.telNumber.value}
        errors={basicDetails.telNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'telNumber'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Cell Number"
        value={basicDetails.cellNumber.value}
        errors={basicDetails.cellNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'cellNumber'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Fax Number"
        value={basicDetails.faxNumber.value}
        errors={basicDetails.faxNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'faxNumber'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Email Address"
        value={basicDetails.email.value}
        errors={basicDetails.email.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'email'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Website"
        value={basicDetails.website.value}
        errors={basicDetails.website.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'website'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Postal Address"
        value={basicDetails.postalAddress.value}
        errors={basicDetails.postalAddress.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'postalAddress'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Postal Code"
        value={basicDetails.postalAddressPostal.value}
        errors={basicDetails.postalAddressPostal.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'postalAddressPostal'],
            value: e.target.value,
          })
        }
      />
      
      <InputText
        name="Street Address"
        value={basicDetails.streetAddress.value}
        errors={basicDetails.streetAddress.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'streetAddress'],
            value: e.target.value,
          })
        }
      />

      <InputText
        name="Street Postal Code"
        value={basicDetails.streetAddressPostal.value}
        errors={basicDetails.streetAddressPostal.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'streetAddressPostal'],
            value: e.target.value,
          })
        }
      />

      <CountryList
        name="Country"
        value={basicDetails.country.value}
        errors={basicDetails.country.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'country'],
            value: parseInt(e.target.value),
          })
        }
      />
    </div>
  </div>
);

export default EntityBasicDetails;
