import {
  faExclamation,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFilePdf,
  faFileVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { apiURL } from '../../api';
import styles from './Attachments.module.scss';

const Image = ({ attachment }) => (
  <div className={styles.Attachment} title={attachment.filename}>
    <a
      href={apiURL(`/attachment/${attachment.id}`)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={apiURL(`/attachment/${attachment.id}`)}
        alt={attachment.filename}
      />
    </a>
  </div>
);

const strTrunc = (str, length) => {
  if (str.length > length) {
    const start = str.substr(0, length - 12);
    const end = str.substr(str.length - 8, 8);
    return start + '…' + end;
  }

  return str;
};

const File = ({ attachment, icon, mask, transform }) => (
  <div className={styles.Attachment} title={attachment.filename}>
    <a
      href={apiURL(`/attachment/${attachment.id}`)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        icon={icon}
        size="3x"
        mask={mask}
        transform={transform}
      />
      <span>{strTrunc(attachment.filename, 32)}</span>
    </a>
  </div>
);

const Attachment = ({ attachment }) => {
  switch (attachment.mimetype) {
    case null:
    case 'application/octet-stream':
      return (
        <File
          attachment={attachment}
          icon={faExclamation}
          mask={faFile}
          transform="shrink-10 down-2"
        />
      );
    case 'application/pdf':
      return <File attachment={attachment} icon={faFilePdf} />;
    case 'application/x-freearc':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/gzip':
    case 'application/x-rar-compressed':
    case 'application/x-tar':
    case 'application/zip':
    case 'application/x-7z-compressed':
      return <File attachment={attachment} icon={faFileArchive} />;
    default:
      switch (attachment.mimetype.split('/')[0]) {
        case 'text':
          return <File attachment={attachment} icon={faFileAlt} />;
        case 'image':
          return <Image attachment={attachment} />;
        case 'audio':
          return <File attachment={attachment} icon={faFileAudio} />;
        case 'video':
          return <File attachment={attachment} icon={faFileVideo} />;
        default:
          return <File attachment={attachment} icon={faFile} />;
      }
  }
};

const Attachments = ({ attachments }) => (
  <div className={styles.Attachments}>
    {attachments.map((a) => (
      <Attachment key={a.id} attachment={a} />
    ))}
  </div>
);

export default Attachments;
