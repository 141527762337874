import React from 'react';

import InputAutocomplete from './Inputs/InputAutocomplete';
import InputSelect from './Inputs/InputSelect';
import InputText from './Inputs/InputText';

const BankName = ({ name, value, errors, onChange }) => (
  <>
    <InputAutocomplete
      {...{ name, value, errors, onChange }}
      list="bankNames"
    />
    <datalist id="bankNames">
      <option value="Absa" />
      <option value="African Bank" />
      <option value="Bidvest Bank" />
      <option value="Capitec Bank" />
      <option value="Discovery" />
      <option value="First National Bank" />
      <option value="FirstRand Bank" />
      <option value="Grindrod Bank" />
      <option value="Imperial Bank South Africa" />
      <option value="Investec" />
      <option value="Nedbank" />
      <option value="Sasfin Bank" />
      <option value="Standard Bank" />
      <option value="TymeBank" />
      <option value="Ubank" />
    </datalist>
  </>
);

const accountTypeOptions = [
  { id: '1', name: 'Current (Cheque/Bond) Account' },
  { id: '2', name: 'Savings Account' },
  { id: '3', name: 'Transmission Account' },
  { id: '4', name: 'Bond Account' },
  { id: '6', name: 'Subscription Share Account' },
  { id: 'D', name: 'eWallet Account (eWallet Pro)' },
  { id: 'S', name: 'eWallet Account (Send Money)' },
  { id: 'F', name: 'FNB Card Account' },
  { id: 'W', name: 'WesBank' },
];

const BankingDetails = ({ fields, bankingDetails, dispatch }) => (
  <>
    <h3>Banking Details</h3>
    <div className="Inputs">
      <BankName
        name="Bank Name"
        value={bankingDetails.bankName.value}
        errors={bankingDetails.bankName.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'bankName'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Branch Code"
        value={bankingDetails.branchCode.value}
        errors={bankingDetails.branchCode.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'branchCode'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Account Holder"
        value={bankingDetails.accountName.value}
        errors={bankingDetails.accountName.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'accountName'],
            value: e.target.value,
          })
        }
      />
      <InputSelect
        options={accountTypeOptions}
        name="Account Type"
        value={bankingDetails.accountType.value}
        errors={bankingDetails.accountType.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'accountType'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Account Number"
        value={bankingDetails.accountNumber.value}
        errors={bankingDetails.accountNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'accountNumber'],
            value: e.target.value,
          })
        }
      />
    </div>
  </>
);

export default BankingDetails;
