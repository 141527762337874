import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { post } from '../../../api';
import { AuthTokenContext } from '../../../context';
import styles from '../Login.module.scss';

const LoginEmailInput = ({ state: [value, onChange], onBlur, error }) => (
  <input
    className={['Input', error ? 'Error' : null].join(' ')}
    type="text"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    onBlur={onBlur}
    placeholder="Email address"
    autoComplete="email"
  />
);

const LoginPasswordInput = ({ state: [value, onChange], error }) => (
  <input
    className={['Input', error ? 'Error' : null].join(' ')}
    type="password"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    placeholder="Password"
    autoComplete="current-password"
  />
);

const LoginForm = ({ history, pwResetStatus }) => {
  const { setTokenExpires } = useContext(AuthTokenContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const getParams = history.location.search.split('?returl=')[1];

  const submit = async (event) => {
    event.preventDefault();

    const res = await post('/user/login', { email, password });

    if (res.ok) {
      const json = await res.json();
      await setTokenExpires(json.token_expires);
      history.push(getParams || '/');
    } else {
      setError(true);
    }

    return res.ok;
  };

  const goToPwReset = (event) => {
    event.preventDefault();

    history.push("/password-reset");
  };

  const goToRegister = (event) => {
    event.preventDefault();

    history.push("/register");

  };

  return (

		<form onSubmit={submit} className={styles.LoginForm}>
      <div className={styles.FormInputs}>
        <LoginEmailInput state={[email, setEmail]} />
        <LoginPasswordInput state={[password, setPassword]} />
      </div>

      <button
        className={styles.LoginBtn}
        type="submit"
        disabled={!email || !password} >
				Login
			</button>

			<button
        className={styles.RegisterBtn}
        onClick={goToRegister} >
        Register
      </button>

      <button
        className={styles.ResetPassBtn}
        onClick={goToPwReset}
        >
        Reset Password
      </button>

			<div className={styles.Messages}>
				<div className={styles.ErrorMsg}>
					{error ? 'Incorrect email address or password. Please try again.' : null}
				</div>
				<div className={styles.SuccessMsg}>
					{pwResetStatus === 'successful'
						? 'Password reset successful. Please login with your new password.'
						: null}
				</div>
			</div>
    </form>
  );
};

export default withRouter(LoginForm);
export { LoginEmailInput };
