import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { get } from '../../api';
import parseLocationToken from '../../utils/parseLocationToken';
import NoToken from './NoToken';

const Quote = ({ location, match }) => {
  const { id } = match.params;
  const { token } = parseLocationToken(location);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (token && id !== undefined) {
      (async () => {
        const resp = await get(`/quote/${id}/lines`, token);
        if (resp.ok) {
          setLines(await resp.json());
        } else {
          console.error(await resp.text());
        }
      })();
    }
  }, [token, id]);

  if (!token) {
    return <NoToken />;
  }

  return (
    <div className="Page">
      <h2>Quotation</h2>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Full Price</th>
            <th>Your Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {lines.map((line) => (
            <tr>
              <td>{line.code}</td>
              <td>{line.name}</td>
              <td>{line.full_price.toFixed(2)}</td>
              <td>{line.your_price.toFixed(2)}</td>
              <td>{line.quantity}</td>
              <td>{line.your_price * line.quantity}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ borderTop: '1px solid black' }}>
              {lines
                .reduce(
                  (previous, current) =>
                    previous + current.your_price * current.quantity,
                  0,
                )
                .toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(Quote);
