import './qualityAssurance.scss';

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const TestsPerUserGraph = ({ per_user }) => (
  <>
    <div className="header">
      <h1 className="title">Tests Done</h1>
    </div>
    {per_user[0] ? <Doughnut data={TestsDone(per_user[0])} /> : <div></div>}
  </>
);

const TestsDone = (per_user) => ({
  labels: per_user.get_qa_tests_per_user_graph.data_labels,
  datasets: [
    {
      label: 'Failed',
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      pointBorderColor: 'rgba(75,192,192,1)',
      data: per_user.get_qa_tests_per_user_graph.tests_done,
    },
  ],
});

export default TestsPerUserGraph;
