import React, { useContext,useEffect, useState } from 'react';
import { CustomTabs, CustomTab } from '../../utils/CustomTabs';
import MobileLayout  from '../../components/utils/MobileLayout';
import DesktopLayout  from '../../components/utils/DesktopLayout';
import ChangedInformation from './ChangedInformation';
import { UserContext } from '../../context';

import { get, post } from '../../api';
import styles from './UpdateInformation.module.scss';

const InfoUpdateRequests = ({ history }) => {
  const { user } = useContext(UserContext);
  const [infoRequests, setInfoRequests] = useState([{}]);
  const [isInformationOfficer, setIsInformationOfficer] = useState(false);

  const GoToHome = () => history.push({ pathname: `/` });

  const OptionsToolbar = ({test}) => {
    return (
      <>
        <div className={styles.OptionsToolbar}>
          <div className={styles.Heading}>
              <h1>Open Requests</h1>
          </div>
          <button onClick={clickNewRequest} className={styles.submitBtnValidate}>
            Create New Request
          </button>
        </div>
      </>
    );
  };

  const clickHandler = (issueId) =>
    history.push({ pathname: `/request/${issueId}` });

  const clickNewRequest = () =>
    history.push({ pathname: `/update-information` });

  const RequestCard = ({ issue, onClick }) => (
    <>
    <DesktopLayout>
      <div className={styles.IssueCard} onClick={() => onClick(issue.id)} title="Click to view this ticket">
        <div className={styles.CardFirstRow}>
        </div>
        <h1>{issue.name}</h1>
  
  
        <div className={styles.CardCol}>
          <div className={styles.CardRow}>
            <i className="material-icons">outlined_flag</i>
            <span>{issue.create_date}</span>
          </div>

          <div className={styles.CardRow}>
          <i className="material-icons">folder_open</i>
          <span>{issue.requeste}</span>
        </div>
  
          <div className={styles.CardRow}>
            <i className="material-icons">schedule_send</i>
            <span>{issue.state === 'B' ?
                    'Requested':
                    issue.state === 'C' ?
                    'In Review':
                    issue.state === 'D' ?
                    'Approved':
                    issue.state === 'E' ?
                    'Declined':''}</span>
          </div>
  
        </div>
  
      </div>
    </DesktopLayout>
    </>
    )

  const RequestCards = ({ issues, onClick }) => (
    <>
    <DesktopLayout>
      {infoRequests ?
        <div className={styles.CardsWrapper}>
          {infoRequests.map((issue) => (
            <RequestCard key={issue.id} issue={issue} onClick={onClick} />
          ))}
        </div>
      : <span>No Requests</span>}
    </DesktopLayout>
  
   
    </>
  );

  useEffect(() => { 
    const fetchInformationOfficer = async () => {
      const auth = await post(`/check_is_information_officer`, user);

      if (auth.ok) {
        setIsInformationOfficer(await auth.json())
      }
    };
    

    fetchInformationOfficer();
  }, []);

  useEffect(() => {
    const getInfoRequests = async () => {
        const auth = await post(`/get_info_requests`, user);
  
        if (auth.ok) {
            setInfoRequests(await auth.json())
        }
      };
      
      getInfoRequests();
  }, []);
          
  return (
    <>
    { isInformationOfficer ? 
    <></>:
        <>
        <OptionsToolbar test={'s'}></OptionsToolbar>
        <RequestCards onClick={clickHandler}></RequestCards>
        </>
    }
    </>
  );
}
export default InfoUpdateRequests;