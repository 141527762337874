import React from 'react';

import InputSelect from './Inputs/InputSelect';
import InputText from './Inputs/InputText';

const yearEndMonthOptions = [
  { id: '1', name: 'January' },
  { id: '2', name: 'February' },
  { id: '3', name: 'March' },
  { id: '4', name: 'April' },
  { id: '5', name: 'May' },
  { id: '6', name: 'June' },
  { id: '7', name: 'July' },
  { id: '8', name: 'August' },
  { id: '9', name: 'September' },
  { id: '10', name: 'October' },
  { id: '11', name: 'November' },
  { id: '12', name: 'December' },
];

const debtorSetupOptions = [
  { id: 'e', name: 'Unique per Entity' },
  { id: 'd', name: 'Global Debtors (Own File Numbers)' },
  { id: 'f', name: 'Global Debtors and File Numbers' },
];

const FinancialSetup = ({ fields, financialSetup, dispatch }) => (
  <>
    <h3>Financial Setup</h3>
    <div className="Inputs">
      <InputSelect
        name="Year End Month"
        value={financialSetup.yearEndMonth.value}
        errors={financialSetup.yearEndMonth.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'yearEndMonth'],
            value: e.target.value,
          })
        }
        options={yearEndMonthOptions}
      />
      <InputSelect
        name="Debtor Setup"
        value={financialSetup.debtorSetup.value}
        errors={financialSetup.debtorSetup.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'debtorSetup'],
            value: e.target.value,
          })
        }
        options={debtorSetupOptions}
      />

      <InputText
        name="Debtor Prefix"
        value={financialSetup.debtorPrefix.value}
        errors={financialSetup.debtorPrefix.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'debtorPrefix'],
            value: e.target.value,
          })
        }
      />
    </div>
  </>
);

export default FinancialSetup;
