import './RequestPwReset.scss';

import React, { useState } from 'react';

import { get, post } from '../../api';

const RequestPwReset = () => {
  const [email, setEmailState] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailSent, setEmailSent] = useState(false);

  const setEmail = async (event) => {
    const { value } = event.target;
    setEmailSent(false);
    setEmailState(value);

    if (value.length === 0) {
      return true;
    }

    const resp = await get(`/user/emails/${value}`);

    if (resp.ok) {
      setEmails(await resp.json());
    }

    return resp.ok;
  };

  const requestResetSubmit = async (email) => {
    const resp = await post('/user/request_reset', { email: email });

    if (resp.ok) {
      setEmailState(email);
      setEmails([]);
      setEmailSent(true);
    }

    return resp.ok;
  };

  const EmailsList = () =>
    email && emails
      ? emails.map((singleEmail) => (
          <div
            key={singleEmail.id}
            className="Email"
            onClick={() =>setEmailState(singleEmail.email)}
          >
            <span>{singleEmail.email}</span>
          </div>
        ))
      : null;

  const EmailSent = () =>
    emailSent === true ? (
      <spen>
        Email Queued to be sent to{' '}
        <a href={'mailto:' + email} target="_blank" rel="noopener noreferrer">
          {email}
        </a>
      </spen>
    ) : null;

  return (
    <div className="RequestPwReset">
      <h3>Search for an email and then click the item to generate the JWT</h3>
      <div className="SearchContainer">
        <div className="InputContainer">
          <span>Contact Email Address</span>
          <input
            type="search"
            placeholder="Email Address"
            autoComplete="false"
            value={email}
            onChange={setEmail}
          />
        </div>
        <div className="EmailList">
          <EmailsList />
        </div>
        <button
          className="requestResubmit"
          onClick={() => requestResetSubmit(email)}
        >
          {`Send email to ${email}`}
        </button>
      </div>

      <div>
        <EmailSent />
      </div>
    </div>
  );
};

export default RequestPwReset;
