import './qualityAssurance.scss';

import React from 'react';

const TimeBasedStatsRow = ({ time_stats }) => (
  <tr>
    <td>{time_stats.qa_timebase_user_stats.username}</td>
    <td>{time_stats.qa_timebase_user_stats.first_seen}</td>
    <td>{time_stats.qa_timebase_user_stats.last_seen}</td>
    <td>{time_stats.qa_timebase_user_stats.workday_minutes}</td>
    <td
      style={{
        backgroundColor:
          time_stats.qa_timebase_user_stats.html_workday_percentage_color,
        fontWeight: 'bolder',
      }}
    >
      {time_stats.qa_timebase_user_stats.workday_percentage}
    </td>
    <td>{time_stats.qa_timebase_user_stats.logged_minutes}</td>
    <td
      style={{
        backgroundColor:
          time_stats.qa_timebase_user_stats.html_percentage_workday_logged,
        fontWeight: 'bolder',
      }}
    >
      {time_stats.qa_timebase_user_stats.logged_percentage}
    </td>
    <td>{time_stats.qa_timebase_user_stats.times_logged}</td>
    <td>{time_stats.qa_timebase_user_stats.issues_worked_on}</td>
  </tr>
);

const TimeBasedStatsTable = ({ time_stats }) => (
  <table id="TimeStatsTable">
    <thead>
      <tr>
        <th>Username</th>
        <th>First Seen</th>
        <th>Last Seen</th>
        <th>Work Day Minutes</th>
        <th>Work Day Percentage</th>
        <th>Logged Minutes</th>
        <th>Logged Percentage</th>
        <th>Times Logged</th>
        <th>Issues Worked On</th>
      </tr>
    </thead>
    <tbody>
      {time_stats?.map((time_stats) => (
        <TimeBasedStatsRow
          time_stats={time_stats}
          key={time_stats.qa_timebase_user_stats.username}
        />
      ))}
    </tbody>
  </table>
);

export default TimeBasedStatsTable;
