import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import parseLocationToken from '../../utils/parseLocationToken';
import styles from './TermsAndConditions.module.scss';

const NoRedirect = () => (
  <div className={styles.TermsAndConditions}>
    <h1>Terms and Conditions</h1>
    <div>
      <h3>Missing Parameter</h3>
      <p>
        Something went wrong. Please try clicking the link in your email again.
      </p>
      <p>
        If you continue to see this error, please contact Goodx Support on{' '}
        <a href="tel:+27128459888">012 845 9888</a>
      </p>
    </div>
  </div>
);

const TermsAndConditions = ({ location, history }) => {
  const { redirect } = parseLocationToken(location);
  const [accepted, setAccepted] = useState(false);
  const { hash } = location;

  useEffect(() => {
    if (hash) {
      document
        .getElementById(hash.substring(1))
        .scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  if (!redirect) {
    return <NoRedirect />;
  }

  return (
    <div className={styles.TermsAndConditions}>
      <div>
        <h1>GoodX Software Standard Licence &amp; Services Agreement</h1>

        <section id="recitals">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#recitals' }}
            >
              Recitals
            </Link>
          </h2>
          <ol>
            <li>
              <p>
                Whereas GoodX has developed application software and is hereby
                appointed by the Client to install, maintain and support the
                application software for the Client; and
              </p>
            </li>
            <li>
              <p>
                Whereas the Client wishes to obtain a Licence to use the
                application software and obtain services on the terms and
                conditions set out in this GoodX Software Standard Licence &amp;
                Services Agreement read together with the General Terms and
                Conditions, Policies and Standard Operating Procedures as
                amended from time to time on the official GoodX website;
              </p>
            </li>
          </ol>
        </section>

        <p>
          <strong>Now therefore</strong> GoodX and the Client hereby agree to
          the following:
        </p>

        <section id="1-general">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#1-general' }}
            >
              1. General Terms and Conditions, Policies and Standard Operating
              Procedures
            </Link>
          </h2>
          <ol>
            <li>
              <p>
                The Client agrees that the General Terms and Conditions,
                Policies and Standard Operating Procedures as amended from time
                to time on the official GoodX Software website are read into
                this Agreement as if specifically agreed upon.
              </p>
            </li>
            <li>
              <p>
                The Client agrees that the General Terms and Conditions,
                Policies and Standard Operating Procedures may be unilaterally
                amended by GoodX from time to time and the Client agrees to
                comply with same as specifically forming part of this agreement
                as if reproduced specifically in this contract.
              </p>
            </li>
            <li>
              <p>
                The Client undertakes to lodge a dispute within 7 days of any
                changes that are made to the General Terms and Conditions,
                Policies and Standard Operating Procedures if the Client is not
                satisfied with such changes. Once a dispute is lodged, the
                Parties must resolve the dispute within 7 days, failing which
                the Agreement will terminate at the end of the month following
                the month in which the dispute was lodged.
              </p>
            </li>
          </ol>
        </section>

        <section id="2-appointment">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#2-appointment' }}
            >
              2. Appointment
            </Link>
          </h2>
          <p>
            The Client hereby appoints GoodX, with effect from the Commencement
            Date to:
          </p>
          <ol>
            <li>
              <p>
                install, maintain and support the GoodX Software for the use of
                the Client
              </p>
            </li>
            <li>
              <p>
                to render the services marked in Annexure A to this agreement
              </p>
            </li>
            <li>
              <p>
                to render any further services to the client as per separate
                quotations accepted in writing after the commencement date
              </p>
            </li>
          </ol>
          <p>
            subject to the General Terms and Conditions, Policies and Standard
            Operating Procedures and GoodX accepts this appointment.
          </p>
        </section>

        <section id="3-commencement-and-duration">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#3-commencement-and-duration' }}
            >
              3. Commencement and duration
            </Link>
          </h2>
          <ol>
            <li>
              <p>
                This Agreement shall commence on the Commencement Date and shall
                continue in force unless terminated by either the Client or
                GoodX by giving the other party at least 1 (one) calendar
                month’s written notice of such termination.
              </p>
            </li>
            <li>
              <p>
                Should extra services be required after the commencement date,
                the Client will sign separate quotation documents for each extra
                service and the services will commence from date of the written
                acceptance unless terminated by either the Client or GoodX by
                giving the other party at least 1 (one) calendar month’s written
                notice of such termination.
              </p>
            </li>
          </ol>
        </section>

        <section id="4-grant-of-licence">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#4-grant-of-licence' }}
            >
              4. Grant of Licence
            </Link>
          </h2>
          <p>
            GoodX herewith grants a non-exclusive and non-transferable Licence
            to the Client on the terms and conditions set out in this Agreement
            read together with the General Terms and Conditions, Policies and
            Standard Operating Procedures. The Licence granted to the Client
            authorises the Client to use the GoodX Software until the Licence
            Agreement is terminated.
          </p>
        </section>

        <section id="5-services-rendered">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#5-services-rendered' }}
            >
              5. Services Rendered
            </Link>
          </h2>
          <p>
            The Client can contract with GoodX for services by acceptance of the
            service items in Annexure A to this agreement, or by accepting
            separate quotations after the commencement date, which services will
            all be governed by this Agreement read together with the General
            Terms and Conditions, Policies and Standard Operating Procedures as
            amended from time to time.
          </p>
        </section>

        <section id="6-fees-and-payment">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#6-fees-and-payment' }}
            >
              6. Fees and Payment
            </Link>
          </h2>
          <p>
            The installation and initial training costs (if applicable) per
            Annexure A are payable before installation of the GoodX Software.
          </p>

          <p>
            The monthly Licence Fees as per this Agreement are due and payable
            in advance before or on the first day of the month and will commence
            on the first day of the calendar month following the installation of
            the GoodX Software.
          </p>

          <p>
            The monthly service fees as per Annexure A of this Agreement,
            increased annually as per this Agreement or any other accepted
            quotation and annual increase after the commencement date are due
            and payable at the end of the month in which the services were
            rendered.
          </p>

          <p>
            The Client will be charged for all SMS messages submitted to GoodX
            for delivery. Pricing is for all SMSes sent to a mobile number
            inside the Republic of South Africa. The charges for the SMS Service
            may be changed on one month’s notice by GoodX to the Client.
          </p>

          <p>
            GoodX may annually increase the fees by an amount which is fair and
            reasonable in view of:
          </p>
          <ol>
            <li>
              <p>
                any enhancement of the services that GoodX renders to the
                Client; and/or
              </p>
            </li>
            <li>
              <p>the growth of the size or complexity of the Client; and/or</p>
            </li>
            <li>
              <p>the increase in the support needs of the Client;</p>
            </li>
          </ol>
          <p>
            provided that GoodX gives the Client 30 (thirty) days’ notice of its
            intention to do so. If the Client does not terminate this agreement
            within the aforesaid notice period of 30 (thirty) days, it shall be
            deemed to have accepted the increase.
          </p>

          <p>
            Should the proposed increase exceed the annual CPI or the annual
            tariff rate increase of Discovery Medical Aid, whichever is the
            highest, by more than 2%, the increase may only be implemented upon
            written acceptance of the increase by the Client after negotiations
            between the Parties.
          </p>

          <p>
            The Client shall pay GoodX within 7 (seven) days all amounts due and
            payable in terms of this agreement upon presentation of the monthly
            invoice(s) sent by email to the Client or on the date as specified
            on the debit order form as signed by the Client and accepted by
            GoodX.
          </p>

          <p>
            The Client shall sign a debit order form to enable GoodX to deduct
            all fees and expenses due and payable from the bank account that the
            Client indicated on the debit order form, which form may be amended
            from time to time by the Client or GoodX. The Client undertakes to
            pay all banking fees associated with the debit order deduction and
            all penalties should the debit order be reversed due to any reason
            whatsoever.
          </p>

          <p>
            The Client shall pay compound interest monthly on all overdue
            amounts due to GoodX at a rate of 2% per annum above the then
            current overdraft interest rate charged by GoodX’s bankers.
          </p>

          <p>
            The Client will not be entitled to withhold payment of any amount
            payable to GoodX to satisfy any claim of the Client arising from
            this or any other contract between the Parties, nor will the Client
            be entitled to set-off such an amount against the amount payable to
            GoodX in terms of this Agreement or any other contract.
          </p>

          <p>
            GoodX shall be entitled to cancel this agreement and terminate its
            services with immediate effect if the Client should fail to pay any
            fees timely or if the debit order given by or on behalf of the
            Client is not honoured, and the Client fails to rectify the breach
            within 7 (SEVEN) days after receiving notice of such breach.
          </p>
        </section>

        <section id="7-limitation-of-liability">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#7-limitation-of-liability' }}
            >
              7. Limitation of liability
            </Link>
          </h2>
          <p>
            The liability of GoodX for faulty execution of the GoodX Software or
            GoodX Switch, the Support Services rendered in terms of the
            Agreement or the use of the Cloud by the Client as well as all
            damages suffered by the Client, whether direct or indirect, as a
            result of the malfunctioning of such Software, Switch or the Cloud
            or caused by GoodX as a result of faulty execution of the Support
            Services, will be limited to GoodX rectifying the malfunction or any
            Software or Switch errors or the Cloud or Support services, within a
            reasonable time and free of charge.
          </p>

          <p>
            This liability is completely excluded if the Client attempts to
            correct or allows third parties to correct or attempt to correct the
            Software or Switch or such faulty execution of the Support Services
            or any damage arising therefrom without the prior written approval
            of GoodX.
          </p>

          <p>
            GoodX shall not be held liable for any fault in the computer
            hardware or other software nor does GoodX warrant the integrity of
            any third party data or electronic information, including but not
            limited to converted data.
          </p>

          <p>
            GoodX shall not be liable whether in contract, delict or otherwise,
            for any direct, indirect, special or consequential loss or damage or
            any loss of profit suffered or sustained by the Client as a result
            of or in connection with the use of or reliance on incorrect data
            provided by the Medical Scheme or Administrator or the Client’s
            omission to inform GoodX of any change in the Client’s details.
          </p>

          <p>
            Any other liability on the part of GoodX not specified herein, is
            specifically excluded. Without limiting the generality of the
            aforegoing, GoodX shall not be liable for any delay, failure,
            breakdown or damage caused by:
          </p>

          <p>
            software, programs and support services supplied by or obtained by
            the Client without the consent or knowledge of GoodX; or
          </p>

          <p>
            software or programs modified by the Client or any third party not
            authorised to do so in terms of the Agreement; or
          </p>

          <p>
            the actions or requirements of any telecommunications authority or a
            supplier of telecommunications services or software.
          </p>

          <p>
            In no event shall GoodX be liable to the Client for loss of profits
            or for incidental, special or consequential damages arising out of
            or in connection with the Software or Support Services rendered by
            GoodX in terms of the Agreement or the delivery, installation,
            servicing, performance or use of it in combination with other
            computer software.
          </p>

          <p>
            GoodX or its accredited Agent shall, in particular, not be liable
            for any consequential loss which the Client may suffer as a result
            of any fault in the software and its aggregate liability arising
            from breach of this agreement by GoodX will, in any event, not
            exceed the fees charged by GoodX for 1 (one) month.
          </p>

          <p>
            GoodX Software cannot be held liable for any damages arising from
            third party software and /or Licences.
          </p>
        </section>

        <section id="8-intellectual-property-rights">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#8-intellectual-property-rights' }}
            >
              8. Intellectual Property Rights
            </Link>
          </h2>
          <p>
            GoodX retains ownership of all the intellectual property rights in
            or relating to the software and switch, including the program code
            and source code, and any copy thereof. The software is not sold and
            the Client is only entitled to use it under Licence while paying the
            agreed fees.
          </p>
          <p>
            The Client acknowledges that any and all of the Intellectual
            Property Rights including trademarks, trade names, copyright and
            other rights used or embodied in or in connection with the Software
            are and will remain the sole property of GoodX.
          </p>
          <p>
            The Client shall not question or dispute the ownership of such
            rights at any time during the continuation in force of the Agreement
            or thereafter.
          </p>
          <p>
            GoodX warrants that, to the best of its knowledge, the Software does
            not infringe upon or violate any Intellectual Property Rights of any
            third party and GoodX indemnifies and holds the Client harmless for
            any loss or damage suffered by the Client as a result of GoodX’s
            breach of this warranty.
          </p>
          <p>
            All content on the Cloud and the online Learning Centre, and all
            content of any documents provided to the Client (in, for instance,
            newsletters) is the property of GoodX, unless stated otherwise.
          </p>
          <p>
            No user may copy, modify, publish, transmit, transfer or sell,
            reproduce, create derivative works from, distribute, display, or in
            any way exploit any of the content of the Cloud, online Learning
            Centre or other documents, in whole or in part, except with the
            express written agreement of GoodX.
          </p>
        </section>

        <section id="9-breach-and-termination">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#9-breach-and-termination' }}
            >
              9. Breach and termination
            </Link>
          </h2>
          <p>
            Should either of the Parties breach any stipulation contained in the
            Agreement, and that breach is not due to force majeure, then the
            suffering party may:
          </p>
          <p>
            terminate the Agreement; provided that the party to the Agreement
            who breached its obligations in terms thereof fails to remedy such
            breach within 7 (seven) calendar days after receiving a notice by
            email from the other party to the Agreement, on expiry of this
            period; provided further that it confirms this termination, by way
            of a notice by email to the other party, and claim all damages that
            it might have suffered as a result of that breach; or
          </p>
          <p>
            it may claim specific performance of the Agreement from the other
            party to the Agreement.
          </p>
          <p>
            If the Client wishes to continue with the Install but wishes to
            terminate an Entity, the Client will give notice of termination of
            the Entity before or on the 26th of the month, failing which the
            Entity will be billed for at the end of the month.
          </p>
          <p>
            Either of the Parties may terminate the Agreement at any stage by
            giving the other one (1) month written notice of its intention to do
            so.
          </p>
          <p>
            If the Client wishes to terminate the Agreement, but needs access to
            the system to view past history, the Client will have limited access
            for viewing purposes only and will be liable for 25% of the original
            Licence fee until final and full termination of the Agreement.
          </p>
          <p>
            On termination of this agreement, for whatever reason, the Client
            shall forthwith cease to use the software and return the GoodX
            Software and any copy thereof to GoodX, failing which, GoodX shall
            be entitled to have unhindered access at any reasonable time to the
            Client’s premises and computers in order to remove the GoodX
            Software.
          </p>
          <p>
            If the Client terminates the agreement but needs limited access to
            work off outstanding debt on the system, or needs viewing access to
            the data, the Client will pay a Licence fee of 25% of the original
            Licence fee plus any Cloud user fees for users having access to the
            GoodX Software on the Cloud.
          </p>
          <p>
            The Client is at any time entitled to do a data extraction from the
            system in excel or a similar open source format, which data can be
            extracted by drawing the reports in GoodX, eg the Debtor/Patient
            Report writer, Age Analysis, Debtor Drilldown report and Accounting
            and stock reports available in the software.
          </p>
        </section>

        <section id="10-interpretation">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#10-interpretation' }}
            >
              10. Interpretation
            </Link>
          </h2>
          <p>
            The clause headings in this Agreement have been inserted for
            convenience only and will not be taken into consideration in the
            interpretation of this Agreement. Any reference in this Agreement to
            the singular includes the plural and vice versa. Any reference in
            this Agreement to natural persons includes legal persons and
            references to any gender include references to the other genders and
            vice versa.
          </p>
        </section>

        <section id="11-validity">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#11-validity' }}
            >
              11. Validity
            </Link>
          </h2>
          <p>
            If any provision of this Agreement is found or held to be invalid or
            unenforceable, the validity and enforceability of all the other
            provisions of this Agreement will not be affected thereby.
          </p>
        </section>

        <section id="12-domicilium-citandi-et-executandi">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#12-domicilium-citandi-et-executandi' }}
            >
              12. Domicilium citandi et executandi
            </Link>
          </h2>
          <p>
            The Parties elect the addresses in this Agreement as their
            respective domicilium citandi et executandi. Either of the Parties
            may change its domicilium citandi et executandi to another address
            within the same country, by way of a notice to the other party to
            this Agreement, provided that such a notice is received by the
            addressee, at least 7 (seven) calendar days prior to such a change
            taking effect.
          </p>
        </section>

        <section id="13-entire-agreement-and-variations">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#13-entire-agreement-and-variations' }}
            >
              13. Entire agreement and variations
            </Link>
          </h2>
          <p>
            This Agreement read together with the General Terms, Conditions and
            Policies and the Standard Operating Procedures as amended from time
            to time constitutes the whole agreement between the Parties and
            supersedes all prior verbal or written agreements or understandings
            or representations by or between the Parties regarding the subject
            matter of this Agreement, and the Parties will not be entitled to
            rely, in any dispute regarding this Agreement, on any terms,
            conditions or representations not expressly contained in this
            Agreement and the General Terms, Conditions and Policies and the
            Standard Operating Procedures.
          </p>
          <p>
            No variation of or addition to this Agreement will be of any force
            or effect unless reduced to writing and signed by or on behalf of
            the Parties and any variation to the General Terms, Conditions and
            Policies and the Standard Operating Procedures as published on the
            website will be in full force as if specifically agreed upon at the
            signing of this Agreement.
          </p>
          <p>
            Neither party to this Agreement has given any warranty or made any
            representation to the other party, other than any warranty or
            representation which may be expressly set out in this agreement.
          </p>
        </section>

        <section id="14-relaxation">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#14-relaxation' }}
            >
              14. Relaxation
            </Link>
          </h2>
          <p>
            No indulgence, leniency or extension of a right, which either of the
            Parties may have in terms of this agreement, and which either party
            (“the grantor”) may grant or show to the other party, shall in any
            way prejudice the grantor, or preclude the grantor from exercising
            any of the rights that it has derived from this Agreement, or be
            construed as a waiver by the grantor of that right.
          </p>
        </section>

        <section id="15-waiver">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#15-waiver' }}
            >
              15. Waiver
            </Link>
          </h2>
          <p>
            No waiver on the part of either party to this Agreement of any
            rights arising from a breach of any provision of this Agreement will
            constitute a waiver of rights in respect of any subsequent breach of
            the same or any other provision.
          </p>
        </section>

        <section id="16-severability">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#16-severability' }}
            >
              16. Severability
            </Link>
          </h2>
          <p>
            In the event that any of the terms of this Agreement are found to be
            invalid, unlawful or unenforceable, such terms will be severable
            from the remaining terms, which will continue to be valid and
            enforceable.
          </p>
        </section>

        <section id="17-governing-law-and-jurisdiction">
          <h2 title="Permalink to this paragraph">
            <Link
              className={styles.Permalink}
              to={{ ...location, hash: '#17-governing-law-and-jurisdiction' }}
            >
              17. Governing law and jurisdiction
            </Link>
          </h2>
          <p>
            The validity and interpretation of this Agreement will be governed
            by the laws of the Republic of South Africa. For the purpose of
            resolving any dispute which may exist or occur between the parties
            hereto, the parties consent to the jurisdiction of the magistrate's
            court for the district of Pretoria. This clause shall be deemed to
            constitute the required written consent conferring jurisdiction upon
            the said court pursuant to section 45 of the Magistrates' Courts Act
            32 of 1944 or any amendment thereof provided that the Parties shall
            have the right at his sole option and discretion to institute
            proceedings in any other competent court in respect of any claim
            which, but for the aforegoing, would exceed the jurisdiction of the
            magistrate's court.
          </p>
        </section>
      </div>

      <div className={styles.AcceptButtons}>
        <div
          className={styles.AcceptCheckbox}
          title={accepted ? 'Accepted' : 'Not Accepted'}
        >
          <FontAwesomeIcon
            className={accepted ? styles.Accepted : ''}
            icon={accepted ? faCheckSquare : faSquare}
            onClick={() => setAccepted((prev) => !prev)}
            size="2x"
          />
          <span>I accept these Terms and Conditions</span>
        </div>
        <button
          className={accepted ? styles.Accepted : ''}
          onClick={() =>
            history.push({
              ...location,
              pathname: redirect,
              search: searchRemoveRedirect(location.search),
              state: { accepted },
            })
          }
          disabled={!accepted}
          title={accepted ? null : 'Terms and Conditions not accepted'}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const searchRemoveRedirect = (search) => {
  const searchQS = new URLSearchParams(search);
  searchQS.delete('redirect');
  return searchQS.toString();
};

export default withRouter(TermsAndConditions);
