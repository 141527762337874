import React from 'react';

import InputText from './Inputs/InputText';

const SmtpDetails = ({ fields, smtpDetails, dispatch }) => (
  <>
    <h3>SMTP Details</h3>
    <div className="Inputs">
      <InputText
        name="Outgoing (SMTP) Server"
        value={smtpDetails.dkim_host.value}
        errors={smtpDetails.dkim_host.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'dkim_host'],
            value: e.target.value,
          })
        }
      />
      <label>Enable TLS</label>
      <input
        style={{ backgroundColor: 'transparent', height: '20px', boxShadow: 'none', width: '5%'}}
        type="checkbox"
        errors={smtpDetails.dkim_tls.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'dkim_tls'],
            value: e.target.checked,
          })
        }
        checked={smtpDetails.dkim_tls.value}
      />
      <InputText
        name="SMTP Port"
        value={smtpDetails.dkim_port.value}
        errors={smtpDetails.dkim_port.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'dkim_port'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Username"
        value={smtpDetails.dkim_username.value}
        errors={smtpDetails.dkim_username.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'dkim_username'],
            value: e.target.value,
          })
        }
      />
       <InputText
        name="Password"
        value={smtpDetails.dkim_password.value}
        errors={smtpDetails.dkim_password.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'dkim_password'],
            value: e.target.value,
          })
        }
      />
    </div>
  </>
);

export default SmtpDetails;