import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { post } from '../../../api';
import styles from '../Login.module.scss';
import OTPInput from '../../../components/inputs/OTPInput';
import PasswordInputs from '../../../components/inputs/PasswordInput';

const Mailto = () => (
  <a href="mailto:help@goodx.co.za" target="_blank" rel="noopener noreferrer">
    help@goodx.co.za
  </a>
);

const InvalidOTP = (
  <React.Fragment>
    <div className={styles.ErrorMsg2}>
      <div className={styles.Icon}><i className="material-icons">warning</i></div>
      You have entered an invalid OTP. Please click the Send OTP button again and enter the new OTP.
    </div>
  </React.Fragment>
);

const SomethingWentWrong = (
  <React.Fragment>
    <div className={styles.ErrorMsg}>
      <div className={styles.Icon}><i className="material-icons">warning</i></div>
      Something went wrong. Please try again or log a ticket with <Mailto />.
    </div>
  </React.Fragment>
);

const OTPForm = ({ location, history }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOTP] = useState('');
    const [message, setMessage] = useState(null); 
    const [urlLocation, setUrlLocation] = useState('');

    const submit = async (event) => {
		event.preventDefault();

      const resp = await post('/user/setpw', { email, password, otp });

      if (resp.ok) {
        history.push({
          pathname: `${urlLocation}/success`,
          state: { 'page': history.location.pathname }
         });
      } else {
        if (resp.status === 403) {
          setMessage(InvalidOTP);
        } else {
          setMessage(SomethingWentWrong);
        }
      }

      return resp.ok;
    };

	useEffect(() => {
    if (email !== null) {
      setEmail(location.state.email);
      setUrlLocation(location.state.urlLocation);
    }
	 }, [location, email]);

    return (
      <div className={styles.OTPFrm}>
        <div className={styles.StepWrapper}>
					<div className={styles.StepNumber}>2</div>

					{ urlLocation === '/register' ?
						<div className={styles.StepText}>Insert the OTP sent to your email address and set your account password.</div>
					:
						<div className={styles.StepText}>Insert the OTP sent to your email address and reset your account password.</div>
					}
				</div>

				<OTPInput
					className={styles.OTPInput}
					state={[otp, setOTP]}
					setMessage={setMessage}
				/>


				{/* Add reroute to previous page */}
				<button
					className={styles.ResendOTPBtn} >
					I didn&apos;t get the OTP - resend OTP
				</button>

				<PasswordInputs
					className={styles.EmailInput}
					state={[password, setPassword]}
					setMessage={setMessage}
				/>


        { urlLocation === '/register' ?
  				<button
  					className={styles.FinishRegisterBtn}
  					onClick={submit}
  					disabled={!password || !otp} >
  					Finish Registration
  				</button>
        :
          <button
  					className={styles.FinishRegisterBtn}
  					onClick={submit}
  					disabled={!password || !otp} >
  					Finish Password Reset
  				</button>
        }

				<Link className={styles.BackLoginBtn} to="/">
					Back to Login
				</Link>

				<div className={styles.Messages}>
					{message}
				</div>

      </div>
    );
  };

  export default withRouter(OTPForm);
