import React from 'react';

import Validator from '../Validator';

const InputSelect = ({ name, value, errors, onChange, options, withEmpty }) => (
  <>
    <label>{name}</label>
    <select
      className={errors ? 'Error' : null}
      value={value}
      onChange={onChange}
    >
      {withEmpty ? <option></option> : null}
      {options.map((v) => (
        <option key={v.id} value={v.id}>
          {v.name}
        </option>
      ))}
    </select>
    <Validator errors={errors} />
  </>
);

export default InputSelect;
