import {
  faExclamationTriangle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './Tab.module.scss';

const Tab = ({ name, active, error, onChange, canDelete, onDelete }) => (
  <div
    className={[styles.Tab, active ? styles.Active : undefined].join(' ')}
    onClick={(e) => onChange(e)}
  >
    <span>{name}</span>
    {error ? (
      <FontAwesomeIcon
        className={styles.ErrorIcon}
        icon={faExclamationTriangle}
        title="Tab contains errors"
      />
    ) : null}
    {canDelete ? (
      <div
        className={styles.Icon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onDelete(e);
        }}
        title="Remove"
      >
        <FontAwesomeIcon className={styles.DeleteIcon} icon={faTrash} />
      </div>
    ) : null}
  </div>
);

export default Tab;
