import React from 'react';

import Validator from './Validator';

const OpenHours = ({ fields, day, dispatch }) => (
  <div>
    <input
      style={{ backgroundColor: 'transparent', width: '30%', padding: 'inherit', boxShadow: 'none'}}
      type="time"
      onChange={(e) =>
        dispatch({
          fields: [...fields, 'startTime'],
          value: e.target.value,
        })
      }
      value={day.startTime.value}
    />
    <label> to </label>
    <input
      style={{ backgroundColor: 'transparent', width: '30%', padding: 'inherit', boxShadow: 'none'}}
      type="time"
      onChange={(e) =>
        dispatch({
          fields: [...fields, 'endTime'],
          value: e.target.value,
        })
      }
      value={day.endTime.value}
    />
  </div>
);

const DayInputs = ({ fields, day, dispatch }) => (
  <div className="Inputs">
    <label>Hours</label>
    {/* <OpenHours {...{ fields, day, dispatch }} /> */}
    <div>
    <input
      style={{ backgroundColor: 'transparent', width: '30%', padding: 'inherit', boxShadow: 'none'}}
      type="time"
      onChange={(e) =>
        dispatch({
          fields: [...fields, 'startTime'],
          value: e.target.value,
        })
      }
      value={day.startTime.value}
    />
    <label style={{paddingRight:"5px", paddingLeft:"5px"}}> to </label>
    <input
      style={{ backgroundColor: 'transparent', width: '30%', padding: 'inherit', boxShadow: 'none' }}
      type="time"
      onChange={(e) =>
        dispatch({
          fields: [...fields, 'endTime'],
          value: e.target.value,
        })
      }
      value={day.endTime.value}
    />
    <span style={{ float:"right" }}>
      <label style={{ paddingRight:"5px" }}>Length</label>
      <input
        style={{ backgroundColor: 'transparent', width:'30%', padding: 'inherit', boxShadow: 'none' }}
        type="number"
        min="0"
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'interval'],
            value: e.target.value,
          })
        }
        onBlur={(e) =>
          dispatch({
            fields: [...fields, 'interval'],
            value: parseInt(e.target.value),
          })
        }
        value={day.interval.value}
      />
      </span>
    </div>
    <Validator errors={day.interval.errors} />
  </div>
);

const DiaryDay = ({ name, fields, day, dispatch }) => (
  <div className="Section">
    <h3 style={{ margin: '0' }}>
      <input
        style={{ backgroundColor: 'transparent', height: '20px', boxShadow: 'none', width: '5%'}}
        type="checkbox"
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'enabled'],
            value: e.target.checked,
          })
        }
        checked={day.enabled.value}
      />
      {name} ({day.enabled.value ? 'open' : 'closed'})
    </h3>
    {day.enabled.value ? <DayInputs {...{ fields, day, dispatch }} /> : null}
  </div>
);

const Diary = ({ fields, diary, dispatch }) => (
  <>
    <h3>Diary</h3>
    <div style={{ paddingLeft: '1em' }}>
      <DiaryDay
        name="Sunday"
        fields={[...fields, 'sunday']}
        day={diary.sunday}
        dispatch={dispatch}
      />
      <DiaryDay
        name="Monday"
        fields={[...fields, 'monday']}
        day={diary.monday}
        dispatch={dispatch}
      />
      <DiaryDay
        name="Tuesday"
        fields={[...fields, 'tuesday']}
        day={diary.tuesday}
        dispatch={dispatch}
      />
      <DiaryDay
        name="Wednesday"
        fields={[...fields, 'wednesday']}
        day={diary.wednesday}
        dispatch={dispatch}
      />
      <DiaryDay
        name="Thursday"
        fields={[...fields, 'thursday']}
        day={diary.thursday}
        dispatch={dispatch}
      />
      <DiaryDay
        name="Friday"
        fields={[...fields, 'friday']}
        day={diary.friday}
        dispatch={dispatch}
      />
      <DiaryDay
        name="Saturday"
        fields={[...fields, 'saturday']}
        day={diary.saturday}
        dispatch={dispatch}
      />
    </div>
  </>
);

export default Diary;
