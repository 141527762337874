const requestOptions = (method, json, authToken) => ({
  method,
  headers: {
    'Content-Type': json === undefined ? undefined : 'application/json',
    Authorization: authToken === undefined ? undefined : authToken,
  },
  body: json === undefined ? undefined : JSON.stringify(json),
  credentials: 'include',
});

console.log(process.env.REACT_APP_BASE_URL)

export const apiURL = (url) => new URL(url, process.env.REACT_APP_BASE_URL);

const checkFetch = async (url, options) => await fetch(apiURL(url), options);

export const get = async (url, authToken) =>
  await checkFetch(url, requestOptions('GET', undefined, authToken));
export const post = async (url, json, authToken) =>
  await checkFetch(url, requestOptions('POST', json, authToken));
export const upload = async (url, file, authToken) =>
  await checkFetch(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
      credentials: 'include',
    },
    authToken,
  );

export const fetchSupportApi = async (url, setFn) => {
  const resp = await get(url);
  if (resp.ok) {
    setFn(await resp.json());
  }
  return resp.ok;
};

export const fetchData = async (url, setFn) => {
  const resp = await get(url);
  if (resp.ok) {
    setFn(await resp.json());
  }
  return resp.ok;
};
