import { useEffect, useState } from 'react';

const Timer = ({ milliseconds, callback }) => {
  const [timeLeft, setTimeLeftState] = useState(milliseconds);

  useEffect(() => {
    const deadline = new Date().getTime() + milliseconds;

    const setTimeLeft = () => {
      const left = deadline - new Date().getTime();
      if (left > 0) {
        setTimeLeftState(left);
        requestAnimationFrame(setTimeLeft);
      } else {
        callback();
      }
    };

    requestAnimationFrame(setTimeLeft);
  }, [milliseconds, callback]);

  return (timeLeft / 1000).toFixed(1);
};

export default Timer;
