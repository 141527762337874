import React from 'react';
import { Bar } from 'react-chartjs-2';

const GraphData = (data) => ({
  labels: data[0].data_labels,
  datasets: [
    {
      type: 'line',
      label: 'Average Minutes spent per day',
      data: data[0].avg_mins_spent_per_day,
      fill: false,
      backgroundColor: 'rgb(50, 168, 82)',
      borderColor: 'rgba(67, 11, 219)',
      yAxisID: 'y-axis-1',
    },
    {
      type: 'bar',
      label: 'Devops Minutes',
      data: data[0].devops_minutes,
      fill: false,
      backgroundColor: 'rgba(61, 190, 255)',
      borderColor: 'rgba(33, 88, 217)',
      yAxisID: 'y-axis-1',
    },
    {
      type: 'bar',
      label: 'FCL Minutes',
      data: data[0].fcl_minutes,
      fill: false,
      backgroundColor: 'rgb(30, 152, 179)',
      borderColor: 'rgba(30, 152, 179)',
      yAxisID: 'y-axis-1',
    },
    {
      type: 'bar',
      label: 'Platinum Minutes',
      data: data[0].platinum_minutes,
      fill: false,
      backgroundColor: 'rgb(168, 50, 115)',
      borderColor: 'rgba(67, 11, 219)',
      yAxisID: 'y-axis-1',
    },
  ],
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const TimeSpentPerDepartmentPerReleaseDesktop = (data) => (
  <>
    <div className="header">
      <h1 className="title">Time Per Department</h1>
    </div>
    {data.data[0] ? (
      <Bar
        data={GraphData(
          data.data.map(
            (data) => data.get_qa_time_spent_per_department_per_release,
          ),
        )}
        options={options}
      />
    ) : (
      <div></div>
    )}
  </>
);

export default TimeSpentPerDepartmentPerReleaseDesktop;
