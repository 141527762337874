const parseLocationToken = (location) => {
  const search = new URLSearchParams(location.search);

  const redirect = search.get('redirect');
  const exp = parseInt(search.get('exp'));
  const token = search.get('token');
  const expired = !token || new Date(exp * 1000) < new Date();

  return { redirect, token, expired };
};

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export default parseLocationToken;
