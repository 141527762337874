import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { get } from '../../api';
import Timer from '../../components/timer/Timer';
import styles from './StarStop.module.scss';

const StarStop = ({ history, location, match }) => {
  const taskId = match.params.taskId;
  const isStar = location.pathname.substring(6, 10) === 'star';

  const [message, setMessage] = useState(null);

  const sendStarStop = async () => {
    const resp = await get(`/task/${isStar ? 'star' : 'stop'}/${taskId}`);
    if (resp.ok) {
      setMessage(
        <div>
          <span>{await resp.text()}</span>
          <br />
          <span>Going back in </span>
          <Timer callback={() => history.goBack()} milliseconds={1000} />
          <span> seconds</span>
        </div>,
      );
    } else {
      history.push({ pathname: '/login' });
    }
  };

  return (
    <div className={styles.StarStop}>
      <button onClick={sendStarStop}>{isStar ? 'Star' : 'Stop'}</button>
      {message}
    </div>
  );
};

export default withRouter(StarStop);
