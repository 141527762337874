export const requiredValidator = (value) =>
  value === '' || value === null ? 'Value is required' : null;

const phoneNumberRegex = new RegExp(/^[\\+0]\d+$/);
export const phoneNumberValidator = (value) =>
  value
    ? value.match(phoneNumberRegex)
      ? null
      : 'Invalid Phone Number'
    : null;

const emailRegex = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
);
export const emailValidator = (value) =>
  value ? (value.match(emailRegex) ? null : 'Invalid Email Address') : null;

export const numberValidator = (value) =>
  value ? (isNaN(value) ? 'Not a Number' : null) : null;

export const fileLT2MBValidator = (value) =>
  value
    ? value.length > 2 * 1024 * 1024 * 1.3
      ? 'File Larger than 2MB'
      : null
    : null;

export const trimValidator = (value) =>
  value ? (value.trim() !== value ? 'Whitespace at start or end' : null) : null;

export const practiceNumberValidator = (value) =>
  value
    ? value.length !== 7 && value.length !== 13
      ? 'Not a valid Practice Number'
      : null
    : null;
