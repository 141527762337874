import './qualityAssurance.scss';

import { faAddressBook, faCompress } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const TotalTests = ({ per_category }) => (
  <div className="col-md-3">
    <div className="card-counter incoming_tickets">
      <i>
        <FontAwesomeIcon icon={faCompress} />
      </i>
      <span className="count-name detail">Tests Done</span>
      <span className="count-numbers detail">
        {per_category[0]
          ? per_category[0].get_qa_test_counts.no_of_tests +
            (per_category[1]
              ? per_category[1].get_qa_test_counts.no_of_tests
              : 0)
          : 0}
      </span>
    </div>
  </div>
);

const QuickInfoBar = ({ per_category }) => (
  <div>
    <TotalTests per_category={per_category} />
    {per_category ? (
      per_category?.map((per_category) => (
        <div>
          <div className="col-md-3">
            <div className="card-counter open_tickets">
              <i>
                <FontAwesomeIcon icon={faAddressBook} />
              </i>
              <span className="count-name detail">
                Tests {per_category.get_qa_test_counts.result}
              </span>
              <span className="count-numbers detail">
                {per_category.get_qa_test_counts.no_of_tests}
              </span>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div>
        <div>
          <div className="col-md-3">
            <div className="card-counter open_tickets">
              <i>
                <FontAwesomeIcon icon={faAddressBook} />
              </i>
              <span className="count-name detail">Tests Failed</span>
              <span className="count-numbers detail">0</span>
            </div>
          </div>
        </div>
        <div>
          <div className="col-md-3">
            <div className="card-counter open_tickets">
              <i>
                <FontAwesomeIcon icon={faAddressBook} />
              </i>
              <span className="count-name detail">Tests Passed</span>
              <span className="count-numbers detail">0</span>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default QuickInfoBar;
