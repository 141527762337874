import './qualityAssurance.scss';

import React from 'react';

const UserTest = ({ per_category }) => (
  <tr>
    <td>{per_category.get_qa_tests_per_tester.tester}</td>
    <td>{per_category.get_qa_tests_per_tester.no_of_tests}</td>
  </tr>
);

const TestPerUser = ({ per_category }) => (
  <table id="QaTestResultTable">
    <thead>
      <tr>
        <th>Tester Name</th>
        <th>No Of Tests</th>
      </tr>
    </thead>
    <tbody>
      {per_category?.map((per_category) => (
        <UserTest
          per_category={per_category}
          key={per_category.get_qa_tests_per_tester.tester}
        />
      ))}
    </tbody>
  </table>
);

export default TestPerUser;
