import { post, upload } from '../api';

const uploadAttachment = async (issueID, subject, file) => {
  const create = await post('/attachment', {
    file_name: file.name,
    res_model: 'project.issue',
    res_name: `${subject}...`,
    res_id: issueID,
  });

  if (create.ok) {
    const { attachment_id } = await create.json();
    const uploaded = await upload(`/attachment/${attachment_id}/upload`, file);
    return uploaded.ok ? attachment_id : undefined;
  }

  return undefined;
};

const uploadAttachments = async (issueID, subject, files) =>
  await Promise.all(
    files.map((file) => uploadAttachment(issueID, subject, file)),
  );

export default uploadAttachments;
