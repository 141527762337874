import React, { useState } from 'react';
import RichTextEditor from 'react-rte-17';

import FilesDisplay from '../file-upload/FilesDisplay/FilesDisplay';
import styles from './Message.module.scss';

import ReactQuill from 'react-quill';
import { modules, formats } from '../../components/utils/QuillEditorConfig';
import '../../components/utils/QuillEditor.css';

const isBlankMessage = (message) => message.toString('html') === '<p><br></p>';

const acceptsMimeTypes = [
  'audio/*',
  'image/*',
  'video/*',
  'text/*',
  'application/pdf',
  'application/x-freearc',
  'application/x-bzip',
  'application/x-bzip2',
  'application/gzip',
  'application/x-rar-compressed',
  'application/x-tar',
  'application/zip',
  'application/x-7z-compressed',
].join(',');

const Message = ({ submit, state }) => {
  const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
  const [files, setFiles] = useState([]);

  const submitNewMessage = async () => {
    const messageHTML = message.toString('html');

    if (messageHTML) {
      if (await submit(messageHTML, files)) {
        setMessage(RichTextEditor.createEmptyValue());
        setFiles([]);
      }
    }
  };

  const onRemoveFile = (index) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };

  return (
    <div className={styles.MessagesWrapper}>
      <ReactQuill theme="snow"
        modules={modules}
        formats={formats}
        value={message} onChange={setMessage}
        className={styles.Editor}
        placeholder="Type your message to the FCL (GoodX Consultant) here..."
      />

      <div className={styles.FilesUpload}>
        <label className={styles.FilesBtn}>
          Upload files...
          <input
            type="file"
            multiple
            accept={acceptsMimeTypes}
            onChange={(e) => {
              const { files } = e.target;

              setFiles((currentFiles) => {
                if (files) {
                  return [...currentFiles, ...Array.from(files)];
                }
                return currentFiles;
              });
            }}
          />
        </label>
      </div>

      <FilesDisplay files={files} onRemove={onRemoveFile} />

      <div className={styles.SubmitButton}>
        <button onClick={submitNewMessage} disabled={isBlankMessage(message) && !state}>
          Send message
        </button>
      </div>
    </div>
  );
};

export default Message;
