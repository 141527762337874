import './Issue.scss';

import {
  faAddressBook,
  faArrowRight,
  faBug,
  faCircle,
  faCompress,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { get } from '../../api';
import ActiveIssuesTable from './ActiveIssues.jsx';
import BugIssuesTable from './BugIssues.jsx';
import CategoryIssuesTable from './CategoryIssues.jsx';
import TimeStatsTable from './TimeStats.jsx';
import TodayInIssuesTable from './TodayInIssues.jsx';

const Issue = () => {
  const [reports, setReports] = useState([]);
  const [active_issues, setActiveIssues] = useState([]);
  const [stageissues, setStageIssues] = useState([]);
  const [userissues, setUserIssues] = useState([]);
  const [tagissues, setTagIssues] = useState([]);
  const [lastupdatedissues, setLastUpdatedIssues] = useState([]);
  const [issuesin, setIssuesIn] = useState([]);
  const [timestats, setTimeStats] = useState([]);
  const [bugIssues, setBugIssues] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchDevops = async (url, setFn) => {
    const resp = await get(url);
    if (resp.ok) {
      setFn(await resp.json());
    }
    return resp.ok;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDevops('/devops/issues', setReports);
      fetchDevops('/devops/getCurrentActiveIssues', setActiveIssues);
      fetchDevops('/devops/getIssuesPerStage', setStageIssues);
      fetchDevops('/devops/getIssuesPerUser', setUserIssues);
      fetchDevops('/devops/getIssuesPerTag', setTagIssues);
      fetchDevops('/devops/getIssuesPerLastUpdated', setLastUpdatedIssues);
      fetchDevops('/devops/getIssuesForToday', setIssuesIn);
      fetchDevops('/devops/getTimeStats', setTimeStats);
      fetchDevops('/devops/BugsIdentified', setBugIssues);
      fetchDevops('/devops/getCategories', setCategories);
    }, 60000);
    return () => clearInterval(interval);
  });

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="card-counter open_tickets">
          <i>
            <FontAwesomeIcon icon={faUsers} />
          </i>
          <span className="count-name detail">Ops</span>
          <span className="count-numbers detail">{reports.ops_tickets}</span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter code_review">
          <i>
            <FontAwesomeIcon icon={faBug} />
          </i>
          <span className="count-name detail">Bugs Identified</span>
          <span className="count-numbers detail">
            {reports.bugs_identified}
          </span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter dev_stats">
          <i>
            <FontAwesomeIcon icon={faAddressBook} />
          </i>
          <span className="count-name detail">Clients affected</span>
          <span className="count-numbers detail">{reports.clients_affected}</span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter incoming_tickets">
          <i>
            <FontAwesomeIcon icon={faCircle} />
          </i>
          <span className="count-name detail">Today In</span>
          <span className="count-numbers detail">
            {reports.today_incoming_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter outgoing_tickets">
          <i>
            <FontAwesomeIcon icon={faArrowRight} />
          </i>
          <span className="count-name detail">Today Out</span>
          <span className="count-numbers detail">
            {reports.today_outgoing_tickets}
          </span>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-counter info">
          <i>
            <FontAwesomeIcon icon={faCompress} />
          </i>
          <span className="count-name detail">Today Diff</span>
          <span className="count-numbers detail">{reports.today_day_diff}</span>
        </div>
      </div>
      <div className="col-md-5">
        <div className="detail2">Stage</div>
        <span className="count-name category_view">
          <CategoryIssuesTable per_category={stageissues} />
        </span>
      </div>
      <div className="col-md-5">
        <div className="detail2">User</div>
        <span className="count-numbers peruser">
          <CategoryIssuesTable per_category={userissues} />
        </span>
      </div>
      <div className="col-md-5">
        <div className="detail2">Last Updated</div>
        <span className="count-numbers lastupdated">
          <CategoryIssuesTable per_category={lastupdatedissues} />
        </span>
      </div>
      <div className="col-md-5">
        <div className="detail2">Tags</div>
        <span className="count-numbers tag">
          <CategoryIssuesTable per_category={tagissues} />
        </span>
      </div>
      <div className="col-md-5">
        <div className="detail2">Categories</div>
        <span className="count-numbers tag">
          <CategoryIssuesTable per_category={categories} />
        </span>
      </div>
      <div className="row">
        <div className="times_stats">
          <span className="count-numbers times_stats">
            <TimeStatsTable time_stats={timestats} />
          </span>
        </div>
        <div className="col-md-1">
          <span className="table-headers">Active Tickets</span>
          <ActiveIssuesTable active_issues={active_issues} />
        </div>
        <div className="col-md-1">
          <span className="table-headers">Bugs Identified</span>
          <BugIssuesTable bug_issues={bugIssues} />
        </div>
        <div className="col-md-1">
          <span className="table-headers">Tickets In not out today</span>
          <TodayInIssuesTable issues_today={issuesin} />
        </div>
      </div>
    </div>
  );
};

export default Issue;
