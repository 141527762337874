import React from 'react';

const NoToken = () => (
  <div>
    <h1>Quote</h1>
    <div>
      <h3>Not Authorized</h3>
      <p>
        Part of the URL is an access token. Please click the link in your email
        again.
      </p>
      <p>
        If you continue to see this error, please contact Goodx Support on{' '}
        <a href="tel:+27128459888">012 845 9888</a>
      </p>
    </div>
  </div>
);

export default NoToken;
