import './Issue.scss';

import React from 'react';
import { format as timeago } from 'timeago.js';

const dateDisplay = (date) => (date ? timeago(date) : 'Never');
const ActiveIssuesRow = ({ active_issues }) => (
  <tr
    style={
      active_issues.red_flag
        ? { background: '#DA3926' }
        : { background: '#5BE49C' }
    }
  >
    <td>
      <a
        href={`https://odoo.quantsolutions.co.za/issue/${active_issues.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {active_issues.issue}
      </a>
    </td>
    <td>{active_issues.name}</td>
    <td>{dateDisplay(active_issues.duration)}</td>
    <td>{dateDisplay(active_issues.last_update_date)}</td>
    <td>{active_issues.categories_delimited}</td>
  </tr>
);

const ActiveIssuesTable = ({ active_issues }) => (
  <table id="ActiveIssuesTable">
    <thead>
      <tr>
        <th>ID</th>
        <th>Assigned To</th>
        <th>Current Duration</th>
        <th>Last Updated</th>
        <th>Tags</th>
      </tr>
    </thead>
    <tbody>
      {active_issues.map((active_issues) => (
        <ActiveIssuesRow
          key={active_issues.uniq_id}
          active_issues={active_issues}
        />
      ))}
    </tbody>
  </table>
);

export default ActiveIssuesTable;
