import styles from './UpdateInformation.module.scss';

import React, { useContext, useEffect, useState } from 'react';

import { get, post, fetchData } from '../../api';
import { UserContext } from '../../context';

const fetchMessages = async ({ issueId, setMessages }) => {
    const resp = await get(`/issue/${issueId}/messages`);

    if (resp.ok) {
    setMessages(await resp.json());
    }

    return resp.ok;
};

const ChangedInformationInRequest = ({ match, history }) => {
    const { user } = useContext(UserContext);
    const requestId = match.params.id;
    const [isInformationOfficer, setIsInformationOfficer] = useState(false);
    const [infoRequests, setInfoRequests] = useState({})
    const [street, setStreet] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [fax, setFax] = useState('');
    const [information_officer_name, setInformation_officer_name] = useState('');
    const [information_officer, setInformation_officer] = useState('');
    const [information_officer_cell_no, setInformation_officer_cell_no] = useState('');
    const [marketing_email, setMarketing_email] = useState('');
    const [debtor_vatnr, setDebtor_vatnr] = useState('');
    const [name, setName] = useState('');
    const [website, setWebsite] = useState('');
    const [operationalEmail, setOperationalEmail] = useState('');
    const [financialEmail, setFinancialEmail] = useState('');
    const GoToHome = () => history.push({ pathname: `/` });

    useEffect(() => {
        if(typeof (infoRequests[0]) !== "undefined") {
          setName(infoRequests[0].name);
          setStreet(infoRequests[0].street);
          setStreet2(infoRequests[0].street2);
          setCity(infoRequests[0].city);
          setZip(infoRequests[0].zip);
          setEmail(infoRequests[0].email);
          setFax(infoRequests[0].fax);
          setWebsite(infoRequests[0].website);
          setFinancialEmail(infoRequests[0].financialemail);
          setOperationalEmail(infoRequests[0].operationalemail);
          setPhone(infoRequests[0].phone);
          setMobile(infoRequests[0].mobile);
          setInformation_officer_name(infoRequests[0].informationofficername);
          setInformation_officer(infoRequests[0].informationofficeremail);
          setInformation_officer_cell_no(infoRequests[0].informationofficercellno);
          setMarketing_email(infoRequests[0].marketingemail);
          setDebtor_vatnr(infoRequests[0].debtorvatnr);
        }
      }, [infoRequests]);


    useEffect(() => {
        const getInfoRequests = async () => {
            const auth = await post(`/get_change_request`, requestId);
      
            if (auth.ok) {
                setInfoRequests(await auth.json())
            }
          };
          getInfoRequests();
        
        const fetchInformationOfficer = async () => {
          const auth = await post(`/check_is_information_officer`, user);
    
          if (auth.ok) {
            setIsInformationOfficer(await auth.json())
          }
        };
        
    
        fetchInformationOfficer();
      }, []);

    return (
    <>
        <div className={styles.PageWrapperChanges}>
            <div className={styles.HeadingCard}>
                <span>Name: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{name}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Street: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{street}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Street 2: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{street2}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>City: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{city}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Zip Code: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{zip}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Phone Number: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{phone}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Email Address: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{email}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Mobile Number: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{mobile}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Fax Number: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{fax}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Information Officer Name: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{information_officer_name}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Information Officer Email Address: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{information_officer}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Information Officer Cell Number: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{information_officer_cell_no}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Marketing Email Address: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{marketing_email}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Vat Number: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{debtor_vatnr}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Website: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{website}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Operational Email Address: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{operationalEmail}</p>
                </div>
            </div>
            <div className={styles.HeadingCard}>
                <span>Financial Email Address: </span>
            </div>
            <div className="OverviewPanel">
                <div className="OverviewFirstRow">
                    <p>{financialEmail}</p>
                </div>
            </div>

        </div>
    </>
    );
};


export default ChangedInformationInRequest;
