import React, { useEffect, useState } from 'react';

import { get } from '../../api';
import Timer from '../../components/timer/Timer';
import styles from './ReopenIssue.module.scss';

const ReopenIssue = ({ history, match }) => {
  const id = match.params.id;
  const apikey = match.params.key;

  const [message, setMessage] = useState({ error: false, text: null });

  useEffect(() => {
      const goHome = () => history.push({ pathname: '/' });
      const openIssue = async () => {
        const resp = await get(`/issue/reopen/${id}/${apikey}`);

        if (resp.ok) {
          setMessage({
            error: false,
            text: (
              <span>
                {' '}
                Issue reopened. You will be redirected to the home page in{' '}
                <Timer callback={goHome} milliseconds={1000} />
              </span>
            ),
          });
        } else {
          if (resp.status === 304) {
            setMessage({
              error: true,
              text: (
                <span>
                  Your issue has already been reopened.
                  <p>
                    <font color="black">
                      To go back to the login {' '}
                      <a href={'/'}>
                        click here
                      </a>
                    </font>
                  </p>
                </span>
              ),
            });
          } else {
            setMessage({
              error: true,
              text: 'Unable to reopen your issue due to server error.',
            });
          }
        }
        return resp.ok;
      };
      openIssue();
    },
    [id, history, apikey],
  );

  return (
    <div className={styles.ReopenIssue}>
      <div className={styles.Card}>
        <span className={styles.Header}>Request to reopen issue #{id}</span>
        <span className={message.error ? styles.Error : null}>
          {message.text}
        </span>
      </div>
    </div>
  );
};

export default ReopenIssue;
