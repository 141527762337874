import './qualityAssurance.scss';

import React from 'react';

const ActiveTask = ({ per_user }) => (
  <tr>
    <td>
      <a
        target="blank"
        href={`https://odoo.quantsolutions.co.za/web?#id=${per_user.get_qa_active_tasks[0]?.task_id}&view_type=form&model=project.task&action=191`}
      >
        {per_user.get_qa_active_tasks[0].task_id}
      </a>
    </td>
    <td>{per_user.get_qa_active_tasks[0].user_name}</td>
    <td>{per_user.get_qa_active_tasks[0].sprint_name}</td>
    <td>{per_user.get_qa_active_tasks[0].task_duration}</td>
  </tr>
);

const ActiveTaskPerUser = ({ per_user }) => (
  <table id="QaTestResultTable">
    <thead>
      <tr>
        <th>Task Id</th>
        <th>Tester Name</th>
        <th>Spirnt Name</th>
        <th>Duration</th>
      </tr>
    </thead>
    <tbody>
      {per_user.get_qa_active_tasks?.map((per_user) => (
        <ActiveTask
          key={per_user.get_qa_active_tasks[0].task_id}
          per_user={per_user}
        />
      ))}
    </tbody>
  </table>
);

export default ActiveTaskPerUser;
