import './Issue.scss';

import React, { useEffect, useState, useContext } from 'react';

import { get } from '../../api';
import { UserContext } from '../../context';

import { priorityAltText, priorityLevel, dateDisplay } from '../home/components/IssueList';
import Tooltip from '@material-ui/core/Tooltip';
import PrevEscalations from './PrevEscalations';
import EscalatePopup from './EscalatePopup';


const IssueSummary = ({ issueId, history }) => {

  const [issue, setIssue] = useState(null);
  const { user } = useContext(UserContext);
  const [previousEscalations, setPreviousEscalations] = useState({});
  
  const clickHandler = () =>
    history.push({ pathname: `/issue/${issueId}` });

  useEffect(() => {
    const fetchIssue = async () => {
      const resp = await get(`/issue/${issueId}`);

      if (resp.ok) {
        setIssue(await resp.json());
      }

      return resp.ok;
    };

    fetchIssue();
  }, [issueId]);

  useEffect(() => {
    const fetchPreviousEscalations = async () => {
      const resp = await get(`/get_previous_escalations/${user.partner_id}`);

      if (resp.ok) {
        setPreviousEscalations(await resp.json());
      }

      return resp.ok;
    };

    fetchPreviousEscalations();
  }, [user.partner_id]);

  return issue === null ? (
    <h1>Unauthorized</h1>
  ) : (
  <>

      <div className="OverviewPanel">
        <div className="OverviewFirstRow">
          <p>[{issue.id}]</p>
          <div className="Priority">{priorityAltText(issue.priority)}&nbsp;&nbsp;
            <div className="PriorityBack">
              <div className={["PriorityFront", priorityLevel(issue.priority).split("_")[1]].join(' ')}>&nbsp;</div>
            </div>
          </div>
        </div>

        <h1>{issue.name}</h1>

        <div className="OverviewRow">
          <button className="ViewTicketBtn" onClick={clickHandler}>View Ticket</button>
        </div>


        <div className="OverviewRow">
          <i className="material-icons">outlined_flag</i>
          <span className="field">Status:</span>
          <span className="value">{issue.project_type}</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">folder_open</i>
          <span className="field">Project:</span>
          <span className="value">
            {issue.project_description ?
              <Tooltip title={issue.project_description}>
                <span>{issue.project_name}</span>
              </Tooltip>
              : <span>{issue.project_name}</span>
            }
          </span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">history</i>
          <span className="field">Last action:</span>
          <span className="value">{dateDisplay(issue.write_date + ' GMT')} (
          {new Date(issue.write_date + ' GMT').toLocaleString('en-GB')})</span>
        </div>

        {issue.sprint ?
        <div className="OverviewRow">
          <i className="material-icons">schedule_send</i>
          <span className="field">Expected release date:</span>
          <span className="value">{issue.sprint}</span>
        </div>
        : null
        }

        <div className="OverviewRow">
          <i className="material-icons">support_agent</i>
          <span className="field">FCL:</span>
          <span className="value">{issue.fcl_ref}</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">assignment_ind</i>
          <span className="field">Assigned to:</span>
          <span className="value">{issue.assigned_to}</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">event</i>
          <span className="field">Created date:</span>
          <span className="value">{dateDisplay(issue.create_date + ' GMT')} (
          {new Date(issue.create_date + ' GMT').toLocaleString('en-GB')})</span>
        </div>

        <div className="OverviewRow">
          <i className="material-icons">edit</i>
          <span className="field">Created by:</span>
          <span className="value">{issue.logger_name}</span>
        </div>
        
        <br />
        <PrevEscalations escalateObj={previousEscalations} />
        <EscalatePopup issue={issue} currentId={issueId} />
      </div>
    </>
  );
};

export default IssueSummary;
