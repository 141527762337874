import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { get } from '../../api';
import RepoWeeks from './RepoWeeks';

const RepoOverview = ({ history }) => {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const fetchWeeks = async () => {
      const resp = await get('/weeks');

      if (resp.ok) {
        setWeeks(await resp.json());
      }
    };

    fetchWeeks();
  }, [setWeeks]);

  const clickHandler = (week) =>
    history.push({
      pathname: `/week-overview/${week.week_grouping}`,
      state: { week_data: week },
    });

  return (
    <React.Fragment>
      <legend style={{display:'inline-flex', paddingRight:'3px', borderBlock:'1px solid black', justifyContent:'center'}}>
        Color Index:
        <li style={{ color: 'green', paddingRight:'3px' }}>
          {'>=50% Hours remaining to allocate'}
        </li>
        <li style={{ color: 'orange', paddingRight:'3px'  }} >
          {'>20% AND <50% Hours remaining to allocate'}
        </li>
        <li style={{ color: 'red', paddingBottom:'3px'  }}>
          {'<=20% Hours remaining to allocate'}
        </li>
        <li style={{ color: 'purple' }} >
          {'ELSE'}
        </li>
      </legend>
      <RepoWeeks weeks={weeks} onClick={clickHandler}></RepoWeeks>
    </React.Fragment>
  );
};

export default withRouter(RepoOverview);
