import React, { useEffect, useState } from 'react';

import { get } from '../../../api';
import InputSelect from './Inputs/InputSelect';

const fetchSpecialities = async (setSpecialities) => {
  const resp = await get('/install_setup/speciality');
  if (resp.ok) {
    setSpecialities(await resp.json());
  } else {
    console.error(await resp.text());
  }
};

const Speciality = ({ name, value, errors, onChange }) => {
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    fetchSpecialities(setSpecialities);
  }, [setSpecialities]);

  return (
    <InputSelect
      {...{ name, value, errors, onChange }}
      options={specialities}
      withEmpty
    />
  );
};

export default Speciality;
