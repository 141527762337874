import React from 'react';

import Validator from '../Validator';

const InputAutocomplete = ({ name, value, errors, onChange, list }) => (
  <>
    <label>{name}</label>
    <input
      className={errors ? 'Error' : null}
      type="text"
      {...{ onChange, value, list }}
    />
    <Validator errors={errors} />
  </>
);

export default InputAutocomplete;
