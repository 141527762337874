import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { UserContext } from '../../../context';

import { post } from '../../../api';
import Question from '../../../components/question/Question';
import Stars from '../../../components/stars/Stars';
import Timer from '../../../components/timer/Timer';
import parseLocationToken from '../../../utils/parseLocationToken';
import styles from './TicketFeedback.module.scss';

const Submit = ({ data: { expired, resolved, satisfied, rating, klaar } }) => {
  const submitDisabled =
    expired || resolved === null || satisfied === null || rating === 0 || klaar ===1;
  const submitTitle = [
    expired ? 'Auth token is expired' : null,
    resolved === null ? 'Resolved not completed' : null,
    satisfied === null ? 'Satisfied not completed' : null,
    rating === 0 ? 'Rating not completed' : null,
    klaar === 1 ? 'Already Clicked' : null,
  ]
    .filter((v) => v !== null)
    .join('\n');

  return (
    <button
      disabled={submitDisabled}
      title={submitTitle}
      className={styles.Submit}
      type="submit"
    >
      Submit Feedback
    </button>
  );
};

const TicketFeedback = ({ location, history }) => {
  const [resolved, setResolved] = useState(null);
  const [satisfied, setSatisfied] = useState(null);
  const [rating, setRating] = useState(0);
  const [klaar, setKlaar] = useState(0);
  const [comment, setComment] = useState('');
  const [message, setMessage] = useState({ error: false, text: null });
  const [surveyData, setSurveyData] = useState({});

  const { user } = useContext(UserContext);
  const { token, expired } = parseLocationToken(location);

  if (expired && !message.error) {
    setMessage({ error: true, text: 'Survey response has expired.' });
  }

  const goHome = () => history.push({ pathname: '/' });

  useEffect(() => {
    const fetchSurveyData = async () => {
      const resp = await post('/get_survey_data', {'token': token});

      if (resp.ok) {
        setSurveyData(await resp.json());
      }
    };

    fetchSurveyData();
  }, [setSurveyData, token]);

  const resetForm = () => {
    setResolved(null);
    setSatisfied(null);
    setRating(0);
    setComment('');
  };

  const submit = async (event) => {
    event.preventDefault();
    setKlaar(1);

    const resp = await post('/survey', {
      token,
      response: {
        resolved,
        satisfied,
        rating,
        klaar,
        comment,
      },
      user,
    });

    if (resp.ok) {
      setMessage({
        error: false,
        text: (
          <div>
            <span>
              Feedback submitted. You will be sent to the home page in{' '}
              <Timer callback={goHome} milliseconds={1000} />
            </span>
          </div>
        ),
      });
      resetForm();
    } else {
      let response = await resp.text();
      if ((response) === 'Token has been revoked') {
        setMessage({
          error: true,
          text: <span>Feedback has already been submitted.</span>,
        });
      } else {
        setMessage({
          error: true,
          text: 'Feedback was not submitted.' + response,
        });
      }
    }
    return false;
  };

  return (
    <div className={styles.TicketFeedback}>
      <div className={styles.Card}>
        <span className={styles.Header}>Ticket Feedback</span>
        {surveyData.completed ?
          <span className={styles.Error}>Survey already submitted.</span>
        :
        <>
        <div className={styles.alert}>
          <span class={styles.closebtn} />
          To re-open your issue, mark your issue as unresolved!
        </div>
        <span className={styles.Header}>{surveyData.title}</span>
        <span className={styles.SubHeader}>All fields are required</span>
        <form onSubmit={submit}>
          <div className={styles.Grid}>
            <label>Was your issue resolved?</label>
            <Question value={resolved} onChange={setResolved} />

            <label>Are you satisfied with the resolution?</label>
            <Question value={satisfied} onChange={setSatisfied} />

            <label>Please rate the level of service</label>
            <Stars value={rating} onChange={setRating} />

          </div>

          {!resolved && resolved !== null ?
          <>
            <span className={styles.ReopenHeading}>Your issue will been re-opened.</span>
            <br />
          </>
          : null
          }

          <label>Comments (optional)</label>
          <textarea
            maxLength="1000"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
          <span style={{ textAlign: 'right' }}>{comment.length || 0}/1000</span>
          <span className={styles.SubHeader}>Resolution</span>
          <span className={styles.Error}>{surveyData.resolution}</span>

          <div className={styles.Buttons}>
            <button className={styles.Home} onClick={goHome} title="Go Home">
              <FontAwesomeIcon icon={faHome} />
            </button>
            <Submit data={{ expired, resolved, satisfied, rating, klaar }} />
          </div>
        </form>
        <span className={message.error ? styles.Error : null}>
          {message.text}
        </span>
        </>
        }
      </div>
    </div>
  );
};

export default withRouter(TicketFeedback);
