import styles from './SupportStats.module.scss';

import {
    faSortAmountDown,
    faSortAmountDownAlt,
    faSortNumericDown,
    faSortNumericDownAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import DesktopLayout  from '../../components/utils/DesktopLayout';
import MobileLayout  from '../../components/utils/MobileLayout';
import { makeClass } from '../../components/utils';
import { UserContext } from '../../context';

import { post } from '../../api';


const SupportStats = () => {
    const { user } = useContext(UserContext);
    const [view, setView] = useState(7);
    const [perProductType, setPerProductType] = useState([{}]);
    const [perCategoryType, setPerCategoryType] = useState([{}]);
    const [subCategoryType, setSubCategoryType] = useState([{}]);
    const [perUser, setPerUser] = useState([{}]);

    useEffect(() => {
        const fetchData = async () => {
            const resp = await post(`/support_stats_per_product_type`, { view, user });

            if (resp.ok) {
                setPerProductType(await resp.json());
            }

            const resp1 = await post(`/support_stats_per_category_type`, { view, user });

            if (resp1.ok) {
                setPerCategoryType(await resp1.json());
            }

            const resp2 = await post(`/per_sub_category_type`, { view, user });

            if (resp2.ok) {
                setSubCategoryType(await resp2.json());
            }

            const resp3 = await post(`/support_stas_per_user`, { view, user });

            if (resp3.ok) {
                setPerUser(await resp3.json());
            }

        }
        fetchData();
    },[setPerProductType,setPerCategoryType,setSubCategoryType, setPerUser,user, view]);

    const InvoiceTableHeaders = ({
    name,
    displayName,
    sortIcons,
    sort,
    onClickSort,
  }) => (
    <div onClick={() => onClickSort(name)} className={styles.IssuesTableHeader}>
      {displayName}&nbsp;
      {sort.col === name ? (
        sort.dir === 'dec' ? (
          <FontAwesomeIcon icon={sortIcons.dec} />
        ) : sort.dir === 'asc' ? (
          <FontAwesomeIcon icon={sortIcons.asc} />
        ) : null
      ) : null}
    </div>
  );

  const sortPrepare = (value) => {
    if (typeof value === 'string' || value instanceof String) {
      return value.toLowerCase();
    }
  
    return value || '';
  };

  const sortAsc = (col) => (a, b) => {
    a = sortPrepare(a[col]);
    b = sortPrepare(b[col]);
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  };
  
  const sortDec = (col) => (a, b) => {
    a = sortPrepare(a[col]);
    b = sortPrepare(b[col]);
    if (a < b) return 1;
    if (b < a) return -1;
    return 0;
  };

  const [sort, setSort] = useState({ col: 'id', dir: 'dec' });
  const sortFn = sort.dir === 'dec' ? sortDec(sort.col) : sortAsc(sort.col);

  const setSortToggleDir = () =>
    setSort((s) => ({ col: s.col, dir: s.dir === 'dec' ? 'asc' : 'dec' }));

  const setSortColumn = (col) => setSort({ col, dir: 'dec' });

  const onClickSort = (sortColumn) => {
    if (sort.col === sortColumn) {
      setSortToggleDir();
    } else {
      setSortColumn(sortColumn);
    }
  };

  const TableLine = ({ line, onClick }) => (
    <div className={styles.RowWrapper}>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.name}</div>
      </div>

      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{line.count}</div>
      </div>
    </div>
  );

  return (
    <>
    <DesktopLayout>
        <div className={styles.OptionsToolbar}>
            <div className={styles.Heading}>
                <h1>Support stats</h1>
            </div>

            <div className={styles.IconButtons}>
            <div className={makeClass(styles.ViewButton, view === 7 ? 'active' : null)}
                title="7 Days"
                onClick={() => setView(7)}
                >
                <i className="material-icons">view_list</i> 7 Days
            </div>
            <div
                className={makeClass(styles.ViewButton, view === 30 ? 'active' : null)}
                title="30 Days"
                onClick={() => setView(30)}
                >
                <i className="material-icons">apps</i> 30 Days
            </div>
            </div>
        </div>

        <div className={styles.TableCards}>
            <div className={styles.TableWrapper}>
                <div className={styles.TableHeader}>Per User</div>
                <div className={styles.IssuesTable}>
                <InvoiceTableHeaders
                    name="accNo"
                    displayName="Category"
                    sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
                    sort={sort}
                    onClickSort={onClickSort} />
        
                <InvoiceTableHeaders
                    name="invoiceDate"
                    displayName="No. Of Issues"
                    sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
                    sort={sort}
                    onClickSort={onClickSort} />
                    {!perUser ?
                        <div className={styles.NoneSelected}>No outstanding balances.</div>
                    : null
                    }
                    {perUser ?
                        <>
                        {perUser.map((line) => (
                            <TableLine line={line} key={line.name}/>
                        ))}</>
                    : null
                    }
                </div>
            </div>

            <div className={styles.TableWrapper}>
                <div className={styles.TableHeader}>Per Product Type</div>
                <div className={styles.IssuesTable}>
                <InvoiceTableHeaders
                    name="accNo"
                    displayName="Category"
                    sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
                    sort={sort}
                    onClickSort={onClickSort} />
        
                <InvoiceTableHeaders
                    name="invoiceDate"
                    displayName="No. Of Issues"
                    sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
                    sort={sort}
                    onClickSort={onClickSort} />

                    {!perProductType ?
                        <div className={styles.NoneSelected}>No outstanding balances.</div>
                    : null
                    }
                    {perProductType ?
                        <>
                        {perProductType.map((line) => (
                            <TableLine line={line} key={line.name}/>
                        ))}</>
                    : null
                    }
                </div>
            </div>

            <div className={styles.TableWrapper}>
                <div className={styles.TableHeader}>Per Category Type</div>
                <div className={styles.IssuesTable}>
                <InvoiceTableHeaders
                    name="accNo"
                    displayName="Category"
                    sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
                    sort={sort}
                    onClickSort={onClickSort} />
        
                <InvoiceTableHeaders
                    name="invoiceDate"
                    displayName="No. Of Issues"
                    sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
                    sort={sort}
                    onClickSort={onClickSort} />
                    {!perCategoryType ?
                        <div className={styles.NoneSelected}>No outstanding balances.</div>
                    : null
                    }
                    {perCategoryType ?
                        <>
                        {perCategoryType.map((line) => (
                            <TableLine line={line} key={line.name}/>
                        ))}</>
                    : null
                    }
                </div>
            </div>


            <div className={styles.TableWrapper}>
                <div className={styles.TableHeader}>Per Sub-Category Type</div>
                <div className={styles.IssuesTable}>
                <InvoiceTableHeaders
                    name="accNo"
                    displayName="Category"
                    sortIcons={{ asc: faSortAmountDownAlt, dec: faSortAmountDown }}
                    sort={sort}
                    onClickSort={onClickSort} />
        
                <InvoiceTableHeaders
                    name="invoiceDate"
                    displayName="No. Of Issues"
                    sortIcons={{ asc: faSortNumericDown, dec: faSortNumericDownAlt }}
                    sort={sort}
                    onClickSort={onClickSort} />
        
                    {!subCategoryType ?
                        <div className={styles.NoneSelected}>No outstanding balances.</div>
                    : null
                    }
                    {subCategoryType ?
                        <>
                        {subCategoryType.map((line) => (
                            <TableLine line={line} key={line.name}/>
                        ))}</>
                    : null}
                </div>
            </div>
        </div>

    </DesktopLayout>
    <MobileLayout>
        <div className={styles.OptionsToolbar}>
                <div className={styles.Heading}>
                    <h1>Support stats</h1>
                </div>

                <div className={styles.IconButtons}>
                <div className={makeClass(styles.ViewButton, view === 7 ? 'active' : null)}
                    title="7 Days"
                    onClick={() => setView(7)}
                    >
                    <i className="material-icons">view_list</i> 7 Days
                </div>
                <div
                    className={makeClass(styles.ViewButton, view === 30 ? 'active' : null)}
                    title="30 Days"
                    onClick={() => setView(30)}
                    >
                    <i className="material-icons">apps</i> 30 Days
                </div>
                </div>
            </div>
    </MobileLayout>
    </>
  );
};

export default SupportStats;
