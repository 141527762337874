import './Issue.scss';

import React from 'react';

const ProjectPerStageRow = ({ projectPerStage }) => (
  <tr>
    <td>{projectPerStage.project_name}</td>
    <td>{projectPerStage.cus_stage}</td>
    <td>{projectPerStage.count}</td>
  </tr>
);

const ProjectPerStageTable = ({ projectPerStage }) => (
  <table id="ProjectPerStageTable">
    <thead>
      <tr>
        <th>Project Name</th>
        <th>Stage</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      {projectPerStage?.map((project_per_stages) => (
        <ProjectPerStageRow
          key={project_per_stages.uniq_id}
          projectPerStage={project_per_stages}
        />
      ))}
    </tbody>
  </table>
);

export default ProjectPerStageTable;
