import React from 'react';

import styles from './Question.module.scss';

const Question = ({ value, onChange }) => {
  const yesClass = value === true ?  styles.active : null;
  const noClass = value === false ? styles.active : null;

  return (
    <div className={styles.Answer}>
      <button className={yesClass} type="button" onClick={() => onChange(true)}>
        Yes
      </button>
      <button className={noClass} type="button" onClick={() => onChange(false)}>
        No
      </button>
    </div>
  );
};

export default Question;
