import React, { useEffect, useState, useCallback } from 'react';
import currentDate from '../../../components/utils/CurrentDateTime';

import { post } from '../../../api';
import ResponseResponse from './response/Response';
import styles from './SurveyList.module.scss';

import DesktopLayout  from '../../../components/utils/DesktopLayout';
import MobileLayout  from '../../../components/utils/MobileLayout';
import responsive from '../../../responsive.module.scss';

const ResponseRow = ({ response }) => (

  <>
  <DesktopLayout>
    <div
      className={styles.RowWrapper}
      onClick={() =>
        window.open(
          `https://odoo.quantsolutions.co.za/issue/${response.issue_id}`,
          '_blank',
        )}>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.created_at}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.respondant_email}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.issue_name}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>
          <ResponseResponse
            type={response.survey_name}
            response={response.response}
          />
        </div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.fcl_ref}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.done_user}</div>
      </div>
    </div>
  </DesktopLayout>

  <MobileLayout>
    <div
      className={responsive.IssueCard}
      onClick={() =>
        window.open(
          `https://odoo.quantsolutions.co.za/issue/${response.issue_id}`,
          '_blank',
        )}>

        <div className={responsive.CardCol}>
          <div className={responsive.CardRow}>
            <i className="material-icons">event</i>
            <span>{response.created_at}</span>
          </div>
          <div className={responsive.CardRow}>
            <i className="material-icons">person</i>
            <span>{response.respondant_email}</span>
          </div>
          <div className={responsive.CardRow}>
            <i className="material-icons">local_activity</i>
            <h1>{response.issue_name}</h1>
          </div>
          <div className={responsive.CardRow}>
            <i className="material-icons">support_agent</i>
            <span>{response.fcl_ref}</span>
          </div>
          {response.done_user ?
            <div className={responsive.CardRow}>
              <i className="material-icons">support</i>
              <span>{response.done_user}</span>
            </div>
            : null}
          <div className={responsive.CardRow}>
            <ResponseResponse
              type={response.survey_name}
              response={response.response}
            />
          </div>
        </div>


    </div>

    {/*
    <div>

      <div className={styles.tableRow}>
        <div className={styles.TableCell}></div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}></div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}></div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>

        </div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.fcl_ref}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.TableCell}>{response.done_user}</div>
      </div>
    </div>*/}
  </MobileLayout>
  </>
);

const ResponseHeader = () => (
  <DesktopLayout>
    <div className={styles.SurveyListHeader}>Created</div>
    <div className={styles.SurveyListHeader}>Respondent</div>
    <div className={styles.SurveyListHeader}>Issue Subject</div>
    <div className={styles.SurveyListHeader}>Rating</div>
    <div className={styles.SurveyListHeader}>FCL</div>
    <div className={styles.SurveyListHeader}>Assigned to</div>
  </DesktopLayout>

);

const ResponsesList = ({ responses }) => {
  if (responses.length === 0) {
    return <p>No Survey Responses to display.</p>;
  }
  const list = responses.map((response) => (
    <>
    <DesktopLayout>
      <ResponseRow key={response.id} response={response} />
    </DesktopLayout>
    <MobileLayout>
      <ResponseRow key={response.id} response={response} />
    </MobileLayout>
    </>
  ));

  return (
    <>
      <DesktopLayout>
        <div className={styles.SurveyListWrapper}>
          <div className={styles.SurveyList}>
            <ResponseHeader />
            {list}
          </div>
        </div>
      </DesktopLayout>
      <MobileLayout>
        <div className={responsive.CardsWrapper} >
          {list}
        </div>
      </MobileLayout>
    </>
  );
};

const Last2Weeks = () => {
  let today = new Date();
  today.setDate(today.getDate() - 14);
  let date = new Date(today).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });

  return date;
};

const SurveyList = () => {
  const [surveyList, setSurveyList] = useState([]);
  const [error, setError] = useState(null);

  const [fromDate, setFromDate] = useState(Last2Weeks());
  const [toDate, setToDate] = useState(currentDate());
  const [searchString, setSearchString] = useState('');

  const FilterSurveys = async (e) => {
    setSearchString(e.target.value);
    await fetchSurveyResponses();
  };

  const OptionsToolbar = () => {
    return (
      <>
      <DesktopLayout>
      <div className={styles.OptionsToolbar}>
        <div className={styles.Heading}>
          <h1>Survey Responses</h1>
        </div>

        <div className={styles.SearchFrom}>
          <input
            autoFocus={true}
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            placeholder="From..." />
        </div>

        <div className={styles.SearchTo}>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            placeholder="From..." />
        </div>

        <div className={styles.SearchWord}>
          <input
            type="text"
            autoFocus="autoFocus"
            value={searchString}
            onChange={FilterSurveys}
            placeholder="Search word..." />
            <i className="material-icons">search</i>
        </div>

        <div
          className={styles.DownloadBtn}
          title="Download all surveys" >
          <i className="material-icons">download</i>
        </div>

      </div>
      </DesktopLayout>

      <MobileLayout>
      <div className={responsive.OptionsToolbar}>
        <div className={responsive.Heading}>
          <h1>Survey Responses</h1>
          <div
            className={responsive.DownloadBtn}
            title="Download all surveys" >
            <i className="material-icons">download</i>
          </div>
        </div>

        <div className={responsive.SearchFrom}>
          <input
            autoFocus={true}
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            placeholder="From..." />
        </div>

        <div className={responsive.SearchTo}>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            placeholder="From..." />
        </div>

        <div className={responsive.SearchWord}>
          <input
            type="text"
            autoFocus="autoFocus"
            value={searchString}
            onChange={FilterSurveys}
            placeholder="Search word..." />
            <i className="material-icons">search</i>
        </div>

      </div>
      </MobileLayout>
      </>
    );
  };

  const fetchSurveyResponses = useCallback(async () => {
    const resp = await post('/surveyList', {
      fromDate,
      toDate,
      searchString,
    });

    if (resp.ok) {
      setSurveyList(await resp.json());
      setError(null);
    } else {
      setError(<div>Sorry, you are not authorised to view Survey Responses.</div>);
    }
  }, [fromDate, toDate, searchString]);

  useEffect(() => {
    fetchSurveyResponses();
  }, [setError, fetchSurveyResponses]);

  return (
    <React.Fragment>
      <OptionsToolbar />
      {error}
      <ResponsesList responses={surveyList} />
    </React.Fragment>
  );
};

export default SurveyList;
