import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { withRouter } from 'react-router';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow key={row.github_repo_id} className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.github_repo_name}</b>
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.allowed_hours}</b>
        </TableCell>
        <TableCell align="right"><b>{row.task_count}</b></TableCell>
        <TableCell style={{ color: row.html_line_color }} align="right">
          <b>{row.planned_hours.toFixed(2)}</b>
        </TableCell>
        <TableCell
          style={{
            color: row.effective_hours > row.planned_hours ? 'red' : 'green',
          }}
          align="right"
        >
          <b>{row.effective_hours.toFixed(2)}</b>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div" style={{borderTop:'3px solid black'}}>
                Tasks
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Complexity</TableCell>
                    <TableCell align="left">Sprint</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="center">Hours Planned</TableCell>
                    <TableCell align="center">Hours Completed</TableCell>
                    <TableCell align="center">Linked Issues</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.tasks.map((task) => (
                    <TableRow key={task.task_id}>
                      <TableCell align="left">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://odoo.quantsolutions.co.za/web?#id=${task.task_id}&view_type=form&model=project.task&menu_id=172&action=191`}
                        >
                          {task.task_id}
                        </a>
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 300,
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word',
                        }}
                        align="left"
                      >
                        {task.task_name ?? 'No name set'}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 300,
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word',
                          color:`${task.complexity_color}`,
                        }}
                        align="left"
                      >
                        {task.complexity}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 300,
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word',
                        }}
                        align="left"
                      >
                        {task.sprint_name}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 800,
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word',
                        }}
                        align="left"
                      >
                        {task.task_description ?? 'No description set'}
                      </TableCell>
                      <TableCell align="center">
                        {task.planned_hours?.toFixed(2) ?? 'No hours set'}
                      </TableCell>
                      <TableCell align="center">
                        {task.effective_hours?.toFixed(2) ?? 'No hours set'}
                      </TableCell>
                      <TableCell align="center">
                        {task.linked_issues?.length
                          ? task.linked_issues
                              .map((issue) => (
                                <a
                                  href={`https://odoo.quantsolutions.co.za/web?#id=${issue.issue_id}&view_type=form&model=project.issue&menu_id=184&action=204`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {issue.issue_id}
                                </a>
                              ))
                              .reduce((prev, curr) => [prev, ', ', curr])
                          : 'No linked issues'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DetailedWeekOverview = ({ location }) => {
  const weekData = location.state.week_data;
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6} align="center" style={{borderBottomColor:'white'}}>
                {weekData.week_text}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6} align="center">
                {weekData.week_dates}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell>Github Repo</TableCell>
              <TableCell>Allowed Hours</TableCell>
              <TableCell align="right">Tasks</TableCell>
              <TableCell align="right">Hours Planned</TableCell>
              <TableCell align="right">Hours Completed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weekData.repos.map((row) => (
              <Row
                key={`${row.github_repo_name}_${row.github_repo_id}`}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
export default withRouter(DetailedWeekOverview);
