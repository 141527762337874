import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from '../Login.module.scss';


const RegisteredSuccess = (
  <React.Fragment>
		<div className={styles.Icon}>
			<i className="material-icons">check_circle</i>
		</div>
		<div className={styles.CenterText}>
			<p>Thank you, you have <strong>successfully registered</strong> on the GoodX Client Portal.</p>
			<p>Please login with your registered details.</p>
		</div>
  </React.Fragment>
);

const ResetPwdSuccess = (
  <React.Fragment>
    <div className={styles.Icon}>
			<i className="material-icons">check_circle</i>
		</div>
    <div className={styles.CenterText}>
			<p>Thank you, you have <strong>successfully reset your password</strong> for your account on the GoodX Client Portal. </p>
			<p>Please login with your updated details.</p>
		</div>
  </React.Fragment>
);

const SuccessForm = ({ history }) => {
    const GoHome = () => history.push('/login');

    return (
      <form className={styles.SuccessFrm}>
        <div className={styles.StepText}>
          { history.location.pathname === '/register/success' ?
            RegisteredSuccess
          : 
            ResetPwdSuccess
          }
				</div>

        <button
          className={styles.LoginBtn}
          onClick={GoHome} >
          Login
        </button>

      </form>
    );
  };

export default withRouter(SuccessForm);
