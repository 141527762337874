import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { get, post } from '../../api';
import ActiveTaskPerUser from './AcitveTasks';
import BugsIdentified from './BugsIdentifiedPerVersion';
import BugsIdentifiedPerVersion from './BugsIdentifiedPerVersionGraph';
import BugsIdentifiedPerVersionOther from './BugsIdentifiedPerVersionOtherGraph';
import BugsIdentifiedPerVersionWeb from './BugsIdentifiedPerVersionWebGraph';
import NewBugsPerUserPerSprint from './NewBugsPerUserPerSprint';
import QuickInfoBar from './QuickInfoBar';
import TestingFailedPerVersion from './TestingFailedPerVersion';
import TestPerUser from './TestPerUser.jsx';
import TestResultPerUser from './TestResultsPerUser.jsx';
import TestsPassedFailed from './TestsPassedFailed.jsx';
import TestsPerUserGraph from './TestsPerUserGraph';
import TimeBasedStatsTable from './TimeBasedUserStats';
import TimeSpentPerDepartmentPerReleaseDesktop from './TimeSpentPerDepartmentPerReleaseDesktop';
import TotalTimeSpentPerDepartmentDesktop from './TotalTimeSpentPerDepartmentDesktop';
import UserTimeSpentPerSprint from './UserTimeSpentPerSprint';

const QualityAssurance = ({ history }) => {
  const [testResults, setQaTestResults] = useState([]);
  const [testUser, setQaTestPerUser] = useState([]);
  const [testResultsUser, setQaTestResultsPerUser] = useState([]);
  const [timeBasedUserStats, setQaTimeBasedUserStats] = useState([]);
  const [newBugPerUserPerSprint, setQaNewBugPerUserPerSprint] = useState([]);
  const [testsFailedPerSprint, setQaTestsFailedPerSprintVersion] = useState([]);
  const [userTimeSpentPerSprint, setQaUserTimeSpentPerSprint] = useState([]);
  const [testResultCount, setQaTestResultCount] = useState([]);
  const [testsDonePerUserGraph, setQaTestsDonePerUserGraph] = useState([]);
  const [activeTask, setQaActiveTaskPerUser] = useState([]);
  const [bugsIdentifiedGraph, setBugsIdentifiedGraph] = useState([]);
  const [bugsIdentified, setBugsIdentified] = useState([]);
  const [bugsIdentifiedweb, setBugsIdentifiedweb] = useState([]);
  const [bugsIdentifiedOther, setBugsIdentifiedOther] = useState([]);
  const [
    timeSpentBreakdownDesktop,
    setTimeSpentPerDepartmentDesktop,
  ] = useState([]);
  const [
    timeSpentBreakdownGoodxWeb,
    setTimeSpentPerDepartmentGoodxWeb,
  ] = useState([]);

  const fetchQa = async (url, setFn) => {
    const resp = await get(url);
    if (resp.ok) {
      setFn(await resp.json());
    }
    return resp.ok;
  };

  const postQA = async (url, majorversion, postFn) => {
    const resp = await post(url, { majorversion });
    if (resp.ok) {
      postFn(await resp.json());
    }
    return resp.ok;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQa('/qa_test_results', setQaTestResults);
      fetchQa('/qa_test_per_tester', setQaTestPerUser);
      fetchQa('/qa_test_results_per_user', setQaTestResultsPerUser);
      fetchQa('/qa_timebased_user_stats', setQaTimeBasedUserStats);
      fetchQa('/qa_new_bugs_per_user_per_sprint', setQaNewBugPerUserPerSprint);
      fetchQa(
        '/qa_testing_failed_per_version',
        setQaTestsFailedPerSprintVersion,
      );
      fetchQa('/qa_user_time_spent_per_sprint', setQaUserTimeSpentPerSprint);
      fetchQa('/qa_test_counts', setQaTestResultCount);
      fetchQa('/qa_test_per_user_graph', setQaTestsDonePerUserGraph);
      fetchQa('/qa_active_tasks', setQaActiveTaskPerUser);
      fetchQa('/qa_bugs_identified_per_version', setBugsIdentified);
      postQA(
        '/qa_bugs_identified_per_version_graph',
        'DESKTOP',
        setBugsIdentifiedGraph,
      );
      postQA(
        '/qa_bugs_identified_per_version_graph',
        'GOODXWEB',
        setBugsIdentifiedweb,
      );
      postQA(
        '/qa_bugs_identified_per_version_graph',
        'OTHER',
        setBugsIdentifiedOther,
      );
      postQA(
        '/qa_time_spent_per_department_per_release',
        'DESKTOP',
        setTimeSpentPerDepartmentDesktop,
      );
      postQA(
        '/qa_time_spent_per_department_per_release',
        'GOODXWEB',
        setTimeSpentPerDepartmentGoodxWeb,
      );
    }, 60000);
    return () => clearInterval(interval);
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="container">
            <div className="row">
              <QuickInfoBar per_category={testResultCount} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-4">
            <div className="detail2">New Bugs Per User Per Sprint</div>
            <span className="count-name category_view">
              <NewBugsPerUserPerSprint per_category={newBugPerUserPerSprint} />
            </span>
          </div>
          <div className="col-md-4">
            <div className="detail2">Tests Per User</div>
            <span className="count-numbers lastupdated">
              <TestPerUser per_category={testUser} />
            </span>
          </div>
          <div className="col-md-4">
            <div className="detail2">Tests Failed Per Sprint Version</div>
            <span className="count-numbers tag">
              <TestingFailedPerVersion per_category={testsFailedPerSprint} />
            </span>
          </div>
          <div className="col-md-4">
            <div className="detail2">Active Tasks</div>
            <span className="count-numbers peruser">
              <ActiveTaskPerUser per_user={activeTask} />
            </span>
          </div>
        </div>
        <div className="col-md-1">
          <span className="table-headers">Timebased User Stats</span>
          <TimeBasedStatsTable time_stats={timeBasedUserStats} />
        </div>
        <div className="col-md-1">
          <span className="table-headers">User Time Spent Per Sprint</span>
          <UserTimeSpentPerSprint per_category={userTimeSpentPerSprint} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <TestResultPerUser per_category={testResultsUser} />
          </div>
          <div className="col-md-3">
            <TestsPassedFailed per_category={testResults} />
          </div>
          <div className="col-md-3">
            <TestsPerUserGraph per_user={testsDonePerUserGraph} />
          </div>
        </div>
        <span className="table-headers">
          Bugs Identified Per Version Last 90 Days
        </span>
        <div>
          <div className="col-md-3">
            <BugsIdentifiedPerVersion data={bugsIdentifiedGraph} />
          </div>
          <div className="col-md-3">
            <BugsIdentifiedPerVersionWeb data={bugsIdentifiedweb} />
          </div>
          <div className="col-md-3">
            <BugsIdentifiedPerVersionOther data={bugsIdentifiedOther} />
          </div>
        </div>
        <div className="col-md-1">
          <span className="table-headers">
            Bugs Identified Per Version Last 90 Days Table
          </span>
          <BugsIdentified per_version={bugsIdentified} />
        </div>
        <div className="container">
          <span className="table-headers">
            Time Spent per Department per release Desktop Last 180 Days
          </span>
          <div className="row">
            <div className="col-md2">
              <TimeSpentPerDepartmentPerReleaseDesktop
                data={timeSpentBreakdownDesktop}
              />
            </div>
            <div className="col-md2">
              <TotalTimeSpentPerDepartmentDesktop
                data={timeSpentBreakdownDesktop}
              />
            </div>
          </div>
          <span className="table-headers">
            Time Spent per Department per release Goodx Web Last 180 Days
          </span>
          <div className="row">
            <div className="col-md2">
              <TimeSpentPerDepartmentPerReleaseDesktop
                data={timeSpentBreakdownGoodxWeb}
              />
            </div>
            <div className="col-md2">
              <TotalTimeSpentPerDepartmentDesktop
                data={timeSpentBreakdownGoodxWeb}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(QualityAssurance);
