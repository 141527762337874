import React, { useEffect, useState } from 'react';
import { CustomTabs, CustomTab } from '../../utils/CustomTabs';

import { get } from '../../api';
import styles from './Releases.module.scss';


const Releases = ({ history }) => {
  const [listReleases, setListReleases] = useState({});

  const GoToHome = () => history.push({ pathname: `/` });

  useEffect(() => {
    const fetchReleases = async () => {
      const resp = await get(`/releases`);

      if (resp.ok) {
        setListReleases(await resp.json())
      }
    };
    fetchReleases();
  }, []);
  
  if (!listReleases || !listReleases.jsonb_object_agg) {
    return null;
  }

  const categoriesContent = Object.keys(listReleases.jsonb_object_agg).map((category) => {
    if (listReleases.jsonb_object_agg.hasOwnProperty(category)) {
      const categoryVersions = listReleases.jsonb_object_agg[category];
      const versions = categoryVersions.map((version) => `${version.version} (${version.release_date})`);
      const versionLabel = categoryVersions.map((version) => ({
        label: `${version.version} (${version.release_date})`,
        issues: version.issues,
      }));
 
      const versionTabs = versions.map((version, index) => (
        <CustomTab label={version.split('_')[1]} key={`version-${index}`}>
        {versionLabel
          .filter((v) => v.label === version)
          .map((filteredVersion, versionIndex) => {
            const { label, issues } = filteredVersion;
            const actualVersion = (label.split('(')[0]).replace(/ /g, "");
            
            return (
              <table className={styles.neumorphictable}>
                <thead>
                  <tr className={styles.tableRow} key={versionIndex}>
                    <th className={styles.tableHeader}>Dev Ref</th>
                    <th className={styles.tableHeader}>Description</th>
                    <th className={styles.tableHeader}>Release Note</th>
                  </tr>
                </thead>
                <tbody>
              {issues.map((issue, issueIndex) => (
                <tr className={styles.tableRow} key={issueIndex}>
                  <td className={styles.tableCell}>
                    {version.split('_')[0] === 'desktop' ?
                    <a
                      href={`https://updates.goodxcloud.co.za/8.0/notes/Release%20Notes%20${actualVersion.split('_')[1]}.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {issue.issue_id}
                    </a>
                    : 
                    version.split('_')[0] === 'goodxweb' ?
                    <a
                      href={`https://updates.goodxcloud.co.za/gxweb/notes/Release%20Notes%20${actualVersion}.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {issue.issue_id}
                    </a>
                    : issue.issue_id
                    }
                  </td>
                  <td className={styles.tableCell}>{issue.description}</td>
                  <td className={styles.tableCell}>{issue.release_notes}</td>
                </tr>
              ))}
              </tbody>
              </table>
            );
          })}
        </CustomTab>
      ));
      
      return (
        <CustomTabs display={"none"}>
          <CustomTab label={category}>
            <CustomTabs>
              {versionTabs}
            </CustomTabs>
          </CustomTab>
          <CustomTab label={""} />
        </CustomTabs>
      );
    }
    return null;
  });
    
  return (
    <>
    <div className="BackRow">
      <button className="TertiaryBtn" onClick={GoToHome}>
        <i className="material-icons">arrow_back</i> Back to open tickets
      </button>
    </div>
    {categoriesContent}
    </>
  );
};
  
export default Releases;