import React, { useState } from 'react';

import { post } from '../../api';
import styles from '../../pages/login/Login.module.scss';
import { LoginEmailInput } from '../../pages/login/Forms/LoginForm';


const Mailto = () => (
  <a href="mailto:help@goodx.co.za" target="_blank" rel="noopener noreferrer">
    help@goodx.co.za
  </a>
);

const EmailNotExist = (
  <React.Fragment>
    <div className={styles.ErrorMsg}>
      <div className={styles.Icon}><i className="material-icons">warning</i></div>
      The email address has not yet been registered. Please contact <Mailto /> to request a Client Portal new account.
    </div>
  </React.Fragment>
);

const SomethingWentWrong = (
  <React.Fragment>
    <div className={styles.ErrorMsg}>
      <div className={styles.Icon}><i className="material-icons">warning</i></div>
      Something went wrong. Please try again or log a ticket with <Mailto />.
    </div>
  </React.Fragment>
);

const EmailSent = (
  <React.Fragment>
    <div className={styles.SuccessMsg}>
      <div className={styles.Icon}><i className="material-icons">mark_email_read</i></div>
      The OTP email has been sent. Please check your email for your one-time pin.
    </div>
  </React.Fragment>
);

const EmailInput = ({ state: [email, setEmailState, urlLocation], setMessage, history }) => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const setEmail = async (value) => {
      setError(false);
      setMessage('');
      setEmailState(value);
    };

    const emailExists = async () => {
      const resp = await post('/user/email_exists', { email });

      if (!resp.ok) {
        setMessage(SomethingWentWrong);
        setError(true);
        return false;
      }

      const json = await resp.json();
      if (!json.exists) {
        setMessage(EmailNotExist);
        setError(true);
      }

      return json.exists;
    };

    const sendOTP = async () => {
      setIsLoading(true);
      const resp = await post('/user/request_reset', { email });

      if (resp.ok) {
        setMessage(EmailSent);
        history.push({
          pathname: '/otp-validate',
          state: { 'email': email, 'urlLocation': urlLocation }
         });
        setError(false);
        setIsLoading(false);
      } else {
        setMessage(SomethingWentWrong);
        setError(true);
        setIsLoading(false);
      }

      return resp.ok;
    };

    return (
      <React.Fragment>

					<div className={styles.StepWrapper}>
						<div className={styles.StepNumber}>1</div>

						<div className={styles.StepText}>Input your <strong>email address</strong> below and verify it with a <strong>one-time pin (OTP)</strong> that will be sent to your email address:</div>
					</div>

					<LoginEmailInput
						className={styles.EmailInput}
            state={[email, setEmail]}
            onBlur={emailExists}
            error={error}
          />
          <button
            className={styles.SendOtpBtn}
            onClick={sendOTP}
            disabled={!email || error}
            type="button" >
            Send OTP
          </button>
          {isLoading ?
          <div className={styles.LoaderIcon}><i className="material-icons">autorenew</i></div>
          : null
          }

      </React.Fragment>
    );
  };

export default EmailInput;
