import styles from './UpdateInformation.module.scss';

import React from 'react';

const ChangedInformation = ({ information }) => (
    <>
    { information.name !== information.newName ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Name: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.name}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newName}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.street !== information.newStreet ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Street: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.street}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newStreet}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.street2 !== information.newStreet2 ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Street2: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.street2}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newStreet2}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.city !== information.newCity ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>City: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.city}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newCity}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.zip !== information.newZip ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Zip: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.zip}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newZip}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.phone !== information.newPhone ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Phone: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.phone}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newPhone}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.email !== information.newEmail ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Email: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.email}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newEmail}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.mobile !== information.newMobile ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Mobile: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.mobile}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newMobile}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.fax !== information.newFax ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Fax: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.fax}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newFax}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.information_officer_name !== information.new_information_officer_name ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Information Officer Name: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.information_officer_name}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.new_information_officer_name}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.information_officer !== information.new_information_officer ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Information Officer Email: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.information_officer}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.new_information_officer}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.information_officer_cell_no !== information.new_information_officer_cell_no ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Information Officer Cell Number: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.information_officer_cell_no}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.new_information_officer_cell_no}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.marketing_email !== information.new_marketing_email ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Marketing Email: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.marketing_email}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.new_marketing_email}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.debtor_vatnr !== information.new_debtor_vatnr ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Debtor Vat Number: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.debtor_vatnr}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.new_debtor_vatnr}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.website !== information.newWebsite ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>WebSite: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.website}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newWebsite}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.operationalEmail !== information.newOperationalEmail ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Operational Email: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.operationalEmail}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newOperationalEmail}</p>
            </div>
        </div>
    </div>:
    <></>
    }
    { information.financialEmail !== information.newFinancialEmail ?
    <div className={styles.PageWrapper}>
        <div className={styles.HeadingCard}>
            <span>Financial Officer Email: </span>
        </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.financialEmail}</p>
            </div>
        </div>
            <div className={styles.ArrowHead}>
                <span>&rarr;</span>
            </div>
        <div className="OverviewPanel">
            <div className="OverviewFirstRow">
                <p>{information.newFinancialEmail}</p>
            </div>
        </div>
    </div>:
    <></>
    }

    </>
);

export default ChangedInformation;
