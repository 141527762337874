import './Issue.scss';

import React from 'react';

const LeaderBoardIssueRow = ({ supportClosedStats }) => (
  <tr>
    <td>{supportClosedStats.category_description}</td>
    <td>{supportClosedStats.platinum_done}</td>
    <td>{supportClosedStats.help_done}</td>
    <td>{supportClosedStats.hospital_done}</td>
    <td>{supportClosedStats.support_done}</td>
    <td>{supportClosedStats.stats_percentage}</td>
  </tr>
);

const leaderBoardIssuesTable = ({ supportClosedStats }) => (
  <table id="LeaderBoardIssuesTable">
    <thead>
      <tr>
        <th>User</th>
        <th>Platinum</th>
        <th>Help</th>
        <th>Hospital</th>
        <th>Total</th>
        <th>Percentage</th>
      </tr>
    </thead>
    <tbody>
      {supportClosedStats?.map((support_closed_stats) => (
        <LeaderBoardIssueRow
          key={support_closed_stats.id}
          supportClosedStats={support_closed_stats}
        />
      ))}
    </tbody>
  </table>
);

export default leaderBoardIssuesTable;
