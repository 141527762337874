import React, { useState } from "react";


const YoutubeEmbed = ({ videoId }) => {
  const [error, setError] = useState(false);

  const handleOnError = () => {
    setError(true);
  };

  return (
    <div>
      {error ? (
        <p>Failed to load YouTube video.</p>
      ) : (
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
          onError={handleOnError}
        />
      )}
    </div>
  );
};

export default YoutubeEmbed;