import React from 'react';

import styles from './FilesDisplay.module.scss';

const fileIcon = (file) => {
  switch (file.type) {
    case 'application/pdf':
      return 'picture_as_pdf';
    case 'application/x-freearc':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/gzip':
    case 'application/x-rar-compressed':
    case 'application/x-tar':
    case 'application/zip':
    case 'application/x-7z-compressed':
      return 'folder_zip';
    default:
      switch (file.type.split('/')[0]) {
        case 'text':
          return 'description';
        case 'image':
          return 'image';
        case 'audio':
          return 'audio_file';
        case 'video':
          return 'video_file';
        default:
          return 'insert_drive_file';
      }
  }
};

const FilesDisplay = ({ files, onRemove }) => (
  <div className={styles.Files}>
    {files.map((file, index) => (
      <React.Fragment>
        <div className={styles.UploadedFile}>

          <i className="material-icons">{fileIcon(file)}</i>

          <span className={styles.FileName}>{file.name}</span>

          <div
            className={styles.DeleteBtn}
            onClick={() => (onRemove ? onRemove(index) : null)}
            title={`Remove ${file.name}`}
          >
            <i className="material-icons">close</i>
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
);

export default FilesDisplay;
