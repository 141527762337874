import React from 'react';

const NoSurvey = () => {
  return (
    <div>
      <span>
        No survey found. Please click on the link. Don't try to navigate to this
        page. Survey links have extra information in them that is required to
        complete the survey successfully.
      </span>
    </div>
  );
};

export default NoSurvey;
