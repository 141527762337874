import { createContext } from 'react';

const AuthTokenContext = createContext({
  isTokenValid: () => {},
  setTokenExpires: () => {},
});

const UserContext = createContext({
  user: null,
  setUser: () => {},
});

const ViewContext = createContext({
  view: 'table',
  setView: () => {},
});

export { AuthTokenContext, UserContext, ViewContext };
