import './Issue.scss';

import React from 'react';

const BugIssuesRow = ({ bug_issues }) => (
  <tr>
    <td>
      <a
        href={`https://odoo.quantsolutions.co.za/issue/${bug_issues.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {bug_issues.issue}
      </a>
    </td>
    <td>{bug_issues.site}</td>
    <td>{bug_issues.affected_environment}</td>
    <td>{bug_issues.affected_version}</td>
    <td>{bug_issues.current_hot_fix}</td>
  </tr>
);

const BugIssuesTable = ({ bug_issues }) => (
  <table id="BugIssuesTable">
    <thead>
      <tr>
        <th>ID</th>
        <th>Site</th>
        <th>Environment</th>
        <th>Version</th>
        <th>Current Hot Fix</th>
      </tr>
    </thead>
    <tbody>
      {bug_issues.map((bug_issues) => (
        <BugIssuesRow
          key={bug_issues.id}
          bug_issues={bug_issues}
        />
      ))}
    </tbody>
  </table>
);

export default BugIssuesTable;
