import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './Toast.module.scss';

export const ToastsContext = React.createContext({});

const Toast = ({ toast: { type, title, message }, setDismissed }) => (
  <div className={[styles.Toast, type].join(' ')}>
    <span className={styles.Title}>{title}</span>
    {Array.isArray(message) ? message.map((m) => <p>{m}</p>) : <p>{message}</p>}
    <FontAwesomeIcon
      className={styles.Button}
      onClick={setDismissed}
      icon={faTimesCircle}
      size="2x"
    />
  </div>
);

const ToastsContainer = ({ toasts, dismissToast }) => (
  <div className={styles.ToastsContainer}>
    {toasts
      .filter((t) => t !== null)
      .map((toast, i) => (
        <Toast key={i} {...{ toast }} setDismissed={() => dismissToast(i)} />
      ))}
  </div>
);

export default ToastsContainer;
