import './Issue.scss';

import React from 'react';

const TimeStatsRow = ({ time_stats }) => (
  <tr>
    <td>{time_stats.name}</td>
    <td>{time_stats.first_seen}</td>
    <td>{time_stats.last_seen}</td>
    <td>{time_stats.minutes_between_first_and_last}</td>
    <td style={{ background: time_stats.percentage_workday_start_end_color }}>
      {time_stats.percentage_workday_start_end}
    </td>
    <td>{time_stats.minutes_logged}</td>
    <td style={{ background: time_stats.percentage_workday_logged_color }}>
      {time_stats.percentage_workday_logged}
    </td>
    <td>{time_stats.times_logged}</td>
    <td>{time_stats.issues_worked_on}</td>
    <td>{time_stats.outgoing_tickets}</td>
  </tr>
);

const TimeStatsTable = ({ time_stats }) => (
  <table id="TimeStatsTable">
    <thead>
      <tr>
        <th>Username</th>
        <th>First Seen</th>
        <th>Last Seen</th>
        <th>Work Day Minutes</th>
        <th>Work Day Percentage</th>
        <th>Logged Minutes</th>
        <th>Logged Percentage</th>
        <th>Times Logged</th>
        <th>Issues Worked On</th>
        <th>Issues Done</th>
      </tr>
    </thead>
    <tbody>
      {time_stats?.map((time_stats) => (
        <TimeStatsRow key={time_stats.name} time_stats={time_stats} />
      ))}
    </tbody>
  </table>
);

export default TimeStatsTable;
