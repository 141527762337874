import React, { useEffect, useState } from 'react';

import BankingDetails from './BankingDetails';
import EntityBasicDetails from './EntityBasicDetails';
import FinancialSetup from './FinancialSetup';
import { containsErrors, defaultEntity } from './setupReducer';
import SmtpDetails from './SmtpDetails';
import SystemUsers from './SystemUsers';
import Tab from './Tab';
import TreatingDoctors from './TreatingDoctors';

const Entity = ({
  fields,
  entity: {
    basicDetails,
    bankingDetails,
    financialSetup,
    treatingDoctors,
    systemUsers,
    smtpDetails,
  },
  dispatch,
}) => {
  const [page, setPage] = useState('basicDetails');

  return (
    <>
      <div className="TabList">
        <Tab
          name="Basic Details"
          active={page === 'basicDetails'}
          error={containsErrors(basicDetails)}
          onChange={() => setPage('basicDetails')}
        />
        <Tab
          name={`Treating Doctors (${treatingDoctors.length})`}
          active={page === 'treatingDoctors'}
          error={containsErrors(treatingDoctors)}
          onChange={() => setPage('treatingDoctors')}
        />
        <Tab
          name={`System Users (${systemUsers.length})`}
          active={page === 'systemUsers'}
          error={containsErrors(systemUsers)}
          onChange={() => setPage('systemUsers')}
        />
        <Tab
          name={"SMTP Details"}
          active={page === 'smtpDetails'}
          error={containsErrors(smtpDetails)}
          onChange={() => setPage('smtpDetails')}
        />
      </div>
      <div className="Page">
        {page === 'basicDetails' ? (
          <>
            <EntityBasicDetails
              fields={[...fields, 'basicDetails']}
              basicDetails={basicDetails}
              dispatch={dispatch}
            />
            <BankingDetails
              fields={[...fields, 'bankingDetails']}
              bankingDetails={bankingDetails}
              dispatch={dispatch}
            />
            <FinancialSetup
              fields={[...fields, 'financialSetup']}
              financialSetup={financialSetup}
              dispatch={dispatch}
            />
          </>
        ) : null}
        {page === 'treatingDoctors' ? (
          <TreatingDoctors
            fields={[...fields, 'treatingDoctors']}
            treatingDoctors={treatingDoctors}
            dispatch={dispatch}
          />
        ) : null}
        {page === 'systemUsers' ? (
          <SystemUsers
            fields={[...fields, 'systemUsers']}
            systemUsers={systemUsers}
            dispatch={dispatch}
          />
        ) : null}
        {page === 'smtpDetails' ? (
          <SmtpDetails
            fields={[...fields, 'smtpDetails']}
            smtpDetails={smtpDetails}
            dispatch={dispatch}
          />
        ) : null}
      </div>
    </>
  );
};

const EntitiesButtons = ({ entities, index, fields, setIndex, dispatch }) => (
  <div className="TabList">
    {entities?.map((entity, i) => (
      <Tab
        key={i}
        name={`Entity - ${entity.basicDetails.name.value || 'Name Incomplete'}`}
        active={index === i}
        error={containsErrors(entity)}
        canDelete={entities.length > 1}
        onChange={() => setIndex(i)}
        onDelete={() => {
          dispatch({ fields: [...fields, i, 'filterOut()'] });
          if (index > i) setIndex((oldI) => oldI - 1);
        }}
      />
    ))}

    <Tab
      name="+"
      onChange={() =>
        dispatch({ fields: [...fields, 'append()'], value: defaultEntity })
      }
    />
  </div>
);

const Entities = ({ fields, entities, dispatch }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index >= entities.length) {
      setIndex((i) => Math.max(i - 1, 0));
    }
  }, [entities, index]);

  return (
    <>
      <EntitiesButtons {...{ entities, fields, index, setIndex, dispatch }} />
      <div className="Page">
        {index < entities.length ? (
          <Entity
            fields={[...fields, index]}
            entity={entities[index]}
            dispatch={dispatch}
          />
        ) : null}
      </div>
    </>
  );
};

export default Entities;
