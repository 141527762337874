import React, { useState } from 'react';


const NewPasswordInput = ({ state: [value, onChange], onBlur, error }) => (
    <input
      className={['Input', error ? 'Error' : null].join(' ')}
      type="password"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      placeholder="New password"
      autoComplete="new-password"
    />
  );

const PasswordInputs = ({
    state: [password, setPasswordState],
    setMessage,
  }) => {
    const [error, setError] = useState(false);

    const setNewPassword = (value) => {
      setMessage(null);
      setError(false);
      setPasswordState(value);
    };

    return (
      <React.Fragment>
        <NewPasswordInput
          state={[password, setNewPassword]}
          error={error}
        />
      </React.Fragment>
    );
  };

  export default PasswordInputs;
