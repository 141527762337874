import './qualityAssurance.scss';

import React from 'react';

const TestPerVersionRow = ({ per_category }) => (
  <tr>
    <td>{per_category.get_qa_testing_failed_per_version.version}</td>
    <td>
      {per_category.get_qa_testing_failed_per_version.testing_failed_count}
    </td>
  </tr>
);

const TestingFailedPerVersion = ({ per_category }) => (
  <table id="QaTestResultTable">
    <thead>
      <tr>
        <th>Version</th>
        <th>Failed Tests</th>
      </tr>
    </thead>
    <tbody>
      {per_category?.map((per_category) => (
        <TestPerVersionRow
          per_category={per_category}
          key={per_category.get_qa_testing_failed_per_version.version}
        />
      ))}
    </tbody>
  </table>
);

export default TestingFailedPerVersion;
