import './qualityAssurance.scss';

import React from 'react';

const BugsIdentifiedRows = ({ per_version }) => (
  <tr>
    <td>{per_version.data_object.version}</td>
    <td>{per_version.data_object.daterelease}</td>
    <td>{per_version.data_object.number_of_issues}</td>
    <td>{per_version.data_object.affected_clients}</td>
    <td>{per_version.data_object.no_of_tasks}</td>
  </tr>
);

const BugsIdentified = ({ per_version }) => (
  <table id="TimeStatsTable">
    <thead>
      <tr>
        <th>Version</th>
        <th>Date Released</th>
        <th>Number Of Issues</th>
        <th>Affected Clients</th>
        <th>Number of tasks</th>
      </tr>
    </thead>
    <tbody>
      {per_version?.map((per_version) => (
        <BugsIdentifiedRows
          per_version={per_version}
          key={per_version.data_object.version}
        />
      ))}
    </tbody>
  </table>
);

export default BugsIdentified;
