import React, { useEffect, useState } from 'react';

import InputSelect from './Inputs/InputSelect';
import InputText from './Inputs/InputText';
import { containsErrors, defaultSystemUser } from './setupReducer';
import Tab from './Tab';

const userTypeOptions = [
  { id: 'doctor', name: 'Doctor' },
  { id: 'receptionist', name: 'Receptionist' },
  { id: 'bookkeeper', name: 'Bookkeeper' },
  { id: 'practice_manager', name: 'Practice Manager' },
  { id: 'debtor_clerk', name: 'Debtor Clerk' },
  { id: 'other', name: 'Other' },
  { id: 'cancelled', name: 'Cancelled' },
];

const SystemUser = ({ fields, systemUser, dispatch }) => (
  <div className="Page">
    <h3>User's Details</h3>
    <div className="Inputs">
      <InputText
        name="User's Full Name"
        value={systemUser.userFullName.value}
        errors={systemUser.userFullName.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'userFullName'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Username"
        value={systemUser.username.value}
        errors={systemUser.username.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'username'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Email Address"
        value={systemUser.email.value}
        errors={systemUser.email.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'email'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Cellphone Number"
        value={systemUser.cellphoneNumber.value}
        errors={systemUser.cellphoneNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'cellphoneNumber'],
            value: e.target.value,
          })
        }
      />

      <InputSelect
        name="User Type"
        value={systemUser.userType.value}
        errors={systemUser.userType.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'userType'],
            value: e.target.value,
          })
        }
        options={userTypeOptions}
      />

      <InputSelect
        name="Account Type"
        value={systemUser.accountType.value}
        errors={systemUser.accountType.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'accountType'],
            value: e.target.value,
          })
        }
        options={[
          { id: 'gxweb', name: 'GXWeb User' },
          { id: 'goodx', name: 'GoodX (RDP) User' },
          { id: 'gxweb_goodx', name: 'GXWeb and Goodx (RDP) User' },
        ]}
      />
    </div>
  </div>
);

const SystemUsersButtons = ({
  systemUsers,
  fields,
  index,
  setIndex,
  dispatch,
}) => (
  <div className="TabList">
    {systemUsers.map((user, i) => (
      <Tab
        name={`User - ${user.username.value || 'Username Incomplete'}`}
        active={index === i}
        error={containsErrors(user)}
        canDelete={systemUsers.length > 1}
        onChange={() => setIndex(i)}
        onDelete={() => {
          dispatch({ fields: [...fields, i, 'filterOut()'] });
          if (index > i) setIndex((oldI) => oldI - 1);
        }}
        key={i}
      />
    ))}

    <Tab
      name="+"
      onChange={() =>
        dispatch({
          fields: [...fields, 'append()'],
          value: defaultSystemUser,
        })
      }
    />
  </div>
);

const SystemUsers = ({ fields, systemUsers, dispatch }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index >= systemUsers.length) {
      setIndex((i) => Math.max(i - 1, 0));
    }
  }, [systemUsers, index]);

  return (
    <>
      <SystemUsersButtons
        {...{
          systemUsers,
          fields,
          index,
          setIndex,
          dispatch,
        }}
      />
      {index < systemUsers.length ? (
        <SystemUser
          fields={[...fields, index]}
          systemUser={systemUsers[index]}
          dispatch={dispatch}
        />
      ) : null}
    </>
  );
};

export default SystemUsers;
