import React from 'react';
import Breakpoint from './BreakPoint';

export default function DesktopLayout(props) {

  return (
    <Breakpoint name="desktop">
      {props.children}
    </Breakpoint>
  );
}
