import './Issue.scss';

import React from 'react';

const CategoryIssueRow = ({ per_category }) => (
  <tr>
    <td>{per_category.category_description}</td>
    <td>{per_category.no_of_issues}</td>
    <td>{per_category.stats_percentage}</td>
  </tr>
);

const CategoryIssuesTable = ({ per_category }) => (
  <table id="CategoryIssuesTable">
    <thead>
      <tr>
        <th>Category</th>
        <th>No_of_Issues</th>
        <th>Percentage</th>
      </tr>
    </thead>
    <tbody>
      {per_category?.map((per_category) => (
        <CategoryIssueRow
          key={per_category.category_description}
          per_category={per_category}
        />
      ))}
    </tbody>
  </table>
);

export default CategoryIssuesTable;
