import React, { useEffect, useState } from 'react';

import Diary from './Diary';
import InputText from './Inputs/InputText';
import { containsErrors, defaultTreatingDoctor } from './setupReducer';
import Speciality from './Speciality';
import Tab from './Tab';

const doctorDisplayName = (treatingDoctor) => {
  const title = treatingDoctor.title.value;
  const firstNames = treatingDoctor.firstNames.value;
  const lastName = treatingDoctor.lastName.value;

  return title || firstNames || lastName
    ? [
        title || undefined,
        firstNames
          ?.split(' ')
          ?.map((n) => n[0])
          ?.join('') || undefined,
        lastName || undefined,
      ]?.join(' ')
    : 'Name Incomplete';
};

const DoctorDetails = ({ fields, doctorDetails, dispatch }) => (
  <>
    <h3>Doctor's Details</h3>
    <div className="Inputs">
      <InputText
        name="Title"
        value={doctorDetails.title.value}
        errors={doctorDetails.title.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'title'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="First Names"
        value={doctorDetails.firstNames.value}
        errors={doctorDetails.firstNames.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'firstNames'],
            value: e.target.value,
          })
        }
      />
      <InputText
        name="Last Name"
        value={doctorDetails.lastName.value}
        errors={doctorDetails.lastName.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'lastName'],
            value: e.target.value,
          })
        }
      />
      <Speciality
        name="Speciality"
        value={doctorDetails.speciality.value}
        errors={doctorDetails.speciality.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'speciality'],
            value: parseInt(e.target.value),
          })
        }
      />
      <InputText
        name="Practice Number (7)"
        value={doctorDetails.practiceNumber7.value}
        errors={doctorDetails.practiceNumber7.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'practiceNumber7'],
            value: e.target.value,
          })
        }
        maxLength="7"
      />
      <InputText
        name="MP Number"
        value={doctorDetails.mpNumber.value}
        errors={doctorDetails.mpNumber.errors}
        onChange={(e) =>
          dispatch({
            fields: [...fields, 'mpNumber'],
            value: e.target.value,
          })
        }
      />
    </div>
  </>
);

const TreatingDoctor = ({
  fields,
  treatingDoctor: { doctorDetails, diary },
  dispatch,
}) => (
  <div className="Page">
    <DoctorDetails
      {...{ fields: [...fields, 'doctorDetails'], doctorDetails, dispatch }}
    />
    <Diary {...{ fields: [...fields, 'diary'], diary, dispatch }} />
  </div>
);

const TreatingDoctorsButtons = ({
  treatingDoctors,
  fields,
  index,
  setIndex,
  dispatch,
}) => (
  <div className="TabList">
    {treatingDoctors.map((doctor, i) => (
      <Tab
        name={`Doctor - ${doctorDisplayName(doctor.doctorDetails)}`}
        active={index === i}
        error={containsErrors(doctor)}
        canDelete={treatingDoctors.length > 1}
        onChange={() => setIndex(i)}
        onDelete={() => {
          dispatch({ fields: [...fields, i, 'filterOut()'] });
          if (index > i) setIndex((oldI) => oldI - 1);
        }}
        key={i}
      />
    ))}

    <Tab
      name="+"
      onChange={() =>
        dispatch({
          fields: [...fields, 'append()'],
          value: defaultTreatingDoctor,
        })
      }
    />
  </div>
);

const TreatingDoctors = ({ fields, treatingDoctors, dispatch }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index >= treatingDoctors.length) {
      setIndex((i) => Math.max(i - 1, 0));
    }
  }, [treatingDoctors, index]);

  return (
    <>
      <TreatingDoctorsButtons
        {...{
          treatingDoctors,
          fields,
          index,
          setIndex,
          dispatch,
        }}
      />
      {index < treatingDoctors.length ? (
        <TreatingDoctor
          fields={[...fields, index]}
          treatingDoctor={treatingDoctors[index]}
          dispatch={dispatch}
        />
      ) : null}
    </>
  );
};

export default TreatingDoctors;
