import './qualityAssurance.scss';

import React from 'react';
import { Bar } from 'react-chartjs-2';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const TestResultPerUser = ({ per_category }) => (
  <div>
    <div className="header">
      <h1 className="title">Tests Per User</h1>
    </div>
    {per_category[0] ? (
      <Bar data={TestResultsPerUser(per_category)} options={options} />
    ) : (
      <div></div>
    )}
  </div>
);

const TestResultsPerUser = (per_category) => ({
  labels: per_category[0].get_qa_tests_results_for_user.data_labels,
  datasets: [
    {
      label: 'Failed',
      fill: false,
      lineTension: 0.1,
      backgroundColor:  'rgb(255, 99, 132)',
      borderColor: 'rgba(75, 119, 190, 0.8)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor:  'rgb(255, 99, 132)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      data: per_category[0].get_qa_tests_results_for_user.failed,
    },
    {
      stack: 'stack1',
      label: 'Passed',
      backgroundColor: 'rgb(75, 192, 192)',
      data: per_category[0].get_qa_tests_results_for_user.passed,
    },
  ],
});

export default TestResultPerUser;
