import './Issue.scss';

import React from 'react';

import dateDisplay from '../../components/utils/datetime';

const SupportActiveIssuesRow = ({ supportActiveIssues }) => (
  <tr
    style={
      supportActiveIssues.red_flag
        ? { background: '#DA3926' }
        : { background: '#5BE49C' }
    }
  >
    <td>
      <a
        href={`https://odoo.quantsolutions.co.za/issue/${supportActiveIssues.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {supportActiveIssues.id}
      </a>
    </td>
    <td>{supportActiveIssues.name}</td>
    <td>{supportActiveIssues.issue_detail}</td>
    <td>{dateDisplay(supportActiveIssues.duration)}</td>
    <td>{dateDisplay(supportActiveIssues.last_update_date)}</td>
  </tr>
);

const supportActiveIssuesTable = ({ supportActiveIssues }) => (
  <table id="SupportActiveIssuesTable">
    <thead>
      <tr>
        <th>ID</th>
        <th>Assigned To</th>
        <th>Description</th>
        <th>Current Duration</th>
        <th>Last Updated</th>
      </tr>
    </thead>
    <tbody>
      {supportActiveIssues?.map((support_active_issues) => (
        <SupportActiveIssuesRow
          key={support_active_issues.id}
          supportActiveIssues={support_active_issues}
        />
      ))}
    </tbody>
  </table>
);

export default supportActiveIssuesTable;
