import React from 'react';
import { withRouter } from 'react-router';

import styles from './RepoWeeksInternal.module.scss';

const WeekCard = ({ week, onClick }) => {
  const repos = week.card_data.repos;
  return (
    <div className={styles.Card} onClick={() => onClick(week.card_data)}>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th colSpan="6">{week.card_data.week_text}</th>
          </tr>
          <tr>
            <th colSpan="6">{week.card_data.week_dates}</th>
          </tr>
          <tr>
            <th style={({ textAlign: 'left' }, { width: '30%' })}>Repo</th>
            <th>Allowed Hours</th>
            <th>Task Count</th>
            <th>Hours Planned</th>
            <th>Hours Completed</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          {repos.map((wk) => (
            <tr key={wk.github_repo_name}>
              <td
                style={{
                  textAlign: 'left'
                }}
              >
                {wk.github_repo_name}
              </td>
              <td style={{ color: wk.html_line_color, fontWeight: 'bolder' }}>{wk.allowed_hours}</td>
              <td style={{ color: wk.html_line_color, fontWeight: 'bolder' }}>
                {wk.task_count}
              </td>
              <td style={{ color: wk.html_line_color, fontWeight: 'bolder' }}>
                {wk.planned_hours.toFixed(2)}
              </td>
              <td
                style={{
                  color: wk.html_line_color, fontWeight: 'bolder'
                }}
              >
                {wk.effective_hours.toFixed(2)}
              </td>
              <td
                style={{
                  color: wk.html_line_color, fontWeight: 'bolder'
                }}
              >
                {(wk.planned_hours - wk.effective_hours).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className={styles.TotalStats}>
          <tr>
            <td style={{ textAlign: 'right' }}>Total:</td>
            <td>{' '}
              {repos.reduce(function (total, array) {
                return total + array.allowed_hours;
              }, 0)}</td>
            <td>
              {' '}
              {repos.reduce(function (total, array) {
                return total + array.task_count;
              }, 0)}
            </td>
            <td>
              {' '}
              {repos
                .reduce(function (total, array) {
                  return total + array.planned_hours;
                }, 0)
                .toFixed(2)}
            </td>
            <td>
              {' '}
              {repos
                .reduce(function (total, array) {
                  return total + array.effective_hours;
                }, 0)
                .toFixed(2)}
            </td>
            <td>
              {' '}
              {repos
                .reduce(function (total, array) {
                  return total + array.planned_hours - array.effective_hours;
                }, 0)
                .toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const RepoWeeksInternal = ({ weeks, onClick }) => {
  return (
    <div className={styles.CardList}>
      {weeks.map((week, index) => (
        <WeekCard
          key={`week_${index}`}
          week={week}
          onClick={onClick}
        ></WeekCard>
      ))}
    </div>
  );
};

export default withRouter(RepoWeeksInternal);
