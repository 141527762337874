import { post } from '../../../api';

const onlyValues = (state) => {
  if (state === null) {
    return null;
  }
  if (state.hasOwnProperty('value') && typeof state.value !== 'object') {
    return state.value;
  }
  if (Array.isArray(state)) {
    return state.map((e) => onlyValues(e));
  }
  if (typeof state === 'object') {
    return Object.entries(state).reduce(
      (previous, [key, value]) => ({ ...previous, [key]: onlyValues(value) }),
      {},
    );
  }
};

const save = async (install, token, appendToast) => {
  const resp = await post('/install_setup', {
    token,
    install: onlyValues(install),
  });

  if (resp.ok) {
    console.log('Saved');
    appendToast({
      type: 'success',
      title: 'Saved Successful',
      message:
        'Your form has been successfully saved.',
    });
  } else {
    const errorText = await resp.text();
    console.error(errorText);
    appendToast({
      type: 'error',
      title: 'Saved Failed',
      message: [
        'Unfortunately, saving your form failed. Please try again. If you continue to get this error, please contact GoodX support at help@goodx.co.za or Cellphone number.',
        errorText,
      ],
    });
  }

  return resp;
};

export const markAsCompleted = async (install, token, appendToast) => {
  const resp = await post('/install_setup_mark_as_complete', {
    token,
    install: onlyValues(install),
  });

  if (resp.ok) {
    appendToast({
      type: 'success',
      title: 'Submit Successful',
      message:
        'Your form has been successfully submitted. Your marketing representative will get back to you shortly.',
    });
  } 
  else if (resp.status === 500) {
    appendToast({
      type: 'error',
      title: 'Submit Failed',
      message: [
        'This form has already been marked as completed',
      ],
    });
  }
  else {
    const errorText = await resp.text();
    appendToast({
      type: 'error',
      title: 'Submit Failed',
      message: [
        'Unfortunately, submitting your form failed. Please try again. If you continue to get this error, please contact GoodX support at help@goodx.co.za or Cellphone number.',
        errorText,
      ],
    });
  }

  return resp;
};

export default save;
