import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Validator = ({ errors }) => {
  return (
    <>
      <div></div>
      <div
        className={['ValidationText', 'Error', errors ? null : 'Hidden'].join(
          ' ',
        )}
        title={errors}
      >
        {errors ? (
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{errors}</span>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Validator;
